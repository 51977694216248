import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import InventoryRequestService from '../../services/InventoryRequestService';
import RejectionModal from '../RejectionModal';
const columns = [
  { id: 'sr_no', label: 'Sr no.', minWidth: 50 },
  {
    id: 'full_name',
    label: 'Name',
    minWidth: 100,
  },
  {
    id: 'inventoryItemName',
    label: 'Item Name',
    minWidth: 100,
  },

  {
    id: 'ItemsRequired',
    label: 'Quantity',
    minWidth: 100,
  },

  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
  },

  {
    id: 'inventoryRequestStatus',
    label: 'Status',
    minWidth: 100,
  },

  {
    id: 'details',
    label: 'Details',
    minWidth: 100,
  },
];

export default function InventoryList() {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  // const [loggedInUserId, setLoggedInUserId] = useState(0);
  const [selectedSpecificMonth, setSelectedSpecificMonth] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchItemName, setSearchItemName] = useState('');
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isItemFocused, setIsItemFocused] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMyTeam, setIsMyTeam] = useState(false);

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = (event) => {
    const { name, value } = event.target;

    let filtered;

    if (name === 'item_name') {
      setSearchItemName(value);

      filtered = tableData.filter((data) => {
        const nameMatch =
          searchTerm === '' ||
          data['full_name'].toLowerCase().includes(searchTerm.toLowerCase());

        const itemMatch =
          value === '' ||
          data['inventoryItemName'].toLowerCase().includes(value.toLowerCase());

        const monthMatch =
          !selectedSpecificMonth ||
          (new Date(data.date) >=
            new Date(
              selectedSpecificMonth.$d.getFullYear(),
              selectedSpecificMonth.$d.getMonth(),
              1,
            ) &&
            new Date(data.date) <=
              new Date(
                selectedSpecificMonth.$d.getFullYear(),
                selectedSpecificMonth.$d.getMonth() + 1,
                0,
              ));

        let statusMatch;
        if (selectedStatus === '') {
          statusMatch = data['inventoryRequestStatus'] === 'pending';
        } else {
          statusMatch = data['inventoryRequestStatus'] === selectedStatus;
        }

        return nameMatch && itemMatch && monthMatch && statusMatch;
      });
    } else {
      setSearchTerm(value);

      filtered = tableData.filter((data) => {
        const nameMatch =
          value === '' ||
          data['full_name'].toLowerCase().includes(value.toLowerCase());

        const itemMatch =
          searchItemName === '' ||
          data['inventoryItemName']
            .toLowerCase()
            .includes(searchItemName.toLowerCase());

        const monthMatch =
          !selectedSpecificMonth ||
          (new Date(data.date) >=
            new Date(
              selectedSpecificMonth.$d.getFullYear(),
              selectedSpecificMonth.$d.getMonth(),
              1,
            ) &&
            new Date(data.date) <=
              new Date(
                selectedSpecificMonth.$d.getFullYear(),
                selectedSpecificMonth.$d.getMonth() + 1,
                0,
              ));

        let statusMatch;
        if (selectedStatus === '') {
          statusMatch = data['inventoryRequestStatus'] === 'pending';
        } else {
          statusMatch = data['inventoryRequestStatus'] === selectedStatus;
        }

        if (value === '') {
          return itemMatch && monthMatch && statusMatch;
        }

        return nameMatch && itemMatch && monthMatch && statusMatch;
      });
    }

    setFilteredData(filtered);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      setCurrentUserId(parsedUser.id);

      if (parsedUser.roleId === 2) {
        setIsTeamLead(true);
        const inventory = await InventoryRequestService.findAll(parsedUser.id);
        const filtered = inventory.filter(
          (data) =>
            data['inventoryRequestStatus'] === 'approved' ||
            data['inventoryRequestStatus'] === 'delivered' ||
            data['inventoryRequestStatus'] === 'out_of_stock',
        );
        setTableData(inventory);
        setFilteredData(filtered);
        setIsMyTeam(inventory.length > 1 ? true : false);
      } else {
        setIsTeamLead(false);
        const inventory = await InventoryRequestService.findAll(parsedUser.id);
        const filtered = inventory.filter(
          (data) => data['inventoryRequestStatus'] === 'pending',
        );
        setTableData(inventory);
        setFilteredData(filtered);
        setIsMyTeam(inventory.length > 1 ? true : false);
      }
    };

    fetchUserData();
  }, []);

  const handleSpecificMonthChange = async (date) => {
    setSelectedSpecificMonth(date);

    // Get current date
    const currentDate = new Date(date);

    // Get first date of the current month
    const firstDateOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    );

    // Get last date of the current month
    const lastDateOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
    );

    const filteredInventory = tableData.filter((item) => {
      const itemDate = new Date(item.date);
      const fullNameMatch =
        searchTerm === '' ||
        item['full_name'].toLowerCase().includes(searchTerm.toLowerCase());

      const itemNameMatch =
        searchItemName === '' ||
        item['inventoryItemName']
          .toLowerCase()
          .includes(searchItemName.toLowerCase());

      let statusMatch;
      if (selectedStatus === '') {
        statusMatch = item['inventoryRequestStatus'] === 'pending';
      } else {
        statusMatch = item['inventoryRequestStatus'] === selectedStatus;
      }

      return (
        itemDate >= firstDateOfMonth &&
        itemDate <= lastDateOfMonth &&
        fullNameMatch &&
        itemNameMatch &&
        statusMatch
      );
    });

    setFilteredData(filteredInventory);
  };

  const handleSelectedStatus = async (event) => {
    const { value } = event.target;
    setSelectedStatus(value);

    if (value !== '') {
      const filtered = tableData.filter((data) => {
        const fullNameMatch =
          searchTerm === '' ||
          data['full_name'].toLowerCase().includes(searchTerm.toLowerCase());

        const itemNameMatch =
          searchItemName === '' ||
          data['inventoryItemName']
            .toLowerCase()
            .includes(searchItemName.toLowerCase());

        const statusMatch =
          value === '' ||
          data['inventoryRequestStatus']
            .toLowerCase()
            .includes(value.toLowerCase());

        const monthMatch =
          !selectedSpecificMonth ||
          (new Date(data.date) >=
            new Date(
              selectedSpecificMonth.$d.getFullYear(),
              selectedSpecificMonth.$d.getMonth(),
              1,
            ) &&
            new Date(data.date) <=
              new Date(
                selectedSpecificMonth.$d.getFullYear(),
                selectedSpecificMonth.$d.getMonth() + 1,
                0,
              ));

        return fullNameMatch && itemNameMatch && statusMatch && monthMatch;
      });

      setFilteredData(filtered);
    } else {
      setFilteredData(tableData);
    }
  };

  const handleInventoryView = async (id) => {
    history.push(`/inventory/view/${id}`);
  };

  const addNewRequest = async () => {
    history.push(`/inventory/add`);
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const beforeTable = document.createElement('div');
      beforeTable.innerHTML = '<p style="text-align: center;"></p>';
      container.appendChild(beforeTable);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      const rows = clonedTable.querySelectorAll('tr');
      rows.forEach((row) => {
        const lastCell = row.lastElementChild;
        lastCell?.remove();
      });

      container.appendChild(clonedTable);

      const afterTable = document.createElement('div');
      afterTable.innerHTML =
        '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      // Set CSS styles to avoid content overlap
      const style = document.createElement('style');
      style.innerHTML = `
            .page-break {
                page-break-before: always;
            }
            .container {
                margin-bottom: 50px; /* Ensure there is enough margin */
            }
            tr {
                page-break-inside: avoid; /* Prevent row break */
            }
        `;
      container.appendChild(style);

      // Add a page break to avoid overlap
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'inventory.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const handleNameBlur = () => {
    setIsNameFocused(false);
  };

  const handleItemFocus = () => {
    setIsItemFocused(true);
  };

  const handleItemBlur = () => {
    setIsItemFocused(false);
  };

  const handleEditInventory = async (id) => {
    history.push(`/inventory/request/edit/${id}`);
  };

  const handleDeleteInventory = async (id) => {
    setIsModalOpen(true);
    setCurrentId(id);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentId(null);
  };

  const closeModalWithDelete = async () => {
    if (currentId) {
      await InventoryRequestService.delete(currentId);
      setIsModalOpen(false);

      // get all data
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      setCurrentUserId(parsedUser.id);

      if (parsedUser.roleId === 2) {
        setIsTeamLead(true);
        const inventory = await InventoryRequestService.findAll(parsedUser.id);
        const filtered = inventory.filter(
          (data) =>
            data['inventoryRequestStatus'] === 'approved' ||
            data['inventoryRequestStatus'] === 'delivered' ||
            data['inventoryRequestStatus'] === 'out_of_stock',
        );
        setTableData(inventory);
        setFilteredData(filtered);
      } else {
        setIsTeamLead(false);
        const inventory = await InventoryRequestService.findAll(parsedUser.id);
        const filtered = inventory.filter(
          (data) => data['inventoryRequestStatus'] === 'pending',
        );
        setTableData(inventory);
        setFilteredData(filtered);
      }
    }
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            <h4 className="attendance_overview_heading">Inventory Requests</h4>
          </div>
        </div>

        {/* <div className="leavesIndex__header leavesIndex__header__inventory">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              <div className="filterContainer">
                {isMyTeam && (
                  <div className="remaingLeavesCounterEach inventoryFilterFields">
                    <div
                      className={`searchNameFilterParent searchNameFilterParentSpecific ${
                        isNameFocused ? 'searchNameFilterParentFocused' : ''
                      }`}
                    >
                      <FormControl variant="standard">
                        <OutlinedInput
                          onChange={handleChange}
                          value={searchTerm}
                          className="searchNameFilter"
                          placeholder="Name"
                          onFocus={handleNameFocus}
                          onBlur={handleNameBlur}
                          endAdornment={
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className="remaingLeavesCounterEach inventoryFilterFields">
                  <div
                    className={`searchNameFilterParent searchNameFilterParentSpecific ${
                      isItemFocused ? 'searchNameFilterParentFocused' : ''
                    }`}
                  >
                    <FormControl variant="standard">
                      <OutlinedInput
                        onChange={handleChange}
                        name="item_name"
                        value={searchItemName}
                        className="searchNameFilter"
                        placeholder="Item Name"
                        onFocus={handleItemFocus}
                        onBlur={handleItemBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="remaingLeavesCounterEach inventoryFilterFields">
                  <div className="singleInputField singleInputFieldFilter">
                    <select
                      name="leave_type"
                      id="leave_type"
                      className="singleInputFilter"
                      onChange={handleSelectedStatus}
                      value={selectedStatus}
                    >
                      <option value="" disabled style={{ display: 'none' }}>
                        Search By Status
                      </option>
                      {!isTeamLead && <option value="pending">Pending</option>}
                      <option value="approved">Approved</option>
                      {!isTeamLead && (
                        <option value="rejected">Rejected</option>
                      )}
                      <option value="delivered">Delievered</option>
                      <option value="out_of_stock">Out Of Stock</option>
                    </select>
                  </div>
                </div>
                <div
                  className="remaingLeavesCounterEachInventoryButton"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <button
                    onClick={addNewRequest}
                    className="downloadPDFButton downloadPDFButton2 inventoryFilterButtons"
                  >
                    Add New Request
                  </button>
                  <button
                    onClick={handleDownloadPdf}
                    className="downloadPDFButton downloadPDFButton2"
                  >
                    Download PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              {currentItems.length > 0 ? (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                    id="your-table-id"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentItems.map((row, index) => {
                        const serialNumber = indexOfFirstItem + index + 1;
                        return (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {serialNumber}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['full_name']}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['inventoryItemName']}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['ItemsRequired']}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['date']}
                            </TableCell>
                            {row['inventoryRequestStatus'] === 'approved' && (
                              <TableCell
                                style={{
                                  color: '#45a259',
                                  textAlign: 'center',
                                }}
                              >
                                {row['inventoryRequestStatus']}
                              </TableCell>
                            )}
                            {row['inventoryRequestStatus'] === 'rejected' && (
                              <TableCell
                                style={{
                                  color: '#d7364d',
                                  textAlign: 'center',
                                }}
                              >
                                {row['inventoryRequestStatus']}
                              </TableCell>
                            )}
                            {row['inventoryRequestStatus'] === 'pending' && (
                              <TableCell
                                style={{
                                  color: '#0066ff',
                                  textAlign: 'center',
                                }}
                              >
                                {row['inventoryRequestStatus']}
                              </TableCell>
                            )}

                            {row['inventoryRequestStatus'] === 'delivered' && (
                              <TableCell
                                style={{
                                  color: '#0066ff',
                                  textAlign: 'center',
                                }}
                              >
                                {row['inventoryRequestStatus']}
                              </TableCell>
                            )}
                            {row['inventoryRequestStatus'] ===
                              'out_of_stock' && (
                              <TableCell
                                style={{
                                  color: '#0066ff',
                                  textAlign: 'center',
                                }}
                              >
                                {row['inventoryRequestStatus']}
                              </TableCell>
                            )}

                            <TableCell style={{ textAlign: 'center' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                {/* view */}
                                <div>
                                  <button
                                    style={{
                                      border: 'none',
                                      color: '#0066ff',
                                      backgroundColor: 'transparent',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      handleInventoryView(
                                        row['inventoryRequestId'],
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                    >
                                      <g data-name="Group 295">
                                        <path
                                          data-name="visibility_FILL0_wght300_GRAD0_opsz24 (1)"
                                          d="M13.003 16.95a4.236 4.236 0 0 0 3.115-1.284 4.248 4.248 0 0 0 1.281-3.117 4.236 4.236 0 0 0-1.284-3.115 4.248 4.248 0 0 0-3.117-1.281 4.236 4.236 0 0 0-3.115 1.284 4.248 4.248 0 0 0-1.281 3.117 4.236 4.236 0 0 0 1.284 3.115 4.248 4.248 0 0 0 3.117 1.281zm0-1.485a2.809 2.809 0 0 1-2.063-.85 2.809 2.809 0 0 1-.85-2.063 2.809 2.809 0 0 1 .85-2.063 2.809 2.809 0 0 1 2.063-.85 2.809 2.809 0 0 1 2.063.85 2.809 2.809 0 0 1 .85 2.063 2.809 2.809 0 0 1-.85 2.063 2.809 2.809 0 0 1-2.065.85zm0 4.639a11.281 11.281 0 0 1-6.231-1.817 13.565 13.565 0 0 1-4.509-4.782 1.9 1.9 0 0 1-.2-.468 1.892 1.892 0 0 1-.062-.484 1.884 1.884 0 0 1 .062-.484 1.9 1.9 0 0 1 .2-.468 13.564 13.564 0 0 1 4.509-4.782 11.281 11.281 0 0 1 6.229-1.82 11.281 11.281 0 0 1 6.231 1.818 13.564 13.564 0 0 1 4.514 4.782 1.9 1.9 0 0 1 .2.468 1.893 1.893 0 0 1 .062.484 1.885 1.885 0 0 1-.062.484 1.9 1.9 0 0 1-.2.468 13.565 13.565 0 0 1-4.509 4.782 11.281 11.281 0 0 1-6.236 1.814zm-.002-7.552zm0 5.934a10.3 10.3 0 0 0 5.6-1.6 10.543 10.543 0 0 0 3.9-4.329 10.544 10.544 0 0 0-3.9-4.329 10.3 10.3 0 0 0-5.6-1.6 10.3 10.3 0 0 0-5.6 1.6 10.544 10.544 0 0 0-3.9 4.329 10.543 10.543 0 0 0 3.9 4.329 10.3 10.3 0 0 0 5.6 1.6z"
                                        />
                                        <path
                                          data-name="Rectangle 293"
                                          fill="none"
                                          d="M0 0h26v26H0z"
                                        />
                                      </g>
                                    </svg>
                                  </button>
                                </div>
                                {/* edit */}
                                {row['inventoryRequestUsersId'] ===
                                parseInt(currentUserId) ? (
                                  <div style={{ margin: '0 0.5vw' }}>
                                    {row['inventoryRequestStatus'] ===
                                    'pending' ? (
                                      <button
                                        style={{
                                          border: 'none',
                                          color: '#0066ff',
                                          backgroundColor: 'transparent',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleEditInventory(
                                            row['inventoryRequestId'],
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 26 26"
                                        >
                                          <g data-name="Group 303">
                                            <path d="M4.099 24a2.027 2.027 0 0 1-1.49-.61 2.027 2.027 0 0 1-.61-1.49V6.352a2.027 2.027 0 0 1 .61-1.49 2.027 2.027 0 0 1 1.49-.61h7.96a.785.785 0 0 1 .653.274.939.939 0 0 1 .218.6.917.917 0 0 1-.227.6.811.811 0 0 1-.662.274H4.099a.342.342 0 0 0-.246.112.342.342 0 0 0-.112.246v15.548a.342.342 0 0 0 .112.246.342.342 0 0 0 .246.112h15.548a.342.342 0 0 0 .246-.112.342.342 0 0 0 .112-.246V13.89a.785.785 0 0 1 .274-.653.939.939 0 0 1 .6-.218.939.939 0 0 1 .6.218.785.785 0 0 1 .274.653v8.015a2.027 2.027 0 0 1-.61 1.49 2.027 2.027 0 0 1-1.49.61zm7.775-9.874zm-2.9 1.854v-2.069a2.062 2.062 0 0 1 .163-.808 2.084 2.084 0 0 1 .449-.674l9.912-9.912a1.7 1.7 0 0 1 .594-.394 1.83 1.83 0 0 1 .659-.124 1.778 1.778 0 0 1 .657.124 1.678 1.678 0 0 1 .574.383l1.459 1.454a1.98 1.98 0 0 1 .388.6 1.713 1.713 0 0 1 .135.661 1.881 1.881 0 0 1-.115.65 1.609 1.609 0 0 1-.385.585l-9.946 9.946a2.209 2.209 0 0 1-.674.458 1.97 1.97 0 0 1-.808.172h-2.013a1.017 1.017 0 0 1-.749-.3 1.017 1.017 0 0 1-.304-.752zM22.239 5.23 20.78 3.738zM10.716 15.287h1.448l7.24-7.24-.724-.724-.775-.746-7.189 7.189zm7.964-7.964-.775-.746.775.746.724.724z" />
                                            <path
                                              data-name="Rectangle 294"
                                              fill="none"
                                              d="M0 0h26v26H0z"
                                            />
                                          </g>
                                        </svg>
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          border: 'none',
                                          color: '#0066ff',
                                          backgroundColor: 'transparent',
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 26 26"
                                        >
                                          <g data-name="Group 296">
                                            <path
                                              d="M142.1-875.537a2.027 2.027 0 0 1-1.49-.61 2.027 2.027 0 0 1-.61-1.49v-15.548a2.027 2.027 0 0 1 .61-1.49 2.027 2.027 0 0 1 1.49-.61h7.96a.785.785 0 0 1 .653.274.939.939 0 0 1 .218.6.917.917 0 0 1-.227.6.811.811 0 0 1-.662.274H142.1a.342.342 0 0 0-.246.112.342.342 0 0 0-.112.246v15.548a.342.342 0 0 0 .112.246.342.342 0 0 0 .246.112h15.548a.342.342 0 0 0 .246-.112.342.342 0 0 0 .112-.246v-8.016a.785.785 0 0 1 .274-.653.939.939 0 0 1 .6-.218.939.939 0 0 1 .6.218.785.785 0 0 1 .274.653v8.015a2.027 2.027 0 0 1-.61 1.49 2.027 2.027 0 0 1-1.49.61zm7.775-9.874zm-2.9 1.854v-2.069a2.062 2.062 0 0 1 .163-.808 2.084 2.084 0 0 1 .449-.674l9.912-9.912a1.7 1.7 0 0 1 .594-.394 1.83 1.83 0 0 1 .659-.124 1.778 1.778 0 0 1 .657.124 1.678 1.678 0 0 1 .574.383l1.459 1.454a1.98 1.98 0 0 1 .388.6 1.713 1.713 0 0 1 .135.661 1.881 1.881 0 0 1-.115.65 1.609 1.609 0 0 1-.385.585l-9.946 9.946a2.209 2.209 0 0 1-.674.458 1.97 1.97 0 0 1-.808.172h-2.013a1.017 1.017 0 0 1-.749-.3 1.017 1.017 0 0 1-.304-.752zm13.265-10.75-1.459-1.492zm-11.523 10.057h1.448l7.24-7.24-.724-.724-.775-.746-7.189 7.189zm7.964-7.964-.775-.746.775.746.724.724z"
                                              transform="translate(-138.001 899.537)"
                                              style={{ opacity: '.2' }}
                                            />
                                            <path
                                              data-name="Rectangle 294"
                                              fill="none"
                                              d="M0 0h26v26H0z"
                                            />
                                          </g>
                                        </svg>
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div style={{ margin: '0 0.5vw' }}>
                                    <button
                                      style={{
                                        border: 'none',
                                        color: '#0066ff',
                                        backgroundColor: 'transparent',
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 26 26"
                                      >
                                        <g data-name="Group 296">
                                          <path
                                            d="M142.1-875.537a2.027 2.027 0 0 1-1.49-.61 2.027 2.027 0 0 1-.61-1.49v-15.548a2.027 2.027 0 0 1 .61-1.49 2.027 2.027 0 0 1 1.49-.61h7.96a.785.785 0 0 1 .653.274.939.939 0 0 1 .218.6.917.917 0 0 1-.227.6.811.811 0 0 1-.662.274H142.1a.342.342 0 0 0-.246.112.342.342 0 0 0-.112.246v15.548a.342.342 0 0 0 .112.246.342.342 0 0 0 .246.112h15.548a.342.342 0 0 0 .246-.112.342.342 0 0 0 .112-.246v-8.016a.785.785 0 0 1 .274-.653.939.939 0 0 1 .6-.218.939.939 0 0 1 .6.218.785.785 0 0 1 .274.653v8.015a2.027 2.027 0 0 1-.61 1.49 2.027 2.027 0 0 1-1.49.61zm7.775-9.874zm-2.9 1.854v-2.069a2.062 2.062 0 0 1 .163-.808 2.084 2.084 0 0 1 .449-.674l9.912-9.912a1.7 1.7 0 0 1 .594-.394 1.83 1.83 0 0 1 .659-.124 1.778 1.778 0 0 1 .657.124 1.678 1.678 0 0 1 .574.383l1.459 1.454a1.98 1.98 0 0 1 .388.6 1.713 1.713 0 0 1 .135.661 1.881 1.881 0 0 1-.115.65 1.609 1.609 0 0 1-.385.585l-9.946 9.946a2.209 2.209 0 0 1-.674.458 1.97 1.97 0 0 1-.808.172h-2.013a1.017 1.017 0 0 1-.749-.3 1.017 1.017 0 0 1-.304-.752zm13.265-10.75-1.459-1.492zm-11.523 10.057h1.448l7.24-7.24-.724-.724-.775-.746-7.189 7.189zm7.964-7.964-.775-.746.775.746.724.724z"
                                            transform="translate(-138.001 899.537)"
                                            style={{ opacity: '.2' }}
                                          />
                                          <path
                                            data-name="Rectangle 294"
                                            fill="none"
                                            d="M0 0h26v26H0z"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                  </div>
                                )}
                                {/* delete */}
                                {row['inventoryRequestUsersId'] ===
                                parseInt(currentUserId) ? (
                                  <div>
                                    {row['inventoryRequestStatus'] ===
                                    'pending' ? (
                                      <button
                                        style={{
                                          border: 'none',
                                          color: '#0066ff',
                                          backgroundColor: 'transparent',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleDeleteInventory(
                                            row['inventoryRequestId'],
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 26 26"
                                        >
                                          <g data-name="Group 302">
                                            <path
                                              data-name="delete_FILL0_wght300_GRAD0_opsz24 (1)"
                                              d="M6.658 24a2.267 2.267 0 0 1-1.663-.692 2.268 2.268 0 0 1-.692-1.663V5.107h-.326a.945.945 0 0 1-.7-.281.946.946 0 0 1-.281-.7.944.944 0 0 1 .281-.7.946.946 0 0 1 .7-.281h4.886a1.111 1.111 0 0 1 .337-.816 1.111 1.111 0 0 1 .816-.337h5.513a1.111 1.111 0 0 1 .816.337 1.111 1.111 0 0 1 .337.816h4.886a.945.945 0 0 1 .7.281.947.947 0 0 1 .281.7.945.945 0 0 1-.281.7.946.946 0 0 1-.7.281h-.326v16.538a2.268 2.268 0 0 1-.692 1.663 2.267 2.267 0 0 1-1.663.692zM19.287 5.107H6.257v16.538a.39.39 0 0 0 .113.288.39.39 0 0 0 .288.113h12.228a.39.39 0 0 0 .288-.113.39.39 0 0 0 .113-.288zm-8.92 14.333a.944.944 0 0 0 .7-.281.946.946 0 0 0 .281-.7V8.687a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.946.946 0 0 0 .281.7.946.946 0 0 0 .7.281zm4.811 0a.945.945 0 0 0 .7-.281.946.946 0 0 0 .281-.7V8.687a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.945.945 0 0 0 .281.7.946.946 0 0 0 .7.281zM6.257 5.107z"
                                            />
                                            <path
                                              data-name="Rectangle 292"
                                              fill="none"
                                              d="M0 0h26v26H0z"
                                            />
                                          </g>
                                        </svg>
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          border: 'none',
                                          color: '#0066ff',
                                          backgroundColor: 'transparent',
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 26 26"
                                        >
                                          <g data-name="Group 297">
                                            <path
                                              data-name="delete_FILL0_wght300_GRAD0_opsz24 (1)"
                                              d="M183.659-793.383a2.267 2.267 0 0 1-1.663-.692 2.268 2.268 0 0 1-.692-1.663v-16.538h-.326a.945.945 0 0 1-.7-.281.946.946 0 0 1-.281-.7.944.944 0 0 1 .281-.7.946.946 0 0 1 .7-.281h4.886a1.111 1.111 0 0 1 .337-.816 1.111 1.111 0 0 1 .816-.337h5.513a1.111 1.111 0 0 1 .816.337 1.111 1.111 0 0 1 .337.816h4.886a.945.945 0 0 1 .7.281.947.947 0 0 1 .281.7.945.945 0 0 1-.281.7.946.946 0 0 1-.7.281h-.326v16.538a2.268 2.268 0 0 1-.692 1.663 2.267 2.267 0 0 1-1.663.692zm12.629-18.893h-13.03v16.538a.39.39 0 0 0 .113.288.39.39 0 0 0 .288.113h12.228a.39.39 0 0 0 .288-.113.39.39 0 0 0 .113-.288zm-8.92 14.333a.944.944 0 0 0 .7-.281.946.946 0 0 0 .281-.7v-9.772a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.946.946 0 0 0 .281.7.946.946 0 0 0 .7.281zm4.811 0a.945.945 0 0 0 .7-.281.946.946 0 0 0 .281-.7v-9.772a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.945.945 0 0 0 .281.7.946.946 0 0 0 .7.281zm-8.921-14.333z"
                                              transform="translate(-176.001 817.383)"
                                              style={{ opacity: '.2' }}
                                            />
                                            <path
                                              data-name="Rectangle 292"
                                              fill="none"
                                              d="M0 0h26v26H0z"
                                            />
                                          </g>
                                        </svg>
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      style={{
                                        border: 'none',
                                        color: '#0066ff',
                                        backgroundColor: 'transparent',
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                      >
                                        <g data-name="Group 297">
                                          <path
                                            data-name="delete_FILL0_wght300_GRAD0_opsz24 (1)"
                                            d="M183.659-793.383a2.267 2.267 0 0 1-1.663-.692 2.268 2.268 0 0 1-.692-1.663v-16.538h-.326a.945.945 0 0 1-.7-.281.946.946 0 0 1-.281-.7.944.944 0 0 1 .281-.7.946.946 0 0 1 .7-.281h4.886a1.111 1.111 0 0 1 .337-.816 1.111 1.111 0 0 1 .816-.337h5.513a1.111 1.111 0 0 1 .816.337 1.111 1.111 0 0 1 .337.816h4.886a.945.945 0 0 1 .7.281.947.947 0 0 1 .281.7.945.945 0 0 1-.281.7.946.946 0 0 1-.7.281h-.326v16.538a2.268 2.268 0 0 1-.692 1.663 2.267 2.267 0 0 1-1.663.692zm12.629-18.893h-13.03v16.538a.39.39 0 0 0 .113.288.39.39 0 0 0 .288.113h12.228a.39.39 0 0 0 .288-.113.39.39 0 0 0 .113-.288zm-8.92 14.333a.944.944 0 0 0 .7-.281.946.946 0 0 0 .281-.7v-9.772a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.946.946 0 0 0 .281.7.946.946 0 0 0 .7.281zm4.811 0a.945.945 0 0 0 .7-.281.946.946 0 0 0 .281-.7v-9.772a.945.945 0 0 0-.281-.7.946.946 0 0 0-.7-.281.945.945 0 0 0-.7.281.946.946 0 0 0-.281.7v9.772a.945.945 0 0 0 .281.7.946.946 0 0 0 .7.281zm-8.921-14.333z"
                                            transform="translate(-176.001 817.383)"
                                            style={{ opacity: '.2' }}
                                          />
                                          <path
                                            data-name="Rectangle 292"
                                            fill="none"
                                            d="M0 0h26v26H0z"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: 'center', padding: '20px' }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {currentItems.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
        </Card>

        <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="approvalText">
                  Are you sure that you want to delete that Inventory Request?
                </span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="rejectButtonValidationModal"
                    onClick={closeModalWithDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>
      </div>
    </Layout>
  );
}
