import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';
import LeaveTypeService from '../../services/LeaveTypeService';
import specialRequestService from '../../services/specialRequestService';
import ValidationModal from '../BootstrapValidationModal';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  { value: 'ceo', label: 'CEO' },
  { value: 'coo', label: 'COO' },
  { value: 'cfo', label: 'CFO' },
  { value: 'hr', label: 'HR' },
  { value: 'director', label: 'Director Production' },
  { value: 'team_lead', label: 'Team Lead' },
];

export default function NewSpecial() {
  const history = useHistory();

  const [subject, setSubject] = useState('');
  const [subjectError, setSubjectError] = useState(false);

  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState(false);

  const [ccUser, setCcUser] = useState([]);

  const [toUser, setToUser] = useState('select_user');
  const [toUserError, setToUserError] = useState(false);

  const [descriptionText, setDescriptionText] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isSelectUser, setIsSelectUser] = useState(false);
  const [progress, setProgress] = React.useState(0);

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [fromDateError, setFromDateError] = useState(false);

  const [selectedToDate, setSelectedToDate] = useState(null);
  const [toDateError, setToDateError] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [dateError, setDateError] = useState(false);

  // multiple leave
  const [leaveTypeError, setLeaveTypeError] = useState(false);
  const [leaveDurationError, setLeaveDurationError] = useState(false);
  const [selectedHalfDayDateError, setSelectedHalfDayDateError] =
    useState(false);
  const [selectedWhichHalfError, setSelectedWhichHalfError] = useState(false);
  const [shortDateError, setShortDateError] = useState(false);

  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [selectedHalf, setSelectedHalf] = useState('');
  const [selectedleaveTenure, setSelectedleaveTenure] = useState('');
  const [selectedHalfDayDate, setSelectedHalfDayDate] = useState(null);
  const [selectedShortFromDate, setSelectedShortFromDate] = useState(null);
  const [allLeaveType, setAllLeaveType] = useState([]);
  const [createdById, setCreatedById] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      const leaveType = await LeaveTypeService.findAll();
      setAllLeaveType(leaveType);

      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      setCreatedById(parsedUser.id);
    };
    fetchUserData();
  }, []);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    if (name === 'subject') {
      if (value !== '') {
        setSubjectError(false);
        if (value === 'multiple_leave' || value === 'work_from_home') {
          const usersList = ['ceo', 'coo', 'cfo', 'hr'];
          setCcUser(usersList);
          setToUser('director');
          setIsSelectUser(true);
        } else if (value === 'missed_attendance') {
          const usersList = ['ceo', 'coo', 'cfo', 'hr', 'director'];
          setCcUser(usersList);
          setToUser('team_lead');
          setIsSelectUser(true);
        } else {
          const usersList = [];
          setCcUser(usersList);
          setToUser('');
          setIsSelectUser(false);
        }
      }
      setSubject(value);
    }

    if (name === 'description') {
      setDescriptionText(value);
      if (value !== '') {
        setDescriptionError(false);
      }
    }
    if (name === 'cc_users') {
      setCcUser(value);
    }
    if (name === 'to_user') {
      if (value !== '') {
        setToUserError(false);
      }
      setToUser(value);
    }
    if (name === 'select_half') {
      if (value !== '') {
        setSelectedWhichHalfError(false);
      }
      setSelectedHalf(value);
    }

    if (name === 'reason') {
      if (value === '') {
        setReasonError(true);
      } else {
        setReasonError(false);
        setReason(value);
      }
    }
  };

  useEffect(() => {
    let intervalId;
    let isMounted = true;

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/special`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  const onSubmit = async () => {
    if (subject === '') {
      setSubjectError(true);
      setIsModalOpen(true);
      return;
    } else {
      setSubjectError(false);
    }

    if (toUser === '') {
      setToUserError(true);
      setIsModalOpen(true);
      return;
    } else {
      setToUserError(false);
    }

    if (subject === 'work_from_home') {
      if (!selectedFromDate) {
        setFromDateError(true);
        return;
      } else {
        setFromDateError(false);
      }

      if (!selectedToDate) {
        setToDateError(true);
        return;
      } else {
        setToDateError(false);
      }
    }

    if (subject === 'multiple_leave') {
      if (reason === '') {
        setReasonError(true);
        setIsModalOpen(true);
        return;
      } else {
        setReasonError(false);
      }

      if (selectedleaveTenure === '') {
        setLeaveDurationError(true);
        setIsModalOpen(true);
        return;
      } else {
        setLeaveDurationError(false);
      }

      const validateDescription = () => {
        if (descriptionText === '') {
          setDescriptionError(true);
          setIsModalOpen(true);
          setIsSubmitting(false);

          return false;
        } else {
          setDescriptionError(false);
          return true;
        }
      };

      const validateDate = (date, setError) => {
        if (!date) {
          setError(true);
          setIsModalOpen(true);
          setIsSubmitting(false);
          return false;
        } else {
          setError(false);
          return true;
        }
      };

      const validateToDate = (date, setError) => {
        if (!date) {
          setToDateError(true);
          setIsModalOpen(true);
          setIsSubmitting(false);
          return false;
        } else {
          setToDateError(false);
          return true;
        }
      };

      const validateHalf = () => {
        if (selectedHalf === '') {
          setSelectedWhichHalfError(true);
          setIsModalOpen(true);
          setIsSubmitting(false);

          return false;
        } else {
          setSelectedWhichHalfError(false);
          return true;
        }
      };

      const buildLeaveData = (leaveTenure, date, extraData = {}) => {
        return {
          reason: reason,
          date: date.toISOString(),
          status: 'pending',
          description: descriptionText,
          leaveTenure: leaveTenure,
          createdById: createdById,
          specialRequestData: 'specialRequest',
          ...extraData,
        };
      };

      setIsSubmitting(true);

      try {
        switch (selectedleaveTenure) {
          case 'half_day':
            if (
              !validateDate(selectedHalfDayDate, setSelectedHalfDayDateError) ||
              !validateHalf() ||
              !validateDescription()
            )
              return;
            await LeaveService.save(
              buildLeaveData('half_day', selectedHalfDayDate, {
                which_half: selectedHalf,
              }),
            );
            break;

          case 'short_leave':
            if (
              !validateDate(selectedShortFromDate, setShortDateError) ||
              !validateDescription()
            )
              return;
            await LeaveService.save(
              buildLeaveData('short_leave', selectedShortFromDate),
            );
            break;

          case 'full_day':
            if (
              !validateDate(selectedDate, setDateError) ||
              !validateToDate(selectedToDate, setToDateError) ||
              !validateDescription()
            )
              return;
            await LeaveService.save(
              buildLeaveData('full_day', selectedDate, {
                toDate: selectedToDate,
              }),
            );
            break;

          default:
            throw new Error('Invalid leave tenure type');
        }
        setIsSubmittingModalOpen(true);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
    if (descriptionText === '') {
      setDescriptionError(true);
      setIsModalOpen(true);
      return;
    } else {
      setDescriptionError(false);
    }
    if (subject !== 'multiple_leave') {
      try {
        // setIsSubmitting(true);
        const storedUser = localStorage.getItem('user');
        const parsedUser = JSON.parse(storedUser);
        if (subject === 'work_from_home') {
          const data = {
            subject: subject,
            to_user: toUser,
            cc_user: ccUser,
            from_date: selectedFromDate.format(),
            to_date: selectedToDate.format(),
            description: descriptionText,
            usersId: parsedUser.id,
          };

          await specialRequestService.save(data);
          setIsSubmittingModalOpen(true);
        } else {
          const data = {
            subject: subject,
            to_user: toUser,
            cc_user: ccUser,
            description: descriptionText,
            usersId: parsedUser.id,
          };
          await specialRequestService.save(data);
          setIsSubmittingModalOpen(true);
        }
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
    if (date) {
      setFromDateError(false);
    }
  };

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
    if (date) {
      setToDateError(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      setDateError(false);
    }
  };

  const handleSelectLeaveType = (event) => {
    if (event.target.value !== '') {
      setLeaveTypeError(false);
    }
    setSelectedLeaveType(event.target.value);
  };

  const handleShortFromDateChange = (date) => {
    setSelectedShortFromDate(date);
    if (date) {
      setShortDateError(false);
    }
  };

  const handleRadioChange = (event) => {
    if (event.target.value !== '') {
      setLeaveDurationError(false);
    }
    setSelectedHalfDayDate(null);
    setDescriptionText('');
    setSelectedHalf('');
    setSelectedleaveTenure(event.target.value);
  };

  const handleHalfDayChange = (date) => {
    setSelectedHalfDayDate(date);
    if (date) {
      setSelectedHalfDayDateError(false);
    }
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Special Request</h4>

        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField specialRequestSubject specialeaveDurationInputMain">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Subject
                    </InputLabel>
                    {/* <select
                      onChange={handleChange}
                      name="subject"
                      value={subject}
                      disabled={isSubmitting}
                      className={`${
                        subjectError ? 'leaveDurationInput-error' : ''
                      }`}
                    >
                      <option value="">Select Subject</option>
                      <option value="loan">Loan Request</option>
                      <option value="multiple_leave">Special Leave</option>
                      <option value="work_from_home">Work From Home</option>
                      <option value="missed_attendance">
                        Missed Attendance
                      </option>
                      <option value="other">Other</option>
                    </select> */}
                    <FormControl
                      className="addNewUserForm specialLeaveRequest"
                      fullWidth
                      variant="outlined"
                    >
                      <Select
                        onChange={handleChange}
                        name="subject"
                        value={subject}
                        disabled={isSubmitting}
                        displayEmpty
                        className={`${
                          subjectError ? 'leaveDurationInput-error' : ''
                        }`}
                      >
                        <MenuItem value="">Select Subject</MenuItem>
                        <MenuItem value="loan">Loan Request</MenuItem>
                        <MenuItem value="multiple_leave">
                          Special Leave
                        </MenuItem>
                        <MenuItem value="work_from_home">
                          Work From Home
                        </MenuItem>
                        <MenuItem value="missed_attendance">
                          Missed Attendance
                        </MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '1vw 0 0 0',
                }}
              >
                <div className="singleInputField specialeaveDurationInput">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      To
                    </InputLabel>
                    <Select
                      value={toUser}
                      onChange={handleChange}
                      name="to_user"
                      id="to_user"
                      disabled={isSelectUser || isSubmitting}
                      className={`specialReuestScreen ${
                        toUserError ? 'leaveDurationInput-error' : ''
                      }`}
                    >
                      <MenuItem value="select_user">Select User</MenuItem>
                      {options.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={ccUser.includes(option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="singleInputField specialeaveDurationInput">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      CC
                    </InputLabel>

                    <FormControl
                      className="MultiSelectParent MultiSelectParentCC"
                      sx={{ m: 1, width: 300 }}
                    >
                      {/* {ccUser.length === 0 && (
                        <InputLabel id="demo-multiple-chip-label">
                          Select User
                        </InputLabel>
                      )} */}
                      <Select
                        className={`multiSelect`}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        name="cc_users"
                        multiple
                        disabled={isSelectUser || isSubmitting}
                        value={ccUser}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          );
                        }}
                        MenuProps={MenuProps}
                      >
                        {options.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            disabled={toUser === option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              {subject === 'work_from_home' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1vw 0 0 0',
                  }}
                >
                  <div className="singleInputField">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Date From
                        </InputLabel>

                        <DesktopDatePicker
                          onChange={handleFromDateChange}
                          className={`leaveDurationInput newLeaveScreen ${
                            fromDateError ? 'leaveDurationInput-error' : ''
                          }`}
                          // minDate={TwoDaysBefore}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="singleInputField">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          To
                        </InputLabel>

                        <DesktopDatePicker
                          onChange={handleToDateChange}
                          className={`leaveDurationInput newLeaveScreen ${
                            toDateError ? 'leaveDurationInput-error' : ''
                          }`}
                          // minDate={TwoDaysBefore}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              )}

              {subject === 'multiple_leave' && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '1vw 0 0 0',
                    }}
                  >
                    <div className="singleInputField specialeaveDurationInput">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Reason
                        </InputLabel>
                        {/* <select
                          name="leave_type"
                          id="leave_type"
                          className={`leaveDurationInput ${
                            leaveTypeError ? 'leaveDurationInput-error' : ''
                          }`}
                          onChange={handleSelectLeaveType}
                        >
                          <option value="">Select Leave Type</option>
                          {allLeaveType.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select> */}
                        <FormControl
                          className="addNewUserForm specialLeaveRequest"
                          fullWidth
                          variant="outlined"
                        >
                          <Select
                            onChange={handleChange}
                            name="reason"
                            value={reason}
                            disabled={isSubmitting}
                            displayEmpty
                            className={`${
                              reasonError ? 'leaveDurationInput-error' : ''
                            }`}
                          >
                            <MenuItem disabled value="">
                              Select Reason
                            </MenuItem>
                            <MenuItem value="multiple_leave">
                              Multiple Leaves in One Month
                            </MenuItem>
                            <MenuItem value="missed_leave">
                              Missed Leave
                            </MenuItem>
                            <MenuItem value="intern_probation">
                              Intern Or Probation Period
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="singleInputField specialeaveDurationInput">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Duration
                        </InputLabel>
                        <select
                          name="leave_type"
                          id="leave_type"
                          className={`leaveDurationInput ${
                            leaveDurationError ? 'leaveDurationInput-error' : ''
                          }`}
                          onChange={handleRadioChange}
                        >
                          <option value="">Select Duration</option>
                          <option value="full_day">Full Day</option>
                          <option value="half_day">Half Day</option>
                          <option value="short_leave">Short Leave</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {reason !== '' && selectedleaveTenure === 'half_day' && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          margin: '1vw 0 0 0',
                        }}
                      >
                        <div className="singleInputField specialeaveDurationInput">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                'DatePicker',
                                'MobileDatePicker',
                                'DesktopDatePicker',
                                'StaticDatePicker',
                              ]}
                            >
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date
                              </InputLabel>
                              <DesktopDatePicker
                                onChange={handleHalfDayChange}
                                className={`leaveDurationInput leaveDurationInputDate ${
                                  selectedHalfDayDateError
                                    ? 'leaveDurationInput-error'
                                    : ''
                                }`}
                                // minDate={TwoDaysBefore}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="singleInputField specialeaveDurationInput">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                'DatePicker',
                                'MobileDatePicker',
                                'DesktopDatePicker',
                                'StaticDatePicker',
                              ]}
                            >
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Select Half
                              </InputLabel>
                              <select
                                name="select_half"
                                id="select_half"
                                className={`leaveDurationInput ${
                                  selectedWhichHalfError
                                    ? 'leaveDurationInput-error'
                                    : ''
                                }`}
                                style={{ padding: '16.5px 12px' }}
                                onChange={handleChange}
                              >
                                <option value="">Select Half</option>
                                <option value="first_half">First</option>
                                <option value="second_half">Second</option>
                              </select>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* short */}
                  {reason !== '' && selectedleaveTenure === 'short_leave' && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          margin: '1vw 0 0 0',
                        }}
                      >
                        <div className="singleInputField specialeaveDurationInputShort">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker']}>
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date
                              </InputLabel>
                              <DateTimePicker
                                onChange={handleShortFromDateChange}
                                className={`leaveDurationInput ${
                                  shortDateError
                                    ? 'leaveDurationInput-error'
                                    : ''
                                }`}
                                // minDate={TwoDaysBefore}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="singleInputField specialeaveDurationInput"></div>
                      </div>
                    </div>
                  )}

                  {reason !== '' && selectedleaveTenure === 'full_day' && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          margin: '1vw 0 0 0',
                        }}
                      >
                        <div className="singleInputField specialeaveDurationInput">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                'DatePicker',
                                'MobileDatePicker',
                                'DesktopDatePicker',
                                'StaticDatePicker',
                              ]}
                            >
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                Date
                              </InputLabel>

                              <DesktopDatePicker
                                onChange={handleDateChange}
                                className={`leaveDurationInput  ${
                                  dateError ? 'leaveDurationInput-error' : ''
                                }`}
                                // minDate={TwoDaysBefore}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>

                        <div className="singleInputField specialeaveDurationInput">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                'DatePicker',
                                'MobileDatePicker',
                                'DesktopDatePicker',
                                'StaticDatePicker',
                              ]}
                            >
                              <InputLabel
                                className="formInputLabels"
                                shrink
                                htmlFor="bootstrap-input"
                              >
                                To
                              </InputLabel>

                              <DesktopDatePicker
                                onChange={handleToDateChange}
                                className={`leaveDurationInput ${
                                  toDateError ? 'leaveDurationInput-error' : ''
                                }`}
                                // minDate={TwoDaysBefore}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1vw 0 0 0',
                  }}
                >
                  <div className="singleInputFieldTextArea specialRequestTextArea">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Description
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput ${
                          descriptionError ? 'leaveDurationInput-error' : ''
                        }`}
                        name="description"
                        onChange={handleChange}
                        value={descriptionText}
                        placeholder="Enter text here..."
                        disabled={isSubmitting}
                        rows={6}
                        cols={50}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField"></div>
                <div className="singleInputField">
                  <div className="formButton">
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="formSubmitButton"
                      onClick={onSubmit}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>

        <ValidationModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ValidationModal">
                <span>Please Enter All Fields To Continue!</span>
                <div className="CloseValidationModalButton">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="CloseValidationModal"
                    onClick={closeModal}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationModal>

        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
