import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import authService from '../services/AuthService';

export default function Login() {
  const { setAuthenticatedUser } = useAuth();
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedPassword, setSelectedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [isValidEmail, setValidEmail] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [validCredentials, setValidCredentials] = useState(true);
  const [emailErrorText, setEmailErrorText] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);

  const history = useHistory();
  const isMounted = useRef(true);

  const eyeSvgCode = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 36 36"
    >
      <path data-name="Path 54" d="M0 0h36v36H0z" fill="none" />
      <path
        data-name="Path 55"
        d="M17.5 7a14.655 14.655 0 0 1 13.23 8.25 14.733 14.733 0 0 1-26.46 0A14.655 14.655 0 0 1 17.5 7m0-3A17.74 17.74 0 0 0 1 15.25a17.725 17.725 0 0 0 33 0A17.74 17.74 0 0 0 17.5 4zm0 7.5a3.75 3.75 0 1 1-3.75 3.75 3.751 3.751 0 0 1 3.75-3.75m0-3a6.75 6.75 0 1 0 6.75 6.75A6.76 6.76 0 0 0 17.5 8.5z"
        transform="translate(.5 2)"
        fill="#15267d"
      />
    </svg>
  `;

  const eyeHideSvgCode = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 36 36"
    onClick={togglePassword}
  >
    <path
      data-name="Path 56"
      d="M0 0h36v36H0zm0 0h36v36H0zm0 0h36v36H0zm0 0h36v36H0z"
      fill="none"
    />
    <path
      data-name="Path 57"
      d="M18 8.225a15.049 15.049 0 0 1 13.631 8.946 15.617 15.617 0 0 1-3.725 5.075l2.179 2.294A19.228 19.228 0 0 0 35 17.171a18.323 18.323 0 0 0-17-12.2 17.479 17.479 0 0 0-5.625.927l2.55 2.684A14.875 14.875 0 0 1 18 8.225zm-1.654 1.854 3.2 3.367a3.968 3.968 0 0 1 1.978 2.082l3.2 3.367a7.989 7.989 0 0 0 .216-1.74A7.118 7.118 0 0 0 18 9.851a6.251 6.251 0 0 0-1.654.228zM2.561 4.76 6.7 9.119A19.045 19.045 0 0 0 1 17.171a18.323 18.323 0 0 0 17 12.2 17.336 17.336 0 0 0 6.676-1.334l5.286 5.563 2.179-2.294L4.74 2.45zm11.591 12.2 4.034 4.246a.741.741 0 0 1-.185.033 3.97 3.97 0 0 1-3.864-4.067c-.001-.082.015-.131.015-.212zM8.9 11.429l2.7 2.847a7.824 7.824 0 0 0-.556 2.9A7.155 7.155 0 0 0 18 24.491a6.677 6.677 0 0 0 2.735-.586L22.25 25.5a15.372 15.372 0 0 1-4.25.618 15.049 15.049 0 0 1-13.631-8.947A15.946 15.946 0 0 1 8.9 11.429z"
      fill="#15267d"
    />
  </svg>`;

  useEffect(() => {
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onSubmit = async () => {
    if (selectedEmail === '') {
      setEmailErrorText('Enter Your Email Address');
      setValidEmail(false);
      return;
    } else {
      setEmailErrorText('');
      setValidEmail(true);
    }

    if (selectedPassword === '') {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    setIsSubmitting(true);
    try {
      const loginRequest = {
        email: selectedEmail,
        password: selectedPassword,
      };
      const data = await authService.login(loginRequest);
      if (isMounted.current) {
        setAuthenticatedUser(data.user);
        localStorage.setItem('user', JSON.stringify(data.user));
        history.push('/');
        setIsSubmitting(false);
        setValidCredentials(true);

        const today = new Date();

        const staticStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1,
        );

        const staticEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
        );

        localStorage.setItem(
          'staticStartDateAttendance',
          JSON.stringify(staticStartDate),
        );
        localStorage.setItem(
          'staticEndDateAttendance',
          JSON.stringify(staticEndDate),
        );
      }
    } catch (error) {
      if (isMounted.current) {
        setValidCredentials(false);
        setIsSubmitting(false);
      }
    }
  };

  const handleEmailExit = () => {
    if (selectedEmail !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(selectedEmail);
      if (isValid) {
        setIsPasswordSubmitting(false);
        setValidEmail(isValid);
        setEmailErrorText('');
      } else {
        setIsPasswordSubmitting(true);
        setValidEmail(isValid);
        setValidCredentials(true);
        setEmailErrorText('Invalid Email');
      }
    } else {
      setIsPasswordSubmitting(false);
      setValidEmail(true);
    }
  };

  const handleEmailChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setSelectedPassword(event.target.value);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const forgetPassword = () => {
    history.push(`/forgetPassword`);
  };

  return (
    <div className="Portal-Login">
      <div className="Portal-Login-Image"></div>
      <div className="Portal-Login-Logo">
        <img alt="logo" src="./logo_3.png"></img>
      </div>
      <div className="Portal-Login-Form">
        <div className="Hello-Welcome-back">
          <span>
            Hello,
            <br /> Welcome back!
          </span>
        </div>
        <Box
          sx={{
            width: 500,
            maxWidth: '100%',
          }}
        >
          <div className="flex flex-col flex-start">
            <div className="inputBox__container">
              <FormControl className="login-input-form" fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="Portal-Login-input"
                  label="Enter Email"
                  onBlur={handleEmailExit}
                  onChange={handleEmailChange}
                  value={selectedEmail}
                  error={!isValidEmail}
                  InputLabelProps={{
                    classes: {
                      root: isValidEmail
                        ? 'Portal-Login-label'
                        : 'Portal-Login-label-error',
                    },
                    shrink: true,
                  }}
                  disabled={isSubmitting}
                />
              </FormControl>
              {!isValidEmail && (
                <p className="error-message-invalid">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                      transform="translate(-80 880)"
                      fill="#f00"
                    />
                  </svg>
                  {emailErrorText}
                </p>
              )}
            </div>
            <div className="inputBox__container_margin"></div>
            <div className="inputBox__container">
              <FormControl className="login-input-form" fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="fullWidth"
                  type={showPassword ? 'text' : 'password'}
                  className={`Portal-Login-input ${
                    !validCredentials || passwordError
                      ? 'Portal-Login-input-error'
                      : ''
                  }`}
                  label="Enter Password"
                  error={!validCredentials || passwordError}
                  required
                  onChange={handlePasswordChange}
                  value={selectedPassword}
                  InputLabelProps={{
                    shrink: true, // Ensure the label moves up when there is input
                    classes: {
                      root:
                        !validCredentials || passwordError
                          ? 'Portal-Login-label-error'
                          : 'Portal-Login-label',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: eyeSvgCode }}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: eyeHideSvgCode,
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={isPasswordSubmitting}
                />
              </FormControl>
              {!validCredentials && (
                <p className="error-message-invalid">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                      transform="translate(-80 880)"
                      fill="#f00"
                    />
                  </svg>
                  Wrong Password. Try again or click Forgot Password to reset
                </p>
              )}

              {passwordError && (
                <p className="flex justify-center error-message-invalid">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                      transform="translate(-80 880)"
                      fill="#f00"
                    />
                  </svg>
                  Enter Your Password
                </p>
              )}
            </div>
            <div className="inputBox__container_margin"></div>
            <div className="inputBox__container">
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  className="login-button"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isPasswordSubmitting}
                  onClick={onSubmit}
                >
                  Login
                </Button>
              </FormControl>
            </div>
            <div className="forgotPasswordButtonDiv">
              <Stack className="forget-button-div" direction="row" spacing={2}>
                <Button
                  disabled={isPasswordSubmitting}
                  className="Forget-Password"
                  variant="text"
                  onClick={forgetPassword}
                >
                  Forgot Password ?
                </Button>
              </Stack>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
