import SearchIcon from '@mui/icons-material/Search';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import AttendanceService from '../../services/AttendanceService';
import DatePickerModal from '../DatePickerModal';

const columns = [
  { id: 'sr_no', label: 'Sr no.', minWidth: 50, sortable: false },
  {
    id: 'full_name',
    label: 'Name',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'late_minutes',
    label: 'Late Minutes',
    minWidth: 100,
    sortable: true,
  },

  {
    id: 'extra_minute',
    label: 'Extra Minutes',
    minWidth: 100,
    sortable: true,
  },

  {
    id: 'absences',
    label: 'Absent',
    minWidth: 100,
    sortable: true,
  },

  {
    id: 'details',
    label: 'Details',
    minWidth: 100,
    sortable: false,
  },
];

export default function AttendanceList() {
  const history = useHistory();
  const toggleContainer = useRef<HTMLDivElement | null>(null);

  const [tableDataMonth, setTableDataMonth] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loggedInUserId, setLoggedInUserId] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isMyTeam, setIsMyTeam] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = async (event) => {
    const searchTermValue = event.target.value;
    setSearchTerm(searchTermValue);

    if (selectedDate) {
      if (searchTermValue !== '') {
        const filtered = tableDataMonth.filter((data) =>
          data.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(tableDataMonth);
      }
    } else {
      const filtered = tableDataMonth.filter((data) =>
        data.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toggleContainer.current &&
      !toggleContainer.current.contains(event.target as Node)
    ) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSort = (columnId: string) => {
    if (columnId === 'sr_no' || columnId === 'details') return;
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortBy === 'sr_no' || sortBy === 'details') return 0;

    if (sortBy === 'usersName') {
      const nameA = a.userFullName.toUpperCase();
      const nameB = b.userFullName.toUpperCase();
      if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    }

    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      setLoggedInUserId(parsedUser.id);

      const staticStartDate = localStorage.getItem('staticStartDateAttendance');
      const startDates = JSON.parse(staticStartDate);
      const startDate = new Date(startDates);

      const staticEndDate = localStorage.getItem('staticEndDateAttendance');
      const endDates = JSON.parse(staticEndDate);
      const endDate = new Date(endDates);

      const selectionRanges = {
        startDate: startDate, // or just startDate,
        endDate: endDate, // or just endDate,
        key: 'selection',
      };

      setSelectionRange(selectionRanges);

      const isSameDate =
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();

      let formattedDate = '';

      if (isSameDate) {
        formattedDate = startDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else {
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();

        if (sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else if (!sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        }
      }

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format: "YYYY-MM-DD"
      };

      setSelectedDate(formattedDate);

      const allFilters = {
        loggedInUserId: parsedUser.id,
        startDate: formatToLocalDate(startDate),
        endDate: formatToLocalDate(endDate),
      };

      const resultData = await AttendanceService.filterFromList(allFilters);
      setFilteredData(resultData.attendance);
      setTableDataMonth(resultData.attendance);
      setIsMyTeam(resultData.attendance.length > 1 ? true : false);
    };
    fetchUserData();
  }, []);

  const handleApply = async () => {
    const startDates = selectionRange.startDate;
    const endDates = selectionRange.endDate;
    const isSameDate =
      startDates.getDate() === endDates.getDate() &&
      startDates.getMonth() === endDates.getMonth() &&
      startDates.getFullYear() === endDates.getFullYear();

    let formattedDate = '';

    if (isSameDate) {
      formattedDate = startDates.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } else {
      const sameMonth = startDates.getMonth() === endDates.getMonth();
      const sameYear = startDates.getFullYear() === endDates.getFullYear();

      if (sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else if (!sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      }
    }

    setSelectedDate(formattedDate);

    localStorage.setItem(
      'staticStartDateAttendance',
      JSON.stringify(startDates),
    );
    localStorage.setItem('staticEndDateAttendance', JSON.stringify(endDates));

    const formatToLocalDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const allFilters = {
      loggedInUserId: loggedInUserId,
      startDate: formatToLocalDate(selectionRange.startDate),
      endDate: formatToLocalDate(selectionRange.endDate),
    };

    const resultData = await AttendanceService.filterFromList(allFilters);

    if (searchTerm !== '') {
      const filtered = resultData.attendance.filter((data) =>
        data.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(resultData.attendance);
    }

    setTableDataMonth(resultData.attendance);

    closeModal();
  };

  const handleSelect = async (ranges) => {
    console.log(ranges.selection);
    setSelectionRange(ranges.selection);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.remove('blurred__sidebar');
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleAttendanceView = async (id) => {
    const path = `/attendance/view/${id}`;

    const searchParams = new URLSearchParams();
    searchParams.append('dateFilter', selectedDate);

    history.push(`${path}?${searchParams.toString()}`);
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const beforeTable = document.createElement('div');
      beforeTable.innerHTML = '<p style="text-align: center;"></p>';
      container.appendChild(beforeTable);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      const rows = clonedTable.querySelectorAll('tr');
      rows.forEach((row) => {
        const lastCell = row.lastElementChild;
        lastCell?.remove();
      });

      container.appendChild(clonedTable);

      // const afterTable = document.createElement('div');
      // afterTable.innerHTML =
      //   '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      // container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      // Set CSS styles to avoid content overlap
      const style = document.createElement('style');
      style.innerHTML = `
            .page-break {
                page-break-before: always;
            }
            .container {
                margin-bottom: 50px; /* Ensure there is enough margin */
            }
            tr {
                page-break-inside: avoid; /* Prevent row break */
            }
        `;
      container.appendChild(style);

      // Add a page break to avoid overlap
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'attendance.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  const staticRanges = [
    {
      label: 'Today',
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        const today = new Date();
        return (
          range.startDate.toDateString() === today.toDateString() &&
          range.endDate.toDateString() === today.toDateString()
        );
      },
      key: 'today',
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      }),
      isSelected: (range) => {
        const yesterday = new Date(
          new Date().setDate(new Date().getDate() - 1),
        );
        return (
          range.startDate.toDateString() === yesterday.toDateString() &&
          range.endDate.toDateString() === yesterday.toDateString()
        );
      },
      key: 'yesterday',
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay()),
        ),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        const startOfWeek = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay()),
        );
        return (
          range.startDate.toDateString() === startOfWeek.toDateString() &&
          range.endDate.toDateString() === new Date().toDateString()
        );
      },
      key: 'thisWeek',
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 7),
        ),
        endDate: new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 1),
        ),
      }),
      isSelected: (range) => {
        const startOfLastWeek = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 7),
        );
        return (
          range.startDate.toDateString() === startOfLastWeek.toDateString() &&
          range.endDate.toDateString() ===
            new Date(
              new Date().setDate(
                new Date().getDate() - new Date().getDay() - 1,
              ),
            ).toDateString()
        );
      },
      key: 'lastWeek',
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        const startOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1,
        );
        return (
          range.startDate.toDateString() === startOfMonth.toDateString() &&
          range.endDate.toDateString() === new Date().toDateString()
        );
      },
      key: 'thisMonth',
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1,
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      }),
      isSelected: (range) => {
        const startOfLastMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1,
        );
        return (
          range.startDate.toDateString() === startOfLastMonth.toDateString() &&
          range.endDate.toDateString() ===
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0,
            ).toDateString()
        );
      },
      key: 'lastMonth',
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
      }),
      isSelected: (range) => {
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);
        return (
          range.startDate.toDateString() === startOfYear.toDateString() &&
          range.endDate.toDateString() ===
            new Date(new Date().getFullYear(), 11, 31).toDateString()
        );
      },
      key: 'thisYear',
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: new Date(new Date().getFullYear() - 1, 0, 1),
        endDate: new Date(new Date().getFullYear() - 1, 11, 31),
      }),
      isSelected: (range) => {
        const startOfLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
        return (
          range.startDate.toDateString() === startOfLastYear.toDateString() &&
          range.endDate.toDateString() ===
            new Date(new Date().getFullYear() - 1, 11, 31).toDateString()
        );
      },
      key: 'lastYear',
    },
  ];

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            {isMyTeam ? (
              <h4 className="attendance_overview_heading">Team Attendance</h4>
            ) : (
              <h4 className="attendance_overview_heading">My Attendance</h4>
            )}
          </div>
        </div>

        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              {isMyTeam && (
                <div
                  className={`searchNameFilterParent ${
                    isFocused ? 'searchNameFilterParentFocused' : ''
                  }`}
                  style={{ marginRight: '1vw' }}
                >
                  <FormControl variant="standard">
                    <OutlinedInput
                      onChange={handleChange}
                      value={searchTerm}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      className="searchNameFilter"
                      placeholder="Name"
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              )}
              <div className="singleInputFieldFilters">
                <FormControl variant="standard">
                  <OutlinedInput
                    className="searchNameFilter"
                    placeholder="Select Date"
                    onClick={openModal}
                    value={selectedDate || ''}
                    readOnly
                  />
                </FormControl>
              </div>

              <div
                className="remaingLeavesCounterMyLeaves"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <button
                  onClick={handleDownloadPdf}
                  className="downloadPDFButton downloadPDFButton2 bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              {sortedItems.length > 0 ? (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                    id="your-table-id"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ textAlign: 'center' }}
                          >
                            {column.sortable ? (
                              <TableSortLabel
                                active={sortBy === column.id}
                                direction={
                                  sortBy === column.id ? sortOrder : 'asc'
                                }
                                onClick={() => handleSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedItems.map((row, index) => {
                        const serialNumber = indexOfFirstItem + index + 1;
                        return (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {serialNumber}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['full_name']}
                            </TableCell>
                            {row['current_status'] === 'Present' ? (
                              <TableCell style={{ textAlign: 'center' }}>
                                {row['late_minutes']}
                              </TableCell>
                            ) : (
                              <TableCell
                                style={{ textAlign: 'center', color: 'red' }}
                              >
                                {row['current_status']}
                              </TableCell>
                            )}
                            {row['current_status'] === 'Present' ? (
                              <TableCell style={{ textAlign: 'center' }}>
                                {row['extra_minutes']}
                              </TableCell>
                            ) : (
                              <TableCell
                                style={{ textAlign: 'center', color: 'red' }}
                              >
                                -
                              </TableCell>
                            )}
                            {row['absences'] === 0 ? (
                              <TableCell style={{ textAlign: 'center' }}>
                                {row['absences']}
                              </TableCell>
                            ) : (
                              <TableCell
                                style={{ textAlign: 'center', color: 'red' }}
                              >
                                {row['absences']}
                              </TableCell>
                            )}

                            <TableCell style={{ textAlign: 'center' }}>
                              {row['check_in_type'] !== 'Absent' && (
                                <button
                                  style={{
                                    border: 'none',
                                    color: '#0066ff',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleAttendanceView(row['usersId'])
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                  >
                                    <g data-name="Group 295">
                                      <path
                                        data-name="visibility_FILL0_wght300_GRAD0_opsz24 (1)"
                                        d="M13.003 16.95a4.236 4.236 0 0 0 3.115-1.284 4.248 4.248 0 0 0 1.281-3.117 4.236 4.236 0 0 0-1.284-3.115 4.248 4.248 0 0 0-3.117-1.281 4.236 4.236 0 0 0-3.115 1.284 4.248 4.248 0 0 0-1.281 3.117 4.236 4.236 0 0 0 1.284 3.115 4.248 4.248 0 0 0 3.117 1.281zm0-1.485a2.809 2.809 0 0 1-2.063-.85 2.809 2.809 0 0 1-.85-2.063 2.809 2.809 0 0 1 .85-2.063 2.809 2.809 0 0 1 2.063-.85 2.809 2.809 0 0 1 2.063.85 2.809 2.809 0 0 1 .85 2.063 2.809 2.809 0 0 1-.85 2.063 2.809 2.809 0 0 1-2.065.85zm0 4.639a11.281 11.281 0 0 1-6.231-1.817 13.565 13.565 0 0 1-4.509-4.782 1.9 1.9 0 0 1-.2-.468 1.892 1.892 0 0 1-.062-.484 1.884 1.884 0 0 1 .062-.484 1.9 1.9 0 0 1 .2-.468 13.564 13.564 0 0 1 4.509-4.782 11.281 11.281 0 0 1 6.229-1.82 11.281 11.281 0 0 1 6.231 1.818 13.564 13.564 0 0 1 4.514 4.782 1.9 1.9 0 0 1 .2.468 1.893 1.893 0 0 1 .062.484 1.885 1.885 0 0 1-.062.484 1.9 1.9 0 0 1-.2.468 13.565 13.565 0 0 1-4.509 4.782 11.281 11.281 0 0 1-6.236 1.814zm-.002-7.552zm0 5.934a10.3 10.3 0 0 0 5.6-1.6 10.543 10.543 0 0 0 3.9-4.329 10.544 10.544 0 0 0-3.9-4.329 10.3 10.3 0 0 0-5.6-1.6 10.3 10.3 0 0 0-5.6 1.6 10.544 10.544 0 0 0-3.9 4.329 10.543 10.543 0 0 0 3.9 4.329 10.3 10.3 0 0 0 5.6 1.6z"
                                      />
                                      <path
                                        data-name="Rectangle 293"
                                        fill="none"
                                        d="M0 0h26v26H0z"
                                      />
                                    </g>
                                  </svg>
                                </button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                // If no records found
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: 'center', padding: '20px' }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {currentItems.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
        </Card>
      </div>
      <DatePickerModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="modal__popup">
          <div className="date-filter-container">
            <div
              className="date-pickers"
              style={{ display: 'flex', gap: '16px' }}
            >
              <DateRangePicker
                ranges={[selectionRange]}
                staticRanges={staticRanges}
                onChange={handleSelect}
              />
            </div>
            <div
              className="action-buttons"
              style={{ marginTop: '16px', textAlign: 'right' }}
            >
              <Button variant="contained" color="primary" onClick={handleApply}>
                Apply
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={closeModal}
                style={{ marginLeft: '8px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </DatePickerModal>
    </Layout>
  );
}
