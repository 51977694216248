import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Stack } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthService from './services/AuthService';

interface LocationState {
  email: string;
}

export default function OtpVerification() {
  const [selectedOTP, setSelectedOTP] = useState('');

  const [otpError, setOtpError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResendOtpSubmitting, setIsResendOtpSubmitting] = useState(false);

  const [timer, setTimer] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [currentTimer, setCurrentTimer] = useState(120);
  const [showCurrentTimer, setShowCurrentTimer] = useState(false);

  const [displayTime, setDisplayTime] = useState('');
  const [displayCurrentTime, setDisplayCurrentTime] = useState('');
  const [otpErrorText, setOtpErrorText] = useState('');

  const history = useHistory();
  const location = useLocation();
  const email = (location.state as LocationState)?.email ?? null;
  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (email) {
        const data = await AuthService.findOtp(email);
        if (data.error) {
          if (isMounted.current) {
            history.push(`/forgetPassword`);
            return;
          }
        }
        setTimer(data.timer);
        setCurrentTimer(data.currentTimer);

        if (data.currentTimer > 0) {
          setShowTimer(false);
          setShowCurrentTimer(true);
          setIsResendOtpSubmitting(true);
        } else {
          if (timer > 0) {
            setShowTimer(true);
            setShowCurrentTimer(false);
            setIsResendOtpSubmitting(true);
          } else {
            setShowTimer(false);
            setShowCurrentTimer(false);
            setIsResendOtpSubmitting(false);
          }
        }
      } else {
        if (isMounted.current) {
          history.push('forgetPassword');
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // Convert timer value to a human-readable format
    const convertTimerToDisplay = () => {
      let hours = Math.floor(timer / 3600);
      let minutes = Math.floor((timer % 3600) / 60);
      let seconds = timer % 60;

      if (hours > 0) {
        setDisplayTime(
          `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
            seconds < 10 ? '0' : ''
          }${seconds}`,
        );
      } else {
        setDisplayTime(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
      }
    };

    convertTimerToDisplay();

    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
        convertTimerToDisplay();
        if (currentTimer > 0) {
          setShowTimer(false);
          if (!showCurrentTimer) {
            setShowCurrentTimer(true);
          }
          setIsResendOtpSubmitting(true);
        } else {
          setShowTimer(true);
          setShowCurrentTimer(false);
          setIsResendOtpSubmitting(true);
        }
      } else {
        if (currentTimer <= 0) {
          setTimer(0);
          setShowTimer(false);
          setShowCurrentTimer(false);
          setIsResendOtpSubmitting(false);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    const convertCurrentTimerToDisplay = () => {
      let minutes = Math.floor((currentTimer % 3600) / 60);
      let seconds = currentTimer % 60;

      setDisplayCurrentTime(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
    };

    convertCurrentTimerToDisplay();

    const intervalId2 = setInterval(() => {
      if (currentTimer > 0) {
        setCurrentTimer(currentTimer - 1);
        convertCurrentTimerToDisplay();
        if (!showCurrentTimer) {
          setShowCurrentTimer(true);
        }
        setShowTimer(false);
        setIsResendOtpSubmitting(true);
      } else {
        setCurrentTimer(0);
        if (timer > 0) {
          setShowCurrentTimer(false);
          setShowTimer(true);
          setIsResendOtpSubmitting(true);
        } else {
          setShowCurrentTimer(false);
          setShowTimer(false);
          setIsResendOtpSubmitting(false);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId2);
  }, [currentTimer]);

  const forgetPassword = async () => {
    if (email === '') {
      if (isMounted.current) {
        history.push('/forgetPassword');
        return;
      }
    } else {
      setIsSubmitting(true);

      try {
        const data = {
          email: email,
        };
        await AuthService.forget(data);
        const data2 = await AuthService.findOtp(email);
        if (data2.error) {
          if (isMounted.current) {
            history.push(`/forgetPassword`);
            return;
          }
        }
        setTimer(data2.timer);
        setCurrentTimer(data2.currentTimer);

        if (data2.currentTimer > 0) {
          setShowTimer(false);
          setShowCurrentTimer(true);
          setIsResendOtpSubmitting(true);
        } else {
          if (timer > 0) {
            setShowTimer(true);
            setShowCurrentTimer(false);
            setIsResendOtpSubmitting(true);
          } else {
            setShowTimer(false);
            setShowCurrentTimer(false);
            setIsResendOtpSubmitting(false);
          }
        }
      } catch (error) {
        setIsSubmitting(false);
        setIsResendOtpSubmitting(false);
      }
    }
  };

  const handleOTPChange = (event) => {
    const { value } = event.target;
    const truncatedValue = value.slice(0, 6);
    const numericValue = truncatedValue.replace(/\D/g, ''); // Remove non-numeric characters
    setSelectedOTP(numericValue);
  };

  const onSubmit = async () => {
    if (selectedOTP === '') {
      setOtpError(true);
      setOtpErrorText('Enter OTP');
      return;
    } else if (selectedOTP.length !== 6) {
      setOtpError(true);
      setOtpErrorText('Invalid OTP');
      return;
    } else {
      setOtpError(false);
      setIsSubmitting(true);
      try {
        const data = {
          otp: selectedOTP,
          email: email,
        };
        const verify = await AuthService.verifyOTP(data);
        if (verify.data === 'failure') {
          setOtpError(true);
          setIsSubmitting(false);
          setOtpErrorText('Incorrect OTP');

          return;
        } else {
          if (isMounted.current) {
            history.push('/resetPassword', { email: email });
            setIsSubmitting(false);
          }
        }
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="Portal-Login lg:flex-row flex-col">
      <div className="Portal-Login-Image"></div>
      <div className="Portal-Login-Logo">
        <img alt="logo" src="./logo_3.png"></img>
      </div>
      <div className="Portal-Login-Form">
        <div className="Hello-Welcome-back">
          <span>OTP Verification!</span>
          <p className="forget-paragraph">
            An OTP was sent to your email. Enter it below to reset your
            Password. OTP will expire after 2 minutes.
          </p>
        </div>
        <Box
          sx={{
            width: 500,
            maxWidth: '100%',
          }}
        >
          <div>
            <div className="inputBox__container">
              <FormControl className="login-input-form" fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="Portal-Login-input"
                  label={!otpError ? 'Enter OTP' : otpErrorText}
                  onChange={handleOTPChange}
                  value={selectedOTP}
                  error={otpError}
                  inputProps={{
                    maxLength: 6,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  InputLabelProps={{
                    classes: {
                      root: !otpError
                        ? 'Portal-Login-label'
                        : 'Portal-Login-label-error',
                    },
                  }}
                  disabled={isSubmitting}
                />
              </FormControl>
              {showCurrentTimer && (
                <p className="otpTimer">Time Remaining: {displayCurrentTime}</p>
              )}
            </div>

            <div>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  className="login-button"
                  size="large"
                  variant="contained"
                  color="primary"
                  // disabled={isResendOtpSubmitting}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </FormControl>
            </div>
          </div>
          <div
            className="forget-button-div"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Stack direction="row" spacing={2}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  disabled={isResendOtpSubmitting}
                  className="Forget-Password"
                  variant="text"
                  onClick={forgetPassword}
                >
                  Resend OTP!
                </Button>
                {showTimer && (
                  <div>
                    <p className="otpTimer">
                      You may request new code in {displayTime}
                    </p>
                  </div>
                )}
              </div>
            </Stack>
          </div>
        </Box>
      </div>
    </div>
  );
}
