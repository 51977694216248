import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import InventoryRequestService from '../../services/InventoryRequestService';
import InventoryService from '../../services/InventoryService';
import ValidationModal from '../BootstrapValidationModal';

export default function NewInventory() {
  const history = useHistory();

  const [noOfItems, setNoOfItems] = useState(0);
  const [noOfItemsError, setNoOfItemsError] = useState(false);

  const [nameOfItem, setNameOfItem] = useState('');
  const [nameOfItemsError, setNameOfItemsError] = useState(false);

  const [descriptionText, setDescriptionText] = useState('');
  const [inventoryData, setInventoryData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [specificItem, setSpecificItem] = useState('');
  const [specificNameOfItemsError, setSpecificNameOfItemsError] =
    useState(false);

  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItemId, setSelectedItemId] = useState('');

  const [displayOtherItem, setDisplayOtherItem] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = React.useState(0);

  const [descriptionError, setDescriptionError] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    if (name === 'no_of_items') {
      if (value !== '') {
        if (parseInt(value) !== 0) {
          setNoOfItemsError(false);
        }
        setNoOfItems(parseInt(value));
      }
    }
    if (name === 'specific_item') {
      setSpecificItem(value);
    }
    if (name === 'name_of_item') {
      setNameOfItem(value);
      if (value === '') {
        setFilterData(inventoryData);
      } else {
        const filtered = inventoryData.filter((data) =>
          data.name.toLowerCase().includes(value.toLowerCase()),
        );
        setFilterData(filtered);
      }
    }
  };

  useEffect(() => {
    let intervalId;
    let isMounted = true;

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/inventory`);
              }
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isSubmittingModalOpen]);

  useEffect(() => {
    const fetchInventoryData = async () => {
      const inventory = await InventoryService.findAll();

      setInventoryData(inventory['allInventory']);
      setFilterData(inventory['allInventory']);
    };

    fetchInventoryData();
  }, []);

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
    if (value !== '') {
      setDescriptionError(false);
    }
  };

  const handleInputChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    event.target.value = numericValue; // Update the input field value
  };

  const onSubmit = async () => {
    if (displayOtherItem) {
      if (specificItem === '') {
        setSpecificNameOfItemsError(true);
        setIsModalOpen(true);
        setIsDropdownOpen(false);
        return;
      } else {
        setSpecificNameOfItemsError(false);
      }
    } else {
      if (selectedItem === '') {
        setNameOfItemsError(true);
        setIsModalOpen(true);
        setIsDropdownOpen(false);
        return;
      } else {
        setNameOfItemsError(false);
      }
    }

    if (noOfItems === 0) {
      setNoOfItemsError(true);
      setIsModalOpen(true);
      setIsDropdownOpen(false);

      return;
    } else {
      setNoOfItemsError(false);
    }

    if (descriptionText === '') {
      setDescriptionError(true);
      setIsModalOpen(true);
      setIsDropdownOpen(false);
      return;
    } else {
      setDescriptionError(false);
    }

    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);
    if (displayOtherItem) {
      const matchingItems = inventoryData.filter(
        (item) =>
          item.name.toLowerCase().replace(/\s/g, '') ===
          specificItem.toLowerCase().replace(/\s/g, ''),
      );

      if (matchingItems.length > 0) {
        alert(`${specificItem}  is already in inventory`);
        return;
      } else {
        try {
          setIsSubmitting(true);

          const data = {
            existing_item: 'null',
            new_item: specificItem,
            description: descriptionText,
            item_required: noOfItems,
            usersId: parsedUser.id,
          };
          await InventoryRequestService.save(data);
          setIsSubmittingModalOpen(true);
          setIsDropdownOpen(false);
        } catch (error) {
          setIsSubmitting(false);
        }
      }
    } else {
      try {
        const data = {
          existing_item: parseInt(selectedItemId),
          new_item: specificItem,
          description: descriptionText,
          item_required: noOfItems,
          usersId: parsedUser.id,
        };

        await InventoryRequestService.save(data);
        setIsSubmittingModalOpen(true);
        setIsDropdownOpen(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  const handleSelectItem = async (id, name) => {
    if (id) {
      setDisplayOtherItem(false);
    } else {
      setDisplayOtherItem(true);
    }
    setSelectedItemId(id);
    setNameOfItemsError(false);
    setSelectedItem(name);
    setIsDropdownOpen((prev) => !prev);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  useEffect(() => {
    let intervalId;

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              setIsSubmittingModalOpen(false);
              history.push('/inventory');
            }, 500);
            return 100;
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30;
    const totalTime = 1000;
    const steps = totalTime / intervalDuration;

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => clearInterval(intervalId);
  }, [isSubmittingModalOpen]);

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Inventory Request</h4>

        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div className="displayFlexAddForm">
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Name Of Item
                    </InputLabel>
                    <FormControl
                      className={`SpecialDropdown ${
                        nameOfItemsError ? 'leaveDurationInput-error' : ''
                      }`}
                      variant="standard"
                    >
                      <OutlinedInput
                        onClick={toggleDropdown}
                        value={selectedItem}
                        placeholder="Select Item"
                        readOnly
                        endAdornment={
                          <InputAdornment position="end">
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        }
                      />
                      {isDropdownOpen && (
                        <div className="SpecialDropdownList">
                          <FormControl
                            className="SpecialDropdownListFormControl"
                            variant="standard"
                          >
                            <OutlinedInput
                              name="name_of_item"
                              onChange={handleChange}
                              value={nameOfItem}
                              placeholder="Search An Item"
                              endAdornment={
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <div className="dropdown-content">
                            {filterData.map((option, index) => (
                              <button
                                key={index}
                                onClick={() =>
                                  handleSelectItem(option.id, option.name)
                                }
                              >
                                {' '}
                                {option.name}
                              </button>
                            ))}
                            <button
                              onClick={() => handleSelectItem(null, 'other')}
                            >
                              Other
                            </button>
                          </div>
                        </div>
                      )}
                    </FormControl>
                  </div>
                </div>
                {displayOtherItem ? (
                  <div className="singleInputField singleInputFieldForm displayFlexAddFormSecond">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Specify Item
                      </InputLabel>
                      <FormControl
                        className={`${
                          specificNameOfItemsError
                            ? 'leaveDurationInput-error'
                            : ''
                        }`}
                        variant="standard"
                      >
                        <OutlinedInput
                          name="specific_item"
                          onChange={handleChange}
                          value={specificItem}
                          placeholder="Write Item Name"
                        />
                      </FormControl>
                    </div>
                  </div>
                ) : (
                  <div className="singleInputField singleInputFieldForm displayFlexAddFormSecond">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        No Of Items
                      </InputLabel>
                      <FormControl
                        className={`${
                          noOfItemsError ? 'leaveDurationInput-error' : ''
                        }`}
                        variant="standard"
                      >
                        <OutlinedInput
                          name="no_of_items"
                          onChange={handleChange}
                          value={noOfItems}
                          onInput={handleInputChange}
                          placeholder="Write Quantity"
                        />
                      </FormControl>
                    </div>
                  </div>
                )}
              </div>
              {displayOtherItem && (
                <div className="displayFlexAddForm">
                  <div className="singleInputField singleInputFieldForm">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        No Of Items
                      </InputLabel>
                      <FormControl
                        className={`${
                          noOfItemsError ? 'leaveDurationInput-error' : ''
                        }`}
                        variant="standard"
                      >
                        <OutlinedInput
                          name="no_of_items"
                          onChange={handleChange}
                          value={noOfItems}
                          onInput={handleInputChange}
                          placeholder="Write Quantity"
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="singleInputField"></div>
                </div>
              )}
              <div>
                <div
                  className="displayFlexAddForm"
                  style={{
                    margin: '1vw 0',
                  }}
                >
                  <div className="singleInputFieldTextArea">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Description
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput ${
                          descriptionError ? 'leaveDurationInput-error' : ''
                        }`}
                        onChange={handleChangeDescription}
                        value={descriptionText}
                        placeholder="Enter text here..."
                        rows={6}
                        cols={50}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField"></div>
                <div className="singleInputField">
                  <div className="formButton">
                    <button
                      type="button"
                      // disabled={isSubmitting}
                      className="formSubmitButton"
                      onClick={onSubmit}
                    >
                      {/* {isSubmitting ? 'Submitting...' : 'Submit'} */}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <ValidationModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ValidationModal">
                <span>Please Enter All Fields To Continue!</span>
                <div className="CloseValidationModalButton">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="CloseValidationModal"
                    onClick={closeModal}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ValidationModal>

        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
