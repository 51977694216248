import Holiday from '../models/holidays/Holiday';
import UpdateHolidayRequest from '../models/holidays/UpdateHolidayRequest';
import apiService from './ApiService';

export class HolidayService {
  async update(
    id: number,
    updateHolidayRequest: UpdateHolidayRequest,
  ): Promise<void> {
    await apiService.put(`/api/holidays/update/${id}`, updateHolidayRequest);
  }

  async findAll(): Promise<Holiday[]> {
    return (await apiService.get<Holiday[]>('/api/holidays', {})).data;
  }

  async findAllHolidays(): Promise<Holiday[]> {
    return (
      await apiService.get<Holiday[]>('/api/holidays/findAllHolidays', {})
    ).data;
  }

  async filter(data) {
    const data2 = await apiService.post('/api/holidays/filter', data);
    return data2.data;
  }

  async save(data): Promise<void> {
    await apiService.post('/api/holidays', data);
  }

  async findOne(id: string): Promise<Holiday> {
    return (await apiService.get<Holiday>(`/api/holidays/${id}`)).data;
  }

  async delete(id: string): Promise<void> {
    await apiService.put(`/api/holidays/delete/${id}`);
  }
}

const holidayService = new HolidayService();
export { holidayService as default };
