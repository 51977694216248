import { useEffect, useRef, useState } from 'react';

import Navbar from './Navbar';
import Sidebar from './Sidebar';

export default function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(true);
  const [isResponsive, setIsResponsive] = useState(false);
  const sideNavRef = useRef(null);

  useEffect(() => {
    const checkResponsive = () => {
      setIsResponsive(window.innerWidth <= 1024); // You can adjust the threshold as needed
    };

    checkResponsive();

    window.addEventListener('resize', checkResponsive);

    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);

  useEffect(() => {
    if (isResponsive) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    if (!isResponsive) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isResponsive]);

  function handleClickOutside(event) {
    const navbarCenterLogo = document.querySelector('.logoOnNavbarResponsive');

    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      setShowSidebar(false);
      navbarCenterLogo.classList.remove('logoOnNavbarResponsiveHide');
    }
  }

  const sidebarSvgCode = `
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg>
  `;

  const showHideLogoOnNavbar = () => {
    setShowSidebar(!showSidebar);
    const navbarCenterLogo = document.querySelector('.logoOnNavbarResponsive');
    if (navbarCenterLogo) {
      navbarCenterLogo.classList.add('logoOnNavbarResponsiveHide');
    }
  };

  return (
    <>
      <div className="parentDiv">
        <div className="sidebarParent" ref={sideNavRef}>
          {showSidebar && isResponsive && (
            <Sidebar className={showSidebar ? 'show' : ''} />
          )}
          {showSidebar && !isResponsive && (
            <Sidebar className={showSidebar ? 'show' : ''} />
          )}
        </div>

        <div className="main__body">
          <Navbar className="main__navbar__right" />
          <div className="main__body__content pt-10 lg:w-10/12 sm:w-full mx-auto px-5 sm:px-10 py-5 overflow-x-hidden">
            {children}
          </div>
          {!showSidebar && (
            <button
              className={`displaySidebarButton absolute top-5 left-5 border shadow-md bg-white p-3 rounded-full transition-all focus:outline-none lg:hidden ${
                showSidebar ? 'right-5' : 'right-5'
              }`}
              onClick={() => showHideLogoOnNavbar()}
            >
              {!showSidebar && (
                <div>
                  <div
                    className="main__navbar__icon"
                    dangerouslySetInnerHTML={{ __html: sidebarSvgCode }}
                  />
                </div>
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
