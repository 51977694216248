import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import HolidayService from '../../services/HolidayService';

export default function ViewHoliday() {
  const { id } = useParams() as { id: string };

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [title, setTitle] = useState('');
  const [descriptionText, setDescriptionText] = useState('');

  useEffect(() => {
    const fetchUserData = async (id) => {
      const holiday = await HolidayService.findOne(id);
      setTitle(holiday.name);
      setDescriptionText(holiday.description);
      setSelectedFromDate(holiday.from_date);
      setSelectedToDate(holiday.to_date);
    };
    fetchUserData(id);
  }, [id]);

  return (
    <Layout>
      <div className="leavesIndex">
        <div
          className="leavesIndex__header leavesIndex__body_view_screen"
          style={{ paddingBottom: 0 }}
        >
          <div>
            <h4 className="attendance_overview_heading">Holiday Details</h4>
          </div>
        </div>

        <div className="leavesIndex__body leavesIndex__body_view_screen">
          <div className="leavesIndex__body_padding leavesIndex__body_padding_view_screen">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Title
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {title}
                    </InputLabel>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      From Date
                    </InputLabel>
                    {selectedFromDate}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      To Date
                    </InputLabel>
                    {selectedToDate}
                  </div>
                </div>
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div className="singleInputFieldTextArea">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Description
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                        value={descriptionText}
                        placeholder="Enter text here..."
                        rows={12}
                        cols={50}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
}
