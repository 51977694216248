import InventoryRequest from '../models/inventoryRequest/InventoryRequest';
import apiService from './ApiService';

export class InventoryRequestService {
  async update(id: number, data) {
    await apiService.put(`/api/inventory-request/update/${id}`, data);
  }

  async findAll(id: string): Promise<InventoryRequest[]> {
    return (
      await apiService.get<InventoryRequest[]>(
        `/api/inventory-request/findAll/${id}`,
        {},
      )
    ).data;
  }
  async save(data): Promise<void> {
    await apiService.post('/api/inventory-request', data);
  }

  async findOne(id: string) {
    return (await apiService.get(`/api/inventory-request/${id}`)).data;
  }
  async delete(id: string): Promise<void> {
    await apiService.put(`/api/inventory-request/delete/${id}`);
  }

  async filter(data) {
    const data2 = await apiService.post('/api/inventory-request/filter', data);
    return data2.data;
  }
}

const inventoryRequestService = new InventoryRequestService();
export { inventoryRequestService as default };
