import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import authService from '../services/AuthService';

interface LocationState {
  email: string;
}

export default function ResetPassword() {
  const [selectedPassword, setSelectedPassword] = useState('');
  const [selectedCPassword, setSelectedCPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [cPasswordError, setCPasswordError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validCredentials, setValidCredentials] = useState(true);
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const history = useHistory();
  const location = useLocation();
  const email = (location.state as LocationState)?.email ?? null;
  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (email) {
      } else {
        if (isMounted.current) {
          history.push('forgetPassword');
        }
      }
    };

    fetchUserData();
  }, []);

  const togglePassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const toggleCPassword = () => {
    if (showCPassword) {
      setShowCPassword(false);
    } else {
      setShowCPassword(true);
    }
  };

  const eyeSvgCode = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 36 36"
    >
      <path data-name="Path 54" d="M0 0h36v36H0z" fill="none" />
      <path
        data-name="Path 55"
        d="M17.5 7a14.655 14.655 0 0 1 13.23 8.25 14.733 14.733 0 0 1-26.46 0A14.655 14.655 0 0 1 17.5 7m0-3A17.74 17.74 0 0 0 1 15.25a17.725 17.725 0 0 0 33 0A17.74 17.74 0 0 0 17.5 4zm0 7.5a3.75 3.75 0 1 1-3.75 3.75 3.751 3.751 0 0 1 3.75-3.75m0-3a6.75 6.75 0 1 0 6.75 6.75A6.76 6.76 0 0 0 17.5 8.5z"
        transform="translate(.5 2)"
        fill="#15267d"
      />
    </svg>
  `;

  const eyeHideSvgCode = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 36 36"
  >
    <path
      data-name="Path 56"
      d="M0 0h36v36H0zm0 0h36v36H0zm0 0h36v36H0zm0 0h36v36H0z"
      fill="none"
    />
    <path
      data-name="Path 57"
      d="M18 8.225a15.049 15.049 0 0 1 13.631 8.946 15.617 15.617 0 0 1-3.725 5.075l2.179 2.294A19.228 19.228 0 0 0 35 17.171a18.323 18.323 0 0 0-17-12.2 17.479 17.479 0 0 0-5.625.927l2.55 2.684A14.875 14.875 0 0 1 18 8.225zm-1.654 1.854 3.2 3.367a3.968 3.968 0 0 1 1.978 2.082l3.2 3.367a7.989 7.989 0 0 0 .216-1.74A7.118 7.118 0 0 0 18 9.851a6.251 6.251 0 0 0-1.654.228zM2.561 4.76 6.7 9.119A19.045 19.045 0 0 0 1 17.171a18.323 18.323 0 0 0 17 12.2 17.336 17.336 0 0 0 6.676-1.334l5.286 5.563 2.179-2.294L4.74 2.45zm11.591 12.2 4.034 4.246a.741.741 0 0 1-.185.033 3.97 3.97 0 0 1-3.864-4.067c-.001-.082.015-.131.015-.212zM8.9 11.429l2.7 2.847a7.824 7.824 0 0 0-.556 2.9A7.155 7.155 0 0 0 18 24.491a6.677 6.677 0 0 0 2.735-.586L22.25 25.5a15.372 15.372 0 0 1-4.25.618 15.049 15.049 0 0 1-13.631-8.947A15.946 15.946 0 0 1 8.9 11.429z"
      fill="#15267d"
    />
  </svg>`;

  const onSubmit = async () => {
    if (selectedPassword === '' && selectedCPassword === '') {
      setCPasswordError(true);
      setPasswordError(true);
      setPasswordErrorText('Please Enter All Fields');
      setValidCredentials(false);
      return;
    } else {
      setCPasswordError(false);
      setPasswordError(false);
      setPasswordErrorText('');
      setValidCredentials(true);
    }
    if (selectedPassword === '' && selectedCPassword !== '') {
      setPasswordError(true);
      setPasswordErrorText('Please Enter All Fields');
      setValidCredentials(false);
      return;
    } else {
      setPasswordError(false);
      setValidCredentials(true);
      setPasswordErrorText('');
    }

    if (selectedCPassword === '' && selectedPassword !== '') {
      setCPasswordError(true);
      setValidCredentials(false);
      setPasswordErrorText('Please Enter All Fields');
      return;
    } else {
      setValidCredentials(true);
      setPasswordErrorText('');
      setCPasswordError(false);
    }

    if (
      selectedPassword === selectedCPassword &&
      selectedPassword !== '' &&
      selectedCPassword !== ''
    ) {
      setPasswordErrorText('');
      setValidCredentials(true);
      setIsSubmitting(true);
      try {
        const loginRequest = {
          email: email,
          password: selectedPassword,
          cPassword: selectedCPassword,
        };

        try {
          await authService.resetPassword(loginRequest);
          if (isMounted.current) {
            history.push('');
          }
          setIsSubmitting(false);
        } catch (error) {}
      } catch (error) {
        setIsSubmitting(false);
        setPasswordErrorText('Some thing went wrong try again later!');
        setValidCredentials(false);
      }
    } else {
      setCPasswordError(true);
      setPasswordErrorText("Password and Confirm Password doesn't match");
      setValidCredentials(false);
    }
  };

  const handlePasswordChange = (event) => {
    setSelectedPassword(event.target.value);
  };

  const handleCPasswordChange = (event) => {
    setSelectedCPassword(event.target.value);
  };

  return (
    <div className="Portal-Login">
      <div className="Portal-Login-Image"></div>
      <div className="Portal-Login-Logo">
        <img alt="logo" src="./logo_3.png"></img>
      </div>
      <div className="Portal-Login-Form">
        <div className="Hello-Welcome-back">
          <span>Reset Password!</span>
          <div>
            <p className="forget-paragraph">
              Enter your new Password to change it.
            </p>
          </div>
        </div>

        <Box
          sx={{
            width: 500,
            maxWidth: '100%',
          }}
        >
          <div className="flex flex-col flex-start">
            <div className="flex flex-col flex-start">
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="fullWidth"
                  type={showPassword ? 'text' : 'password'}
                  className={`Portal-Login-input ${
                    passwordError ? 'Portal-Login-input-error' : ''
                  }`}
                  label="Enter Password"
                  error={passwordError}
                  required
                  onChange={handlePasswordChange}
                  value={selectedPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: eyeSvgCode }}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: eyeHideSvgCode,
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={isSubmitting}
                />
              </FormControl>
            </div>
            <div className="flex flex-col flex-start">
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="fullWidth"
                  type={showCPassword ? 'text' : 'password'}
                  className={`Portal-Login-input ${
                    cPasswordError ? 'Portal-Login-input-error' : ''
                  }`}
                  label="Confirm Password"
                  error={cPasswordError}
                  required
                  onChange={handleCPasswordChange}
                  value={selectedCPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleCPassword}
                          edge="end"
                        >
                          {showCPassword ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: eyeSvgCode }}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: eyeHideSvgCode,
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={isSubmitting}
                />
              </FormControl>
              {!validCredentials && (
                <p className="error-message-invalid">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                      transform="translate(-80 880)"
                      fill="#f00"
                    />
                  </svg>
                  {passwordErrorText}
                </p>
              )}
            </div>
            <div>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  className="login-button"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onSubmit}
                >
                  Reset Password
                </Button>
              </FormControl>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
