import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface LocationState {
  email: string;
}

export default function ResetPasswordSuccess() {
  const history = useHistory();
  const location = useLocation();
  const email = (location.state as LocationState)?.email ?? null;
  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (email) {
      } else {
        if (isMounted.current) {
          history.push('forgetPassword');
        }
      }
    };

    fetchUserData();
  }, []);

  const onSubmit = async () => {
    if (isMounted.current) {
      history.push('/login');
    }
  };

  return (
    <div className="Portal-Login Portal-Login-password-success lg:flex-row flex-col">
      <div className="Portal-Login-Image"></div>
      <div className="Portal-Login-Logo">
        <img alt="logo" src="./logo_3.png"></img>
      </div>
      <div className="Portal-Login-Form PasswordChangeSuccessParent">
        <div className="Hello-Welcome-back">
          <div className="PasswordChangeSuccess">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 152 152"
              className="svgIcon"
            >
              <g data-name="Group 122" transform="translate(-1419 -264)">
                <circle
                  data-name="Ellipse 27"
                  cx="76"
                  cy="76"
                  r="76"
                  transform="translate(1419 264)"
                  fill="#15267d"
                />
                <path
                  data-name="Path 159"
                  d="M87.226 8.406c-1.774-1.774-4.731-1.774-7.1 0L34.592 53.943 13.3 32.653c-1.774-1.774-4.731-1.774-7.1 0-1.774 1.774-1.774 4.731 0 7.1l24.844 24.835a8.406 8.406 0 0 0 3.548 1.183A5.112 5.112 0 0 0 38.141 64l49.085-49.089a3.869 3.869 0 0 0 0-6.505z"
                  transform="translate(1447.625 303.425)"
                  fill="#fff"
                  style={{ stroke: '#fff', strokeWidth: '2px' }}
                />
              </g>
            </svg>
          </div>
          <span>Password Changed!</span>
          <div className="PasswordChangeSuccessParagraph">
            <p className="forget-paragraph">
              Your Password has been changed. Now Login to access your portal.
            </p>
          </div>
          <div className="inputBox__container">
            <FormControl fullWidth sx={{ m: 1 }}>
              <Button
                className="login-button"
                size="large"
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Login
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}
