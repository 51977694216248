import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import RejectionModal from '../../pages/RejectionModal';
import authService from '../../services/AuthService';
import RolePermissionService from '../../services/RolePermissionService';
import DropdownItemLeave from '../leave/DropdownItemLeave';
import SidebarItemWithDropdownLeave from '../leave/SidebarItemWithDropdownLeave';
import DropdownItemRequests from '../requests/DropdownItemRequests';
import SidebarItemWithDropdownRequests from '../requests/SidebarItemWithDropdownRequests';
import SidebarItem from './SidebarItem';

interface SidebarProps {
  className: string;
}

export default function Sidebar({ className }: SidebarProps) {
  const history = useHistory();

  const { authenticatedUser, setAuthenticatedUser } = useAuth();
  const [dropdownOpenLeave, setDropdownOpenLeave] = useState(false);
  const [dropdownOpenRequests, setDropdownOpenRequests] = useState(false);
  const [rolePermission, setRolePermission] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userRoleId, setUserRoleId] = useState(0);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const roleId = parsedUser.roleId;
      setUserRoleId(roleId);
      fetchPermissionsData(roleId);
    }
  }, []);

  const fetchPermissionsData = async (id) => {
    const rolePermission = await RolePermissionService.findAll(id);
    const allPermissionOfRole = rolePermission['rolePermission'];

    const userListPermissions = allPermissionOfRole.filter(
      (item) =>
        item.permission_name === 'hr' ||
        item.permission_name === 'holidays' ||
        item.permission_name === 'announcements' ||
        item.permission_name === 'accounts',
    );
    setRolePermission(userListPermissions);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdownLeave = () => {
    setDropdownOpenLeave(!dropdownOpenLeave);
  };

  const toggleDropdownRequests = () => {
    setDropdownOpenRequests(!dropdownOpenRequests);
  };

  const handleLogout = async () => {
    await authService.logout();
    setAuthenticatedUser(null);
    history.push('/login');
  };

  const profileSvgCode = `
  <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon"  viewBox="0 0 36 36">
    <defs>
        <clipPath id="7f3agx481a">
            <path data-name="Rectangle 16" style="fill:#fff" d="M0 0h36v36H0z"/>
        </clipPath>
    </defs>
    <g data-name="Group 116">
        <g data-name="Group 3">
            <g data-name="Group 2" style="clip-path:url(#7f3agx481a)">
                <path data-name="Path 1" d="M23.636 19.313a9.565 9.565 0 1 0-11.27 0 14.455 14.455 0 0 0-8.8 13.268 1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5 11.434 11.434 0 0 1 22.867.014 1.5 1.5 0 1 0 3 0 14.456 14.456 0 0 0-8.8-13.287M11.435 11.6A6.565 6.565 0 1 1 18 18.166a6.572 6.572 0 0 1-6.565-6.566" style="fill:#fff"/>
            </g>
        </g>
        <path data-name="Rectangle 153" style="fill:none" d="M0 0h36v36H0z"/>
    </g>
  </svg>
  `;

  const dashboardSvgCode = `
  <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 36 36">
    <g data-name="Group 115">
        <path fill=#fff  d="M183.2-765.814h7.139v-10.749a1.865 1.865 0 0 1 .554-1.374 1.866 1.866 0 0 1 1.374-.554h7.467a1.866 1.866 0 0 1 1.374.554 1.865 1.865 0 0 1 .554 1.374v10.749h7.138v-18.872a.619.619 0 0 0-.072-.3.8.8 0 0 0-.195-.236l-12.144-9.128a.573.573 0 0 0-.39-.144.573.573 0 0 0-.39.144l-12.144 9.128a.8.8 0 0 0-.195.236.619.619 0 0 0-.072.3zm-3.2 0v-18.872a3.828 3.828 0 0 1 .41-1.735 3.7 3.7 0 0 1 1.133-1.35l12.144-9.149a3.708 3.708 0 0 1 2.311-.771 3.735 3.735 0 0 1 2.317.771l12.144 9.149a3.7 3.7 0 0 1 1.133 1.35 3.829 3.829 0 0 1 .41 1.735v18.872a3.078 3.078 0 0 1-.946 2.254 3.077 3.077 0 0 1-2.254.946h-8.41a1.866 1.866 0 0 1-1.374-.554 1.866 1.866 0 0 1-.554-1.374v-10.749h-4.923v10.749a1.866 1.866 0 0 1-.554 1.374 1.866 1.866 0 0 1-1.374.554H183.2a3.077 3.077 0 0 1-2.254-.946 3.078 3.078 0 0 1-.946-2.254zm16-14.359z" transform="translate(-178.001 797.691)"/>
        <path data-name="Rectangle 151" fill=none d="M0 0h36v36H0z"/>
    </g>
  </svg>
  `;

  const attendanceSvgCode = `
  <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 36 36">
    <defs>
        <clipPath id="glxvpop2na">
            <path data-name="Rectangle 23" style="fill:none" d="M0 0h36v36H0z"/>
        </clipPath>
    </defs>
    <g data-name="Group 117">
        <g data-name="Group 5">
            <g data-name="Group 4" style="clip-path:url(#glxvpop2na)">
                <path data-name="Path 2" d="M32.556 34.01H3.444a1.5 1.5 0 0 1-1.5-1.5V14.09a1.5 1.5 0 0 1 1.5-1.5h29.112a1.5 1.5 0 0 1 1.5 1.5v18.42a1.5 1.5 0 0 1-1.5 1.5m-27.612-3h26.112V15.59H4.944z" style="fill:#fff"/>
                <path data-name="Path 3" d="M32.556 15.59a1.5 1.5 0 0 1-1.5-1.5V7.744H4.944v6.346a1.5 1.5 0 1 1-3 0V6.244a1.5 1.5 0 0 1 1.5-1.5h29.112a1.5 1.5 0 0 1 1.5 1.5v7.846a1.5 1.5 0 0 1-1.5 1.5" style="fill:#fff"/>
                <path data-name="Line 3" transform="translate(10.383 3.49)" style="fill:#fff" d="M0 0v4.806"/>
                <path data-name="Path 4" d="M10.382 9.8a1.5 1.5 0 0 1-1.5-1.5V3.489a1.5 1.5 0 1 1 3 0V8.3a1.5 1.5 0 0 1-1.5 1.5" style="fill:#fff"/>
                <path data-name="Line 4" transform="translate(18.097 3.49)" style="fill:#fff" d="M0 0v4.806"/>
                <path data-name="Path 5" d="M18.1 9.8a1.5 1.5 0 0 1-1.5-1.5V3.489a1.5 1.5 0 1 1 3 0V8.3a1.5 1.5 0 0 1-1.5 1.5" style="fill:#fff"/>
                <path data-name="Line 5" transform="translate(25.617 3.49)" style="fill:#fff" d="M0 0v4.806"/>
                <path data-name="Path 6" d="M25.618 9.8a1.5 1.5 0 0 1-1.5-1.5V3.489a1.5 1.5 0 1 1 3 0V8.3a1.5 1.5 0 0 1-1.5 1.5" style="fill:#fff"/>
                <path data-name="Rectangle 17" transform="translate(8.883 18.69)" style="fill:#fff" d="M0 0h3v3H0z"/>
                <path data-name="Rectangle 18" transform="translate(16.5 18.69)" style="fill:#fff" d="M0 0h3v3H0z"/>
                <path data-name="Rectangle 19" transform="translate(24.117 18.69)" style="fill:#fff" d="M0 0h3v3H0z"/>
                <path data-name="Rectangle 20" transform="translate(8.883 24.857)" style="fill:#fff" d="M0 0h3v3H0z"/>
                <path data-name="Rectangle 21" transform="translate(16.5 24.857)" style="fill:#fff" d="M0 0h3v3H0z"/>
                <path data-name="Rectangle 22" transform="translate(24.117 24.857)" style="fill:#fff" d="M0 0h3v3H0z"/>
            </g>
        </g>
        <path data-name="Rectangle 154" style="fill:none" d="M0 0h36v36H0z"/>
    </g>
  </svg>
  `;

  const leavesSvgCode = `
    <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 36 36">
      <defs>
          <clipPath id="xzu5vej27a">
              <path data-name="Rectangle 24" style="fill:#fff" d="M0 0h36v36H0z"/>
          </clipPath>
      </defs>
      <g data-name="Group 119">
          <g data-name="Group 7">
              <g data-name="Group 6" style="clip-path:url(#xzu5vej27a)">
                  <path data-name="Path 7" d="M32.556 34.021H3.444a1.51 1.51 0 0 1-1.5-1.519V5.907a1.51 1.51 0 0 1 1.5-1.519h29.112a1.51 1.51 0 0 1 1.5 1.519V32.5a1.51 1.51 0 0 1-1.5 1.519M4.944 30.983h26.111V7.426H4.944z" style="fill:#fff"/>
                  <path data-name="Line 6" d="M10.383 3.471v4.712"/>
                  <path data-name="Path 8" d="M10.383 9.653a1.486 1.486 0 0 1-1.5-1.471V3.471a1.5 1.5 0 0 1 3 0v4.711a1.486 1.486 0 0 1-1.5 1.471" style="fill:#fff"/>
                  <path data-name="Line 7" d="M18.097 3.471v4.712"/>
                  <path data-name="Path 9" d="M18.1 9.653a1.486 1.486 0 0 1-1.5-1.471V3.471a1.5 1.5 0 0 1 3 0v4.711a1.486 1.486 0 0 1-1.5 1.471" style="fill:#fff"/>
                  <path data-name="Line 8" d="M25.617 3.471v4.712"/>
                  <path data-name="Path 10" d="M25.617 9.653a1.486 1.486 0 0 1-1.5-1.471V3.471a1.5 1.5 0 0 1 3 0v4.711a1.486 1.486 0 0 1-1.5 1.471" style="fill:#fff"/>
                  <path data-name="Path 11" d="M18.1 28.994a8.588 8.588 0 1 1 8.588-8.588 8.6 8.6 0 0 1-8.588 8.588m0-16.237a7.649 7.649 0 1 0 7.649 7.649 7.659 7.659 0 0 0-7.649-7.649" style="fill:#fff"/>
                  <path data-name="Path 12" d="M18.1 29.694a9.288 9.288 0 1 1 9.288-9.288 9.3 9.3 0 0 1-9.288 9.288m0-16.237a6.949 6.949 0 1 0 6.949 6.949 6.956 6.956 0 0 0-6.949-6.949" style="fill:#fff"/>
                  <path data-name="Path 13" d="M20.533 24.234h-4.875v-1.875h1.16v-3.685a3.593 3.593 0 0 1-.867.082 1.983 1.983 0 0 1-.293-.018v-1.8c.047 0 .1.006.146.006a3.4 3.4 0 0 0 1.424-.3 2.262 2.262 0 0 0 .955-.721h1.225v6.439h1.125z" style="fill:#fff"/>
              </g>
          </g>
          <path data-name="Rectangle 152" style="fill:none" d="M0 0h36v36H0z"/>
      </g>
    </svg>
  `;

  const requestSvgCode = `
    <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 36 36">
      <defs>
          <clipPath id="lzl9rq94pa">
              <path data-name="Rectangle 69" style="fill:#fff" d="M0 0h31.738v31.767H0z"/>
          </clipPath>
      </defs>
      <g data-name="Group 120">
          <g data-name="Group 39">
              <g data-name="Group 36">
                  <g data-name="Group 35" style="clip-path:url(#lzl9rq94pa)" transform="translate(2 3)">
                      <path data-name="Path 47" d="M6.778 29.116H1.3a1.3 1.3 0 0 1-1.3-1.3V8.421a1.3 1.3 0 0 1 1.3-1.3h10.917a1.3 1.3 0 1 1 0 2.6H2.6v16.8h4.178a1.3 1.3 0 1 1 0 2.6" style="fill:#fff"/>
                      <path data-name="Path 48" d="M23.239 17h-2.6a8.5 8.5 0 0 1 0-17h2.6a8.5 8.5 0 0 1 0 17m-2.6-15a6.5 6.5 0 0 0 0 13h2.6a6.5 6.5 0 0 0 0-13z" style="fill:#fff"/>
                      <path data-name="Path 49" d="M9.5 31.767a1.3 1.3 0 0 1-.907-.369l-2.72-2.651a1.3 1.3 0 0 1 1.815-1.862L9.5 28.652l1.812-1.766a1.3 1.3 0 0 1 .908-.369h11.19v-.782a1.3 1.3 0 1 1 2.6 0v2.081a1.3 1.3 0 0 1-1.3 1.3H12.747L10.4 31.4a1.3 1.3 0 0 1-.907.369" style="fill:#fff"/>
                      <path data-name="Path 50" d="M24.708 27.553a1.3 1.3 0 0 1-1.3-1.3V17.1a1.3 1.3 0 0 1 2.6 0v9.151a1.3 1.3 0 0 1-1.3 1.3" style="fill:#fff"/>
                  </g>
              </g>
              <text data-name="?" transform="translate(21.786 15.55)" style="font-size:12px;font-family:Roundkey-Medium,Roundkey;font-weight:500;fill:#fff"><tspan x="0" y="0">?</tspan></text>
              <g data-name="Group 38">
                  <g data-name="Group 37" style="clip-path:url(#lzl9rq94pa)" transform="translate(2 3)">
                      <path data-name="Path 51" d="M20.514 24.225H5.759a1 1 0 0 1 0-2h14.755a1 1 0 0 1 0 2" style="fill:#fff"/>
                      <path data-name="Path 52" d="M20.514 20H5.759a1 1 0 0 1 0-2h14.755a1 1 0 1 1 0 2" style="fill:#fff"/>
                      <path data-name="Path 53" d="M11.208 15.776H5.759a1 1 0 0 1 0-2h5.449a1 1 0 1 1 0 2" style="fill:#fff"/>
                  </g>
              </g>
          </g>
          <path data-name="Rectangle 152" style="fill:none" d="M0 0h36v36H0z"/>
      </g>
  </svg>
  `;

  const logoutSvgCode = `
  <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 36 36">
    <g data-name="Group 147">
        <path d="M134.222-824v-14.222a1.72 1.72 0 0 1 .511-1.267A1.72 1.72 0 0 1 136-840a1.72 1.72 0 0 1 1.267.511 1.72 1.72 0 0 1 .511 1.267V-824a1.72 1.72 0 0 1-.511 1.267 1.72 1.72 0 0 1-1.267.511 1.72 1.72 0 0 1-1.267-.511 1.72 1.72 0 0 1-.511-1.267zM136-808a15.372 15.372 0 0 1-6.2-1.267 16.343 16.343 0 0 1-5.089-3.444 16.344 16.344 0 0 1-3.444-5.089A15.37 15.37 0 0 1 120-824a15.659 15.659 0 0 1 1.133-5.889 16.432 16.432 0 0 1 3.178-5.044 1.614 1.614 0 0 1 1.244-.533 1.756 1.756 0 0 1 1.289.533 1.519 1.519 0 0 1 .444 1.222 2.188 2.188 0 0 1-.533 1.311 12.616 12.616 0 0 0-2.356 3.844 12.376 12.376 0 0 0-.844 4.556 11.99 11.99 0 0 0 3.644 8.8 11.99 11.99 0 0 0 8.8 3.645 11.969 11.969 0 0 0 8.822-3.645 12.027 12.027 0 0 0 3.622-8.8 12.727 12.727 0 0 0-.844-4.6 12.062 12.062 0 0 0-2.4-3.889 1.875 1.875 0 0 1-.511-1.244 1.558 1.558 0 0 1 .467-1.2 1.756 1.756 0 0 1 1.289-.533 1.614 1.614 0 0 1 1.244.533 15.7 15.7 0 0 1 3.2 5.044A15.933 15.933 0 0 1 152-824a15.37 15.37 0 0 1-1.267 6.2 16.539 16.539 0 0 1-3.422 5.089 16.074 16.074 0 0 1-5.067 3.444A15.481 15.481 0 0 1 136-808z" transform="translate(-118 842)" style="fill:#fff"/>
        <path data-name="Rectangle 160" style="fill:none" d="M0 0h36v36H0z"/>
    </g>
  </svg>
  `;

  const holidaySvgCode = `
    <svg id="Group_323" data-name="Group 323" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svgIcon" viewBox="0 0 36 36">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_307" data-name="Rectangle 307" width="36" height="36" fill="#fff"/>
      </clipPath>
    </defs>
    <g id="Group_322" data-name="Group 322" clip-path="url(#clip-path)">
      <path id="Path_237" data-name="Path 237" d="M21.611,14.376,20.03,16.084l-7.315-.525a1.026,1.026,0,0,0-.374,2l4.922,1.511-2.555,2.761-2.026-.015a.62.62,0,0,0-.425,1.076l3.8,3.516a.622.622,0,0,0,.878-.034l.049-.054a.619.619,0,0,0,.164-.465L17.01,23.9l2.52-2.722,1.89,4.789a1.026,1.026,0,0,0,1.707.32h0a1.025,1.025,0,0,0,.261-.851l-1.1-7.232,1.6-1.726a1.735,1.735,0,0,0,.072-2.357,1.733,1.733,0,0,0-2.343.254" fill="#fff"/>
      <path id="Path_238" data-name="Path 238" d="M32.556,34.01H3.444a1.51,1.51,0,0,1-1.5-1.519V5.9a1.509,1.509,0,0,1,1.5-1.518H32.556a1.509,1.509,0,0,1,1.5,1.518v26.6a1.51,1.51,0,0,1-1.5,1.519M4.944,30.973H31.055V7.416H4.944Z" fill="#fff"/>
      <line id="Line_146" data-name="Line 146" y2="4.712" transform="translate(10.383 3.46)"/>
      <path id="Path_239" data-name="Path 239" d="M10.383,9.643a1.486,1.486,0,0,1-1.5-1.471V3.461a1.5,1.5,0,0,1,3,0V8.172a1.486,1.486,0,0,1-1.5,1.471" fill="#fff"/>
      <line id="Line_147" data-name="Line 147" y2="4.712" transform="translate(18.097 3.46)"/>
      <path id="Path_240" data-name="Path 240" d="M18.1,9.643a1.486,1.486,0,0,1-1.5-1.471V3.461a1.5,1.5,0,0,1,3,0V8.172a1.486,1.486,0,0,1-1.5,1.471" fill="#fff"/>
      <line id="Line_148" data-name="Line 148" y2="4.712" transform="translate(25.617 3.46)"/>
      <path id="Path_241" data-name="Path 241" d="M25.617,9.643a1.486,1.486,0,0,1-1.5-1.471V3.461a1.5,1.5,0,0,1,3,0V8.172a1.486,1.486,0,0,1-1.5,1.471" fill="#fff"/>
    </g>
  </svg>
  `;

  const announcementSvgCode = `
    <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 36 36">
    <g id="Group_324" data-name="Group 324" transform="translate(-51 -1008)">
      <path id="Subtraction_10" data-name="Subtraction 10" d="M1824.932,12571.846a1.471,1.471,0,0,1-1.348-.883l-1.1-2.53-.268.117a1.934,1.934,0,0,1-2.543-1.007l-.514-1.187a1.933,1.933,0,0,1,1.007-2.539l2.395-1.038,4.039-4.108a1.541,1.541,0,0,1,.918-1.543,1.539,1.539,0,0,1,2.026.8l3.341,7.718a1.539,1.539,0,0,1-2.467,1.732l-5.143.12,1,2.3a1.463,1.463,0,0,1-1.34,2.047Zm3.2-13.82a.5.5,0,0,0-.2.043.509.509,0,0,0-.272.281.5.5,0,0,0,.007.391l3.341,7.721a.511.511,0,1,0,.937-.407l-3.341-7.718A.512.512,0,0,0,1828.129,12558.025Z" transform="translate(-1757.617 -11535.961)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
      <path id="Path_243" data-name="Path 243" d="M12.03,23.161a.909.909,0,0,1-1.193-.472L10.324,21.5a.907.907,0,0,1,.473-1.192l2.017-.873,1.233,2.849Z" transform="translate(52.16 1008.483)" fill="#fff"/>
      <path id="Path_244" data-name="Path 244" d="M15.807,26.046a.438.438,0,0,1-.8.348l-1.1-2.532.8-.348Z" transform="translate(51.904 1008.198)" fill="#fff"/>
      <path id="Path_246" data-name="Path 246" d="M26.19,15.929a.513.513,0,0,1-.267.675l-1.167.505a.513.513,0,0,1-.407-.942l1.166-.505a.513.513,0,0,1,.675.267" transform="translate(51.197 1008.75)" fill="#fff"/>
      <path id="Path_247" data-name="Path 247" d="M22.75,15.124a.513.513,0,0,1-.106-.718l.758-1.021a.513.513,0,0,1,.824.612l-.758,1.021a.513.513,0,0,1-.718.106" transform="translate(51.301 1008.921)" fill="#fff"/>
      <path id="Path_248" data-name="Path 248" d="M26.678,19.264a.51.51,0,0,1-.306.412.5.5,0,0,1-.263.039l-1.263-.146a.513.513,0,1,1,.118-1.019l1.263.145A.514.514,0,0,1,26.678,19.264Z" transform="translate(51.172 1008.545)" fill="#fff"/>
      <path id="Path_249" data-name="Path 249" d="M32.556,34.01H3.444a1.51,1.51,0,0,1-1.5-1.519V5.9a1.509,1.509,0,0,1,1.5-1.518H32.556a1.509,1.509,0,0,1,1.5,1.518v26.6a1.51,1.51,0,0,1-1.5,1.519M4.944,30.973H31.055V7.416H4.944Z" transform="translate(51.056 1008)" fill="#fff"/>
      <line id="Line_149" data-name="Line 149" y2="4.712" transform="translate(61.383 1011.46)"/>
      <path id="Path_250" data-name="Path 250" d="M10.383,9.643a1.486,1.486,0,0,1-1.5-1.471V3.461a1.5,1.5,0,0,1,3,0V8.172a1.486,1.486,0,0,1-1.5,1.471" transform="translate(51 1008)" fill="#fff"/>
      <line id="Line_150" data-name="Line 150" y2="4.712" transform="translate(69.097 1011.46)"/>
      <path id="Path_251" data-name="Path 251" d="M18.1,9.643a1.486,1.486,0,0,1-1.5-1.471V3.461a1.5,1.5,0,0,1,3,0V8.172a1.486,1.486,0,0,1-1.5,1.471" transform="translate(51 1008)" fill="#fff"/>
      <line id="Line_151" data-name="Line 151" y2="4.712" transform="translate(76.617 1011.46)"/>
      <path id="Path_252" data-name="Path 252" d="M25.617,9.643a1.486,1.486,0,0,1-1.5-1.471V3.461a1.5,1.5,0,0,1,3,0V8.172a1.486,1.486,0,0,1-1.5,1.471" transform="translate(51 1008)" fill="#fff"/>
      <rect id="Rectangle_308" data-name="Rectangle 308" width="36" height="36" transform="translate(51 1008)" fill="none"/>
    </g>
  </svg>
  `;
  return (
    <div className={'sidebar ' + className}>
      <div className="sidebar__logo">
        <Link to="/">
          <img className="SidebarLogo" src="/logo.png" alt="logo" />
        </Link>
      </div>
      <div>
        <nav className="main__navbar">
          <SidebarItem to="/">
            <div
              className="main__navbar__icon"
              dangerouslySetInnerHTML={{ __html: dashboardSvgCode }}
            />
            Dashboard
          </SidebarItem>

          <SidebarItem to="/profile">
            <div
              className="main__navbar__icon"
              dangerouslySetInnerHTML={{ __html: profileSvgCode }}
            />
            Profile
          </SidebarItem>

          <SidebarItem to="/attendance">
            <div
              className="main__navbar__icon"
              dangerouslySetInnerHTML={{ __html: attendanceSvgCode }}
            />
            Attendance
          </SidebarItem>

          <SidebarItemWithDropdownLeave
            title="Leave"
            icon={
              <div
                className="main__navbar__icon"
                dangerouslySetInnerHTML={{ __html: leavesSvgCode }}
              />
            }
            dropdownOpenLeave={dropdownOpenLeave}
            toggleDropdownLeave={toggleDropdownLeave}
          >
            {userRoleId !== 1 && userRoleId !== 4 && (
              <DropdownItemLeave to="/NewLeave">
                <div className="main__navbar__icon" />
                New Leave
              </DropdownItemLeave>
            )}
            {userRoleId === 1 || userRoleId === 4 ? (
              <DropdownItemLeave to="/MyLeaves">
                <div className="main__navbar__icon" />
                Team Leaves
              </DropdownItemLeave>
            ) : (
              <DropdownItemLeave to="/MyLeaves">
                <div className="main__navbar__icon" />
                My Leaves
              </DropdownItemLeave>
            )}

            <DropdownItemLeave to="/leave/requests">
              <div className="main__navbar__icon" />
              Pending Leave
            </DropdownItemLeave>
          </SidebarItemWithDropdownLeave>

          <SidebarItem to="/holiday">
            <div
              className="main__navbar__icon"
              dangerouslySetInnerHTML={{ __html: holidaySvgCode }}
            />
            Holiday
          </SidebarItem>

          <SidebarItem to="/announcement">
            <div
              className="main__navbar__icon"
              dangerouslySetInnerHTML={{ __html: announcementSvgCode }}
            />
            Announcement
          </SidebarItem>

          <SidebarItemWithDropdownRequests
            title="Requests"
            icon={
              <div
                className="main__navbar__icon"
                dangerouslySetInnerHTML={{ __html: requestSvgCode }}
              />
            }
            dropdownOpenRequests={dropdownOpenRequests}
            toggleDropdownRequests={toggleDropdownRequests}
          >
            <DropdownItemRequests to="/inventory">
              <div className="main__navbar__icon" />
              Inventory Request
            </DropdownItemRequests>
            <DropdownItemRequests to="/special">
              <div className="main__navbar__icon" />
              Special Request
            </DropdownItemRequests>
          </SidebarItemWithDropdownRequests>
        </nav>
      </div>

      <div style={{ marginTop: 0 }} className="main__navbar">
        <button
          style={{
            backgroundColor: '#15267d',
            border: 'none',
            cursor: 'pointer',
          }}
          className="navbar__item"
          onClick={() => setIsModalOpen(true)}
        >
          <span className="navbar__item__span">
            <div
              className="main__navbar__icon"
              dangerouslySetInnerHTML={{ __html: logoutSvgCode }}
            />
            Logout
          </span>
        </button>
      </div>
      <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="modal__popup">
          <div className="Portal-Login-Form">
            <div
              className="ValidationModal"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className="approvalText">
                Are you sure you want to logout ?
              </span>

              <div className="CloseRejectionModalButton">
                <button
                  type="button"
                  className="CloseRejectionModal"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="rejectButtonValidationModal"
                  onClick={handleLogout}
                >
                  LogOut
                </button>
              </div>
            </div>
          </div>
        </div>
      </RejectionModal>
    </div>
  );
}
