import apiService from './ApiService';

export class SpecialRequestService {
  async save(data): Promise<void> {
    console.log('SpecialRequestService', data);
    await apiService.post('/api/special-request', data);
  }

  async findAll(id: string) {
    return (await apiService.get(`/api/special-request/findAll/${id}`, {}))
      .data;
  }
  async findOne(id: string) {
    return (await apiService.get(`/api/special-request/${id}`)).data;
  }

  async update(id: number, data) {
    await apiService.put(`/api/special-request/update/${id}`, data);
  }

  async filter(data) {
    const data2 = await apiService.post('/api/special-request/filter', data);
    return data2.data;
  }
}

const specialRequestService = new SpecialRequestService();
export { specialRequestService as default };
