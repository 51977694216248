import LeaveType from '../models/leaveType/LeaveType';
import apiService from './ApiService';

export class LeaveTypeService {
  async findAll(): Promise<LeaveType[]> {
    return (await apiService.get<LeaveType[]>('/api/leave-type', {})).data;
  }
}

const leaveTypeService = new LeaveTypeService();
export { leaveTypeService as default };
