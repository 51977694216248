import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import Modal from '../../pages/BootstrapModal';
import RejectionModal from '../../pages/RejectionModal';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

interface NavbarProps {
  className: string;
}

export default function Navbar({ className }: NavbarProps) {
  const history = useHistory();
  const location = useLocation();

  const { authenticatedUser, setAuthenticatedUser } = useAuth();

  const [imageSrc, setImageSrc] = useState('');
  const [imageLoadError, setImageLoadError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);

  const [isModalOpenPassword, setIsModalOpenPassword] = useState(false);
  const [isModalOpenPasswordSuccess, setIsModalOpenPasswordSuccess] =
    useState(false);

  const [selectedPassword, setSelectedPassword] = useState('');
  const [selectedCPassword, setSelectedCPassword] = useState('');
  const [selectedOPassword, setSelectedOPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showOPassword, setShowOPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [cPasswordError, setCPasswordError] = useState(false);
  const [passwordOError, setPasswordOError] = useState(false);

  const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);
  const [userData, setUserData] = useState([]);

  const [validCredentials, setValidCredentials] = useState(true);
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const toggleContainer = useRef(null);

  const eyeSvgCode = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 36 36"
    >
      <path data-name="Path 54" d="M0 0h36v36H0z" fill="none" />
      <path
        data-name="Path 55"
        d="M17.5 7a14.655 14.655 0 0 1 13.23 8.25 14.733 14.733 0 0 1-26.46 0A14.655 14.655 0 0 1 17.5 7m0-3A17.74 17.74 0 0 0 1 15.25a17.725 17.725 0 0 0 33 0A17.74 17.74 0 0 0 17.5 4zm0 7.5a3.75 3.75 0 1 1-3.75 3.75 3.751 3.751 0 0 1 3.75-3.75m0-3a6.75 6.75 0 1 0 6.75 6.75A6.76 6.76 0 0 0 17.5 8.5z"
        transform="translate(.5 2)"
        fill="#15267d"
      />
    </svg>
  `;

  const eyeHideSvgCode = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 36 36"
  >
    <path
      data-name="Path 56"
      d="M0 0h36v36H0zm0 0h36v36H0zm0 0h36v36H0zm0 0h36v36H0z"
      fill="none"
    />
    <path
      data-name="Path 57"
      d="M18 8.225a15.049 15.049 0 0 1 13.631 8.946 15.617 15.617 0 0 1-3.725 5.075l2.179 2.294A19.228 19.228 0 0 0 35 17.171a18.323 18.323 0 0 0-17-12.2 17.479 17.479 0 0 0-5.625.927l2.55 2.684A14.875 14.875 0 0 1 18 8.225zm-1.654 1.854 3.2 3.367a3.968 3.968 0 0 1 1.978 2.082l3.2 3.367a7.989 7.989 0 0 0 .216-1.74A7.118 7.118 0 0 0 18 9.851a6.251 6.251 0 0 0-1.654.228zM2.561 4.76 6.7 9.119A19.045 19.045 0 0 0 1 17.171a18.323 18.323 0 0 0 17 12.2 17.336 17.336 0 0 0 6.676-1.334l5.286 5.563 2.179-2.294L4.74 2.45zm11.591 12.2 4.034 4.246a.741.741 0 0 1-.185.033 3.97 3.97 0 0 1-3.864-4.067c-.001-.082.015-.131.015-.212zM8.9 11.429l2.7 2.847a7.824 7.824 0 0 0-.556 2.9A7.155 7.155 0 0 0 18 24.491a6.677 6.677 0 0 0 2.735-.586L22.25 25.5a15.372 15.372 0 0 1-4.25.618 15.049 15.049 0 0 1-13.631-8.947A15.946 15.946 0 0 1 8.9 11.429z"
      fill="#15267d"
    />
  </svg>`;

  const handleOPasswordChange = (event) => {
    setSelectedOPassword(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setSelectedPassword(event.target.value);
  };

  const handleCPasswordChange = (event) => {
    setSelectedCPassword(event.target.value);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleCPassword = () => {
    setShowCPassword(!showCPassword);
  };
  const toggleOPassword = () => {
    setShowOPassword(!showOPassword);
  };

  const redirectToProfile = () => {
    if (location.pathname !== '/profile') {
      history.push('/profile');
    } else {
      window.location.reload();
    }
  };

  const openChangePasswordModal = () => {
    setIsModalOpenPassword(true);
    const sidebar = document.querySelector('.sidebarParent');
    const attendanceProgressBar = document.querySelectorAll(
      '.attendanceProgress',
    );
    const employee_history = document.querySelector('.timeline');
    const dashboard__today__attendance =
      document.querySelector('.lateMinuteBar');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (attendanceProgressBar) {
      attendanceProgressBar.forEach((element) => {
        element.classList.add('blurred__attendanceProgressBar');
      });
    }
    if (employee_history) {
      employee_history.classList.add('blurred__employee_history');
    }
    if (dashboard__today__attendance) {
      dashboard__today__attendance.classList.add(
        'blurred__dashboard__today__attendance',
      );
    }
  };

  const openLogOutModal = () => {
    setIsLogOutModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const attendanceProgressBar = document.querySelectorAll(
      '.attendanceProgress',
    );
    const employee_history = document.querySelector('.timeline');
    const dashboard__today__attendance =
      document.querySelector('.lateMinuteBar');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (attendanceProgressBar) {
      attendanceProgressBar.forEach((element) => {
        element.classList.add('blurred__attendanceProgressBar');
      });
    }
    if (employee_history) {
      employee_history.classList.add('blurred__employee_history');
    }
    if (dashboard__today__attendance) {
      dashboard__today__attendance.classList.add(
        'blurred__dashboard__today__attendance',
      );
    }
  };

  const closeChangePasswordModal = () => {
    setIsModalOpenPassword(false);
    const sidebar = document.querySelector('.sidebarParent');
    const employee_history = document.querySelector('.timeline');
    const attendanceProgressBar = document.querySelectorAll(
      '.attendanceProgress',
    );
    const dashboard__today__attendance =
      document.querySelector('.lateMinuteBar');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (employee_history) {
      employee_history.classList.remove('blurred__employee_history');
    }
    if (dashboard__today__attendance) {
      dashboard__today__attendance.classList.remove(
        'blurred__dashboard__today__attendance',
      );
    }
    if (attendanceProgressBar) {
      attendanceProgressBar.forEach((element) => {
        element.classList.remove('blurred__attendanceProgressBar');
      });
    }
  };

  const closeChangePasswordSuccessModal = () => {
    setIsModalOpenPasswordSuccess(false);
    const sidebar = document.querySelector('.sidebarParent');
    const employee_history = document.querySelector('.timeline');
    const attendanceProgressBar = document.querySelectorAll(
      '.attendanceProgress',
    );
    const dashboard__today__attendance =
      document.querySelector('.lateMinuteBar');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (employee_history) {
      employee_history.classList.remove('blurred__employee_history');
    }
    if (dashboard__today__attendance) {
      dashboard__today__attendance.classList.remove(
        'blurred__dashboard__today__attendance',
      );
    }
    if (attendanceProgressBar) {
      attendanceProgressBar.forEach((element) => {
        element.classList.remove('blurred__attendanceProgressBar');
      });
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);
    const fetchUserData = async () => {
      const user_details = await UserService.findOne(parsedUser.id);
      setUserData(user_details['user'][0]);
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleContainer.current &&
        !toggleContainer.current.contains(event.target)
      ) {
        setIsOpen(false);
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setIsOpen(!isOpen);
  };

  const closeLogOutModal = () => {
    setIsLogOutModalOpen(false);
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        const parsedUser = JSON.parse(storedUser);

        const response = await fetch(
          `https://hrportals.ainsofttech.com/api/api/users/profile_image_navbar/${parsedUser.id}`,
        );
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
          setImageLoadError(false);
        } else {
          setImageLoadError(true);
        }
      } catch (error) {
        console.log('Error fetching image:', error);
        setImageLoadError(true);
      }
    };

    fetchImage();
  }, []);

  const changePassword = async () => {
    if (selectedOPassword === '') {
      setPasswordOError(true);
      setPasswordErrorText('Please Enter All Fields');
      setValidCredentials(false);
      return;
    } else {
      setPasswordOError(false);
      setValidCredentials(true);
      setPasswordErrorText('');
    }

    if (selectedPassword === '') {
      setPasswordError(true);
      setPasswordErrorText('Please Enter All Fields');
      setValidCredentials(false);
      return;
    } else {
      setPasswordError(false);
      setValidCredentials(true);
      setPasswordErrorText('');
    }

    if (selectedCPassword === '') {
      setCPasswordError(true);
      setValidCredentials(false);
      setPasswordErrorText('Please Enter All Fields');
      return;
    } else {
      setValidCredentials(true);
      setPasswordErrorText('');
      setCPasswordError(false);
    }

    if (selectedPassword === selectedCPassword) {
      setPasswordErrorText('');
      setValidCredentials(true);
      setIsPasswordSubmitting(true);
      try {
        const storedUser = localStorage.getItem('user');
        const parsedUser = JSON.parse(storedUser);
        const data = {
          password: selectedOPassword,
          cPassword: selectedPassword,
        };
        const asd = await UserService.matchPassword(parsedUser.id, data);
        if (asd === "old password doesn't match") {
          setPasswordOError(true);
          setValidCredentials(false);
          setPasswordErrorText('Incorrect Old Password');
          setIsPasswordSubmitting(false);
        } else {
          setIsModalOpenPasswordSuccess(true);
          setIsModalOpenPassword(false);

          setPasswordOError(false);
          setPasswordError(false);
          setCPasswordError(false);
          setValidCredentials(true);
          setPasswordErrorText('');
          setIsPasswordSubmitting(false);
        }
      } catch (error) {
        setIsPasswordSubmitting(false);
        setPasswordOError(false);
        setPasswordError(false);
        setCPasswordError(false);
        setValidCredentials(false);
        setPasswordErrorText('Something went wrong try again later!');
      }
    } else {
      setCPasswordError(false);
      setPasswordErrorText("Password and Confirm Password doesn't match");
      setValidCredentials(false);
    }
  };

  const handleLogout = async () => {
    await AuthService.logout();
    setAuthenticatedUser(null);
    history.push('/login');
  };

  return (
    <div className={className}>
      <div className="navbar__header dropdown-container" ref={toggleContainer}>
        <div
          style={{ width: '25vw', margin: 'auto' }}
          className="logoOnNavbarResponsive"
        >
          <img src="/logo.png" alt="logo" />
        </div>

        {!isModalOpen && (
          <div className="dropdown-header" onClick={() => handleModalToggle()}>
            <div className="dropdown-header-title">
              {imageLoadError ? (
                <img
                  className="navbar__image"
                  src="/avatar_male.png"
                  alt="Avatar"
                />
              ) : (
                <img
                  className="navbar__image"
                  src={imageSrc}
                  alt="User Profile"
                />
              )}
              <div style={{ padding: '0 2vw 0 1vw' }}>
                <div className="navbar__employee__name">
                  <h3>
                    {userData['first_name']} {userData['last_name']}
                  </h3>
                </div>
                <div className="navbar__employee__designation">
                  <h3> {userData['designation_name']}</h3>
                </div>
              </div>
            </div>
            <div className="navbar__image__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="6"
                viewBox="0 0 17 8.5"
              >
                <path d="M8.5 8.5 0 0h17z" />
              </svg>
            </div>
          </div>
        )}

        {isOpen && (
          <ul className="dropdown-list">
            <li className="dropdown-list-item dropdown-list-item__header ">
              <div className="navbar__header dropdown-container">
                <div
                  className="dropdown-header"
                  onClick={() => handleModalToggle()}
                >
                  <div className="dropdown-header-title">
                    {imageLoadError ? (
                      <img
                        className="navbar__image"
                        src="/avatar_male.png"
                        alt="Avatar"
                      />
                    ) : (
                      <img
                        className="navbar__image"
                        src={imageSrc}
                        alt="User Profile"
                      />
                    )}
                    <div style={{ padding: '0 1vw' }}>
                      <div className="navbar__employee__name">
                        <h3>
                          {userData['first_name']} {userData['last_name']}
                        </h3>
                      </div>
                      <div className="navbar__employee__designation">
                        <h3> {userData['designation_name']}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="navbar__image__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="8.5"
                      viewBox="0 0 17 8.5"
                    >
                      <path d="M8.5 8.5 0 0h17z" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid rgba(112, 112, 112, 0.15)',
              }}
            ></hr>
            <li className="dropdown-list-item">
              <button onClick={() => redirectToProfile()}>
                <span className="icon-navbar-dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="navbar__dropdown_svg"
                    viewBox="0 0 24 24"
                  >
                    <g data-name="Group 150">
                      <path
                        data-name="Path 1"
                        d="M16.085 12.813a5.966 5.966 0 1 0-7.03 0 9.016 9.016 0 0 0-5.488 8.276.935.935 0 0 0 .934.937.936.936 0 0 0 .936-.934A7.132 7.132 0 0 1 19.7 21.1a.936.936 0 1 0 1.871 0 9.017 9.017 0 0 0-5.488-8.288M8.475 8a4.1 4.1 0 1 1 4.095 4.1A4.1 4.1 0 0 1 8.475 8"
                        transform="translate(-.567 -.037)"
                        fill="#15267d"
                      />
                      <path
                        data-name="Rectangle 161"
                        fill="none"
                        d="M0 0h24v24H0z"
                      />
                    </g>
                  </svg>
                </span>
                My Profile
              </button>
            </li>
            <li className="dropdown-list-item">
              <button onClick={() => openChangePasswordModal()}>
                <span className="icon-navbar-dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="navbar__dropdown_svg"
                    viewBox="0 0 24 24"
                  >
                    <g data-name="Group 149">
                      <path
                        d="M161.9-900a1.835 1.835 0 0 1-1.345-.559A1.834 1.834 0 0 1 160-901.9v-9.524a1.834 1.834 0 0 1 .559-1.345 1.834 1.834 0 0 1 1.345-.56h.952v-1.9a4.59 4.59 0 0 1 1.393-3.369 4.59 4.59 0 0 1 3.37-1.402 4.59 4.59 0 0 1 3.369 1.393 4.59 4.59 0 0 1 1.393 3.369v1.9h.952a1.834 1.834 0 0 1 1.345.56 1.834 1.834 0 0 1 .56 1.345v9.524a1.834 1.834 0 0 1-.56 1.345 1.834 1.834 0 0 1-1.345.559zm0-1.9h11.429v-9.524H161.9zm5.714-2.857a1.834 1.834 0 0 0 1.345-.56 1.834 1.834 0 0 0 .56-1.345 1.834 1.834 0 0 0-.56-1.345 1.834 1.834 0 0 0-1.345-.56 1.834 1.834 0 0 0-1.345.56 1.834 1.834 0 0 0-.56 1.345 1.834 1.834 0 0 0 .56 1.345 1.834 1.834 0 0 0 1.35.555zm-2.857-8.571h5.714v-1.9a2.755 2.755 0 0 0-.833-2.024 2.755 2.755 0 0 0-2.024-.833 2.755 2.755 0 0 0-2.024.833 2.756 2.756 0 0 0-.833 2.024zM161.9-901.9z"
                        transform="translate(-156 922)"
                        fill="#15267d"
                      />
                      <path
                        data-name="Rectangle 162"
                        d="M0 0h24v24H0z"
                        fill="none"
                      />
                    </g>
                  </svg>
                </span>
                Change Password
              </button>
            </li>
            <li className="dropdown-list-item">
              <button onClick={openLogOutModal}>
                <span className="icon-navbar-dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="navbar__dropdown_svg"
                    viewBox="0 0 24 24"
                  >
                    <g data-name="Group 151">
                      <path
                        d="M128.889-830v-8.889a1.075 1.075 0 0 1 .319-.792A1.075 1.075 0 0 1 130-840a1.075 1.075 0 0 1 .792.319 1.075 1.075 0 0 1 .319.792V-830a1.075 1.075 0 0 1-.319.792 1.075 1.075 0 0 1-.792.319 1.075 1.075 0 0 1-.792-.319 1.075 1.075 0 0 1-.319-.792zM130-820a9.607 9.607 0 0 1-3.875-.792 10.214 10.214 0 0 1-3.181-2.153 10.214 10.214 0 0 1-2.153-3.181A9.606 9.606 0 0 1 120-830a9.787 9.787 0 0 1 .708-3.681 10.27 10.27 0 0 1 1.986-3.153 1.009 1.009 0 0 1 .778-.333 1.1 1.1 0 0 1 .806.333.949.949 0 0 1 .278.764 1.368 1.368 0 0 1-.333.819 7.884 7.884 0 0 0-1.472 2.4 7.735 7.735 0 0 0-.528 2.847 7.494 7.494 0 0 0 2.278 5.5 7.494 7.494 0 0 0 5.5 2.278 7.481 7.481 0 0 0 5.514-2.278 7.517 7.517 0 0 0 2.264-5.5 7.954 7.954 0 0 0-.528-2.875 7.539 7.539 0 0 0-1.5-2.431 1.172 1.172 0 0 1-.319-.778.974.974 0 0 1 .292-.75 1.1 1.1 0 0 1 .806-.333 1.009 1.009 0 0 1 .778.333 9.812 9.812 0 0 1 2 3.153A9.958 9.958 0 0 1 140-830a9.606 9.606 0 0 1-.792 3.875 10.337 10.337 0 0 1-2.139 3.181 10.046 10.046 0 0 1-3.167 2.153A9.675 9.675 0 0 1 130-820z"
                        transform="translate(-118 842)"
                        fill="#15267d"
                      />
                      <path
                        data-name="Rectangle 16"
                        d="M0 0h24v24H0z"
                        fill="none"
                      />
                    </g>
                  </svg>
                </span>
                Logout
              </button>
            </li>
          </ul>
        )}
      </div>

      <Modal isOpen={isModalOpenPassword} onClose={closeChangePasswordModal}>
        <div className="modal__popup">
          <div className="Portal-Login-Form">
            <div className="Hello-Welcome-back">
              <span>Change Password!</span>
            </div>
            <Box
              sx={{
                width: 500,
                maxWidth: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className="marginBottomInput">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      type={showOPassword ? 'text' : 'password'}
                      className={`Portal_Login_input_change_password ${
                        passwordOError ? 'Portal-Login-input-error' : ''
                      }`}
                      label="Enter Old Password"
                      error={passwordOError}
                      required
                      onChange={handleOPasswordChange}
                      value={selectedOPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleOPassword}
                              edge="end"
                            >
                              {showOPassword ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eyeSvgCode,
                                  }}
                                />
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eyeHideSvgCode,
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={isPasswordSubmitting}
                    />
                  </FormControl>
                </div>

                <div className="paddingTopChangePassword marginBottomInput">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      type={showPassword ? 'text' : 'password'}
                      className={`Portal_Login_input_change_password ${
                        passwordError ? 'Portal-Login-input-error' : ''
                      }`}
                      label="Enter New Password"
                      error={passwordError}
                      required
                      onChange={handlePasswordChange}
                      value={selectedPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eyeSvgCode,
                                  }}
                                />
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eyeHideSvgCode,
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={isPasswordSubmitting}
                    />
                  </FormControl>
                </div>

                <div className="paddingTopChangePassword marginBottomInput">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      type={showCPassword ? 'text' : 'password'}
                      className={`Portal_Login_input_change_password ${
                        cPasswordError ? 'Portal-Login-input-error' : ''
                      }`}
                      label="Confirm Password"
                      error={cPasswordError}
                      required
                      onChange={handleCPasswordChange}
                      value={selectedCPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleCPassword}
                              edge="end"
                            >
                              {showCPassword ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eyeSvgCode,
                                  }}
                                />
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: eyeHideSvgCode,
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={isPasswordSubmitting}
                    />
                  </FormControl>
                  {!validCredentials && (
                    <p className="error-message-invalid">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                          transform="translate(-80 880)"
                          fill="#f00"
                        />
                      </svg>
                      {passwordErrorText}
                    </p>
                  )}
                </div>

                <div className="paddingTopChangePassword">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <Button
                      className="change_password_button"
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={isPasswordSubmitting}
                      onClick={changePassword}
                    >
                      Reset Password
                    </Button>
                  </FormControl>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpenPasswordSuccess}
        onClose={closeChangePasswordSuccessModal}
      >
        <div className="modal__popup">
          <div className="Portal-Login-Form">
            <div className="PasswordChangeSuccess">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="146"
                height="146"
                viewBox="0 0 146 146"
              >
                <defs>
                  <filter
                    id="ulnz4omhta"
                    x="0"
                    y="0"
                    width="146"
                    height="146"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity=".161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g data-name="Group 198">
                  <g style={{ filter: 'url(#ulnz4omhta)' }}>
                    <g
                      data-name="Ellipse 27"
                      transform="translate(17 14)"
                      stroke="#fff"
                      fill="#1fc214"
                      strokeWidth="8px"
                    >
                      <circle cx="56" cy="56" r="56" stroke="none" />
                      <circle cx="56" cy="56" r="60" fill="none" />
                    </g>
                  </g>
                  <path
                    data-name="Path 159"
                    d="M65.39 8.053a3.955 3.955 0 0 0-5.215 0L26.713 41.516 11.068 25.871a3.955 3.955 0 0 0-5.215 0 3.955 3.955 0 0 0 0 5.215l18.252 18.252a6.177 6.177 0 0 0 2.607.869 3.756 3.756 0 0 0 2.607-1.3l36.07-36.07a2.843 2.843 0 0 0 .001-4.784z"
                    transform="translate(36.38 41.7)"
                    stroke="#fff"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div className="Hello-Welcome-back">
              <span>Password Changed Successfully!</span>
            </div>
            <Box
              sx={{
                width: 500,
                maxWidth: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className="paddingTopChangePassword">
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <Button
                      className="change_password_button"
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={isPasswordSubmitting}
                      onClick={closeChangePasswordSuccessModal}
                    >
                      Okay
                    </Button>
                  </FormControl>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </Modal>
      <RejectionModal isOpen={isLogOutModalOpen} onClose={closeLogOutModal}>
        <div className="modal__popup">
          <div className="Portal-Login-Form">
            <div
              className="ValidationModal"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className="approvalText">
                Are you sure you want to logout ?
              </span>

              <div className="CloseRejectionModalButton">
                <button
                  type="button"
                  className="CloseRejectionModal"
                  onClick={closeLogOutModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="rejectButtonValidationModal"
                  onClick={handleLogout}
                >
                  LogOut
                </button>
              </div>
            </div>
          </div>
        </div>
      </RejectionModal>
    </div>
  );
}
