import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface DropdownItemRequestsProps {
  to: string;
  children: React.ReactNode;
}

const DropdownItemRequests: React.FC<DropdownItemRequestsProps> = ({
  to,
  children,
}) => {
  const location = useLocation();
  const isActive =
    to === '/'
      ? location.pathname === to
      : location.pathname.startsWith(to) && to !== '/';

  return (
    <Link className={`navbar__item ${isActive ? 'active' : ''}`} to={to}>
      <span
        style={{ width: '98%', marginLeft: 'auto' }}
        className="navbar__item__span"
      >
        {children}
      </span>
    </Link>
  );
};

export default DropdownItemRequests;
