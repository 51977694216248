import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import LeaveService from '../../services/LeaveService';
import LeaveTypeService from '../../services/LeaveTypeService';
import ValidationModal from '../BootstrapValidationModal';

export default function EditLeave() {
  const history = useHistory();
  const { id } = useParams() as { id: string };

  const [allLeaveType, setAllLeaveType] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [selectedHalf, setSelectedHalf] = useState('');
  const [selectedleaveTenure, setSelectedleaveTenure] = useState('');
  const [selectedHalfDayDate, setSelectedHalfDayDate] = useState(null);
  const [selectedShortFromDate, setSelectedShortFromDate] = useState(null);
  const [selectedShortToDate, setSelectedShortToDate] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [dateError, setDateError] = useState(false);

  const [createdById, setCreatedById] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [remainingSickLeave, setRemainingSickLeave] = useState(0);
  const [remainingCasualLeave, setRemainingCasualLeave] = useState(0);
  const [remainingHalfLeave, setRemainingHalfLeave] = useState(0);
  const [remainingShortLeave, setRemainingShortLeave] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);

  const [isSubmittingModalOpen, setIsSubmittingModalOpen] = useState(false);

  const [leaveTypeError, setLeaveTypeError] = useState(false);
  const [leaveDurationError, setLeaveDurationError] = useState(false);
  const [selectedHalfDayDateError, setSelectedHalfDayDateError] =
    useState(false);
  const [selectedWhichHalfError, setSelectedWhichHalfError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const [shortDateError, setShortDateError] = useState(false);
  const [errorFromBackend, setErrorFromBackend] = useState('');

  const [availFullLeave, setAvailFullLeave] = useState(true);
  const [availHalfLeave, setAvailHalfLeave] = useState(true);
  const [availShortLeave, setAvailShortLeave] = useState(true);
  const [availFullLeaveError, setAvailFullLeaveError] = useState('');
  const [quotaExceedError, setQuotaExceedError] = useState('');

  useEffect(() => {
    let intervalId;
    let isMounted = true; // Add a flag to track if the component is mounted
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    const startProgress = () => {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / steps;
          // Check if progress reaches or exceeds 100%
          if (newProgress >= 100) {
            clearInterval(intervalId);
            setTimeout(() => {
              if (isMounted) {
                setIsSubmittingModalOpen(false);
                history.push(`/MyLeaves/${parsedUser.id}`);
              }
            }, 500); // Add a delay before closing modal for a smoother transition
            return 100; // Ensure progress stays at 100%
          }
          return newProgress;
        });
      }, intervalDuration);
    };

    const intervalDuration = 30; // Interval duration in milliseconds for smoother animation
    const totalTime = 1000; // Total time in milliseconds (1 second)
    const steps = totalTime / intervalDuration; // Number of steps to complete

    if (isSubmittingModalOpen) {
      startProgress();
    }

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
      clearInterval(intervalId); // Ensure the interval is cleared on component unmount
    };
  }, [isSubmittingModalOpen]);

  useEffect(() => {
    const fetchUserData = async () => {
      const leaveType = await LeaveTypeService.findAll();
      setAllLeaveType(leaveType);

      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      setCreatedById(parsedUser.id);

      const leaves = await LeaveService.findRemaingLeaves(parsedUser.id);

      setRemainingSickLeave(leaves.sickLeave);
      setRemainingCasualLeave(leaves.casualLeave);
      setRemainingHalfLeave(leaves.halfLeave);
      setRemainingShortLeave(leaves.shortLeave);

      const leavesData = await LeaveService.findOne(id);
      const singleLeave = leavesData['leave'];
      setSelectedLeaveType(String(singleLeave['leaveTypeId']));
      if (
        singleLeave['which_half'] === 'first_half' ||
        singleLeave['which_half'] === 'second_half'
      ) {
        setSelectedleaveTenure('half_day');
      } else {
        setSelectedleaveTenure(singleLeave['which_half']);
      }
      const dateObject = dayjs(singleLeave['date']);

      const dateTime = `${singleLeave.date} ${singleLeave.fromTime}`;
      const mergedDateTime = dayjs(dateTime);
      const endDate = dayjs(mergedDateTime).add(2, 'hour');
      setSelectedShortToDate(endDate);

      setSelectedHalfDayDate(dateObject);
      setSelectedShortFromDate(mergedDateTime);
      setSelectedDate(dateObject);
      setDescriptionText(singleLeave['description']);
      setSelectedHalf(singleLeave['which_half']);
    };
    fetchUserData();
  }, []);

  const onSubmit = async () => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    if (selectedLeaveType === '') {
      setLeaveTypeError(true);
      setIsModalOpen(true);
      return;
    } else {
      setLeaveTypeError(false);
    }

    const validateDescription = () => {
      if (descriptionText === '') {
        setDescriptionError(true);
        setIsModalOpen(true);
        setIsSubmitting(false);

        return false;
      } else {
        setDescriptionError(false);
        return true;
      }
    };

    const validateDate = async (date, setError) => {
      if (!date) {
        setError(true);
        setIsModalOpen(true);
        setIsSubmitting(false);
        return false;
      } else {
        const today = new Date();

        const staticStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1,
        );

        const staticEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
        );

        const formatToLocalDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');

          return `${year}-${month}-${day}`;
        };

        const data = {
          userId: parsedUser.id,
          month: 'specialFilter',
          startDate: formatToLocalDate(staticStartDate),
          endDate: formatToLocalDate(staticEndDate),
        };

        const totalLeaves = await LeaveService.filterMyLeavesOfMonth(data);

        const noOfFullLeavesInThisMonth = totalLeaves[0].fullDays;
        const noOfHalfLeavesInThisMonth = totalLeaves[0].halfLeaves;
        const noOfShortLeavesInThisMonth = totalLeaves[0].shortLeaves;

        if (selectedDate) {
          const isSameMonth =
            selectedDate.$d.getMonth() === date.$d.getMonth() &&
            selectedDate.$d.getFullYear() === date.$d.getFullYear();
          if (noOfFullLeavesInThisMonth > 0 && isSameMonth) {
            setAvailFullLeave(false);
            setIsModalOpen(true);
            setIsSubmitting(false);
            setAvailFullLeaveError(leaveErrors['full_day']);
            return;
          }
        }
        // setSelectedHalfDayDate
        if (selectedHalfDayDate) {
          const isSameMonth =
            selectedHalfDayDate.$d.getMonth() === date.$d.getMonth() &&
            selectedHalfDayDate.$d.getFullYear() === date.$d.getFullYear();
          if (noOfHalfLeavesInThisMonth > 0 && isSameMonth) {
            setAvailHalfLeave(false);
            setIsModalOpen(true);
            setIsSubmitting(false);
            setAvailFullLeaveError(leaveErrors['half_day']);
            return;
          }
        }
        if (selectedShortFromDate) {
          const isSameMonth =
            selectedShortFromDate.$d.getMonth() === date.$d.getMonth() &&
            selectedShortFromDate.$d.getFullYear() === date.$d.getFullYear();
          if (noOfShortLeavesInThisMonth > 0 && isSameMonth) {
            setAvailShortLeave(false);
            setIsModalOpen(true);
            setIsSubmitting(false);
            setAvailFullLeaveError(leaveErrors['short_leave']);
            return;
          }
        }

        setError(false);
        return true;
      }
    };

    const validateHalf = () => {
      if (selectedHalf === '') {
        setSelectedWhichHalfError(true);
        setIsModalOpen(true);
        setIsSubmitting(false);

        return false;
      } else {
        setSelectedWhichHalfError(false);
        return true;
      }
    };

    const leaveTypes = {
      full_day: !availFullLeave,
      half_day: !availHalfLeave,
      short_leave: !availShortLeave,
    };

    const leaveErrors = {
      full_day:
        "You can't take more than one full leave in one month if you really need it then apply it via special request",
      half_day:
        "You can't take more than one half leave in one month if you really need it then apply it via special request",
      short_leave:
        "You can't take more than one Short leave in one month if you really need it then apply it via special request",
    };

    if (leaveTypes[selectedleaveTenure]) {
      setIsModalOpen(true);
      setIsSubmitting(false);
      setAvailFullLeaveError(leaveErrors[selectedleaveTenure]);
      return;
    }

    const checkLeaveQuota = (quota, message) => {
      if (quota === 0) {
        setIsQuotaModalOpen(true);
        setIsSubmitting(false);
        setQuotaExceedError(message);
        return true;
      }
      return false;
    };

    const leaveQuotaMessages = {
      full_day: {
        '2': 'Your Full Day Sick Leaves Quota Ended',
        '1': 'Your Full Day Casual Leaves Quota Ended',
      },
      half_day: 'Your Half Day Leaves Quota Ended',
      short_leave: 'Your Short Leaves Quota Ended',
    };

    const tenureChecks = {
      full_day: () => {
        if (selectedLeaveType === '2')
          return checkLeaveQuota(
            remainingSickLeave,
            leaveQuotaMessages.full_day['2'],
          );
        if (selectedLeaveType === '1')
          return checkLeaveQuota(
            remainingCasualLeave,
            leaveQuotaMessages.full_day['1'],
          );
      },
      half_day: () =>
        checkLeaveQuota(remainingHalfLeave, leaveQuotaMessages.half_day),
      short_leave: () =>
        checkLeaveQuota(remainingShortLeave, leaveQuotaMessages.short_leave),
    };

    if (tenureChecks[selectedleaveTenure]()) {
      return;
    }
    const buildLeaveData = (leaveTenure, date, extraData = {}) => {
      return {
        leaveTypeId: selectedLeaveType,
        date: date.toISOString(),
        status: 'pending',
        description: descriptionText,
        leaveTenure: leaveTenure,
        createdById: createdById,
        ...extraData,
      };
    };

    // setIsSubmitting(true);

    try {
      switch (selectedleaveTenure) {
        case 'half_day':
          if (
            !validateDate(selectedHalfDayDate, setSelectedHalfDayDateError) ||
            !validateHalf() ||
            !validateDescription()
          )
            return;
          const data = {
            leaveTenure: selectedleaveTenure,
            which_half: selectedHalf,
            date: selectedHalfDayDate.toISOString(),
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate = await LeaveService.leaveOnCurrentDate(
            data,
          );
          if (checkLeaveOnCurrentDate['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }

          await LeaveService.update(
            +id,
            buildLeaveData('half_day', selectedHalfDayDate, {
              which_half: selectedHalf,
            }),
          );
          break;

        case 'short_leave':
          if (
            !validateDate(selectedShortFromDate, setShortDateError) ||
            !validateDescription()
          )
            return;
          const data2 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'short_leave',
            date: selectedShortFromDate.toISOString(),
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate2 =
            await LeaveService.leaveOnCurrentDate(data2);
          if (checkLeaveOnCurrentDate2['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate2['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.update(
            +id,
            buildLeaveData('short_leave', selectedShortFromDate),
          );
          break;

        case 'full_day':
          if (
            !validateDate(selectedDate, setDateError) ||
            !validateDescription()
          )
            return;
          const data1 = {
            leaveTenure: selectedleaveTenure,
            which_half: 'full_day',
            date: selectedDate,
            usersId: createdById,
          };
          const checkLeaveOnCurrentDate1 =
            await LeaveService.leaveOnCurrentDate(data1);
          if (checkLeaveOnCurrentDate1['error']) {
            setIsModalOpen(true);
            setErrorFromBackend(checkLeaveOnCurrentDate1['message']);
            setIsSubmitting(false);
            return;
          } else {
            setIsModalOpen(false);
            setErrorFromBackend('');
          }
          await LeaveService.update(
            +id,
            buildLeaveData('full_day', selectedDate),
          );
          break;

        default:
          throw new Error('Invalid leave tenure type');
      }
      setIsSubmittingModalOpen(true);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeQuotaModal = () => {
    setIsQuotaModalOpen(false);
  };

  const closeSubmittingModal = () => {
    setIsSubmittingModalOpen(false);
  };

  const handleSelectLeaveType = (event) => {
    if (event.target.value !== '') {
      setLeaveTypeError(false);
    }
    setSelectedLeaveType(event.target.value);
  };

  const handleShortFromDateChange = (date) => {
    setSelectedShortFromDate(date);
    const endDate = dayjs(date.$d).add(2, 'hour');
    setSelectedShortToDate(endDate);
    if (date) {
      setShortDateError(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      setDateError(false);
    }
  };

  const handleHalfDayChange = (date) => {
    setSelectedHalfDayDate(date);
    if (date) {
      setSelectedHalfDayDateError(false);
    }
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    if (name === 'select_half') {
      setSelectedHalf(value);
      if (selectedWhichHalfError) {
        if (value !== '') {
          setSelectedWhichHalfError(false);
        }
      }
    }
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    setDescriptionText(value);
    if (value !== '') {
      setDescriptionError(false);
    }
  };

  const handleRadioChange = (event) => {
    if (event.target.value !== '') {
      setLeaveDurationError(false);
    }
    setSelectedHalfDayDate(null);
    setDescriptionText('');
    setSelectedHalf('');
    setSelectedleaveTenure(event.target.value);
    setErrorFromBackend('');
    setAvailFullLeaveError('');
    setQuotaExceedError('');
  };

  const maxAllowedTime = new Date(selectedShortFromDate);
  maxAllowedTime.setHours(maxAllowedTime.getHours() + 2);
  const sixPM = new Date(selectedShortFromDate);
  sixPM.setHours(18, 0, 0, 0);

  if (maxAllowedTime > sixPM) {
    maxAllowedTime.setHours(18, 0, 0, 0);
  }

  const minAllowedTime = new Date(selectedShortFromDate);
  minAllowedTime.setHours(9, 0, 0, 0);

  const maxAllowedTimes = new Date(selectedShortFromDate);
  maxAllowedTimes.setHours(18, 0, 0, 0);

  return (
    <Layout>
      <div className="leavesIndex">
        <h4 className="attendance_overview_heading">Leave Request Form</h4>

        <div className="leavesIndex__body">
          <div className="leavesIndex__body_padding">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField addLeaveInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Type Of Leave
                      </InputLabel>
                      <select
                        name="leave_type"
                        id="leave_type"
                        className={`leaveDurationInput ${
                          leaveTypeError ? 'leaveDurationInput-error' : ''
                        }`}
                        onChange={handleSelectLeaveType}
                        value={selectedLeaveType}
                      >
                        <option value="">Select Leave Type</option>
                        {allLeaveType.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="singleInputField addLeaveInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Duration
                      </InputLabel>
                      <select
                        name="leave_type"
                        id="leave_type"
                        className={`leaveDurationInput ${
                          leaveDurationError ? 'leaveDurationInput-error' : ''
                        }`}
                        onChange={handleRadioChange}
                        value={selectedleaveTenure}
                        disabled={true}
                      >
                        <option value="full_day">Full Day</option>
                        <option value="half_day">Half Day</option>
                        <option value="short_leave">Short Leave</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {selectedleaveTenure === 'half_day' && (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'MobileDatePicker',
                            'DesktopDatePicker',
                            'StaticDatePicker',
                          ]}
                        >
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Date
                          </InputLabel>

                          <DesktopDatePicker
                            onChange={handleHalfDayChange}
                            className={`leaveDurationInput leaveDurationInputDate newLeaveScreen ${
                              selectedHalfDayDateError
                                ? 'leaveDurationInput-error'
                                : ''
                            }`}
                            value={selectedHalfDayDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="singleInputField">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'MobileDatePicker',
                            'DesktopDatePicker',
                            'StaticDatePicker',
                          ]}
                        >
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Select Half
                          </InputLabel>
                          <select
                            name="select_half"
                            id="select_half"
                            className={`leaveDurationInput ${
                              selectedWhichHalfError
                                ? 'leaveDurationInput-error'
                                : ''
                            }`}
                            style={{ padding: '16.5px 12px' }}
                            onChange={handleChange}
                          >
                            <option value="">Select Half</option>
                            <option value="first_half">First</option>
                            <option value="second_half">Second</option>
                          </select>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '1vw 0',
                    }}
                  >
                    <div className="singleInputField singleInputFieldTextArea">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Reason For Leave
                      </InputLabel>
                      <FormControl variant="standard">
                        <textarea
                          className={`leaveDurationTextArea leaveDurationInput ${
                            descriptionError ? 'leaveDurationInput-error' : ''
                          }`}
                          onChange={handleChangeDescription}
                          value={descriptionText}
                          placeholder="Enter text here..."
                          rows={6}
                          cols={50}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}

              {/* short */}
              {selectedleaveTenure === 'short_leave' && (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            From Date
                          </InputLabel>

                          <DateTimePicker
                            onChange={handleShortFromDateChange}
                            className={`leaveDurationInput  newLeaveScreen${
                              shortDateError ? 'leaveDurationInput-error' : ''
                            }`}
                            value={selectedShortFromDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="singleInputField">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            To Date
                          </InputLabel>

                          <DateTimePicker
                            className={`leaveDurationInput newLeaveScreen`}
                            value={selectedShortToDate}
                            readOnly
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '1vw 0',
                    }}
                  >
                    <div className="singleInputField singleInputFieldTextArea">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Reason For Leave
                      </InputLabel>
                      <FormControl variant="standard">
                        <textarea
                          className={`leaveDurationTextArea leaveDurationInput ${
                            descriptionError ? 'leaveDurationInput-error' : ''
                          }`}
                          onChange={handleChangeDescription}
                          value={descriptionText}
                          placeholder="Enter text here..."
                          rows={6}
                          cols={50}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}

              {selectedleaveTenure === 'full_day' && (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'MobileDatePicker',
                            'DesktopDatePicker',
                            'StaticDatePicker',
                          ]}
                        >
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Date
                          </InputLabel>

                          <DesktopDatePicker
                            onChange={handleDateChange}
                            className={`leaveDurationInput newLeaveScreen ${
                              dateError ? 'leaveDurationInput-error' : ''
                            }`}
                            value={selectedDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="singleInputField"></div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '1vw 0',
                    }}
                  >
                    <div className="singleInputField singleInputFieldTextArea">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Reason For Leave
                      </InputLabel>
                      <FormControl variant="standard">
                        <textarea
                          className={`leaveDurationTextArea leaveDurationInput ${
                            descriptionError ? 'leaveDurationInput-error' : ''
                          }`}
                          onChange={handleChangeDescription}
                          value={descriptionText}
                          placeholder="Enter text here..."
                          rows={6}
                          cols={50}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField"></div>
                <div className="singleInputField">
                  <div className="formButton">
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="formSubmitButton"
                      onClick={onSubmit}
                    >
                      {isSubmitting ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <ValidationModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            {availFullLeaveError === '' ? (
              <div className="Portal-Login-Form">
                {errorFromBackend === '' ? (
                  <div className="ValidationModal">
                    <span>Please Enter All Fields To Continue!</span>
                    <div className="CloseValidationModalButton">
                      <button
                        type="button"
                        disabled={isSubmitting}
                        className="CloseValidationModal"
                        onClick={closeModal}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="ValidationModal">
                    <span>{errorFromBackend}</span>
                    <div className="CloseValidationModalButton">
                      <button
                        type="button"
                        disabled={isSubmitting}
                        className="CloseValidationModal"
                        onClick={closeModal}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="Portal-Login-Form">
                <div className="ValidationModal">
                  <span>{availFullLeaveError}</span>
                  <div className="CloseValidationModalButton">
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="CloseValidationModal"
                      onClick={closeModal}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ValidationModal>

        <ValidationModal isOpen={isQuotaModalOpen} onClose={closeQuotaModal}>
          <div className="modal__popup">
            <div className="ValidationModal">
              <span>{quotaExceedError}</span>
              <div className="CloseValidationModalButton">
                <button
                  type="button"
                  disabled={isSubmitting}
                  className="CloseValidationModal"
                  onClick={closeQuotaModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ValidationModal>
        <ValidationModal
          isOpen={isSubmittingModalOpen}
          onClose={closeSubmittingModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div className="ProgressModal">
                <Box className="progressOfSubmitting" sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 15,
                      borderRadius: 10,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#1fc214',
                      },
                    }}
                  />
                </Box>
                <span>Submitting Request</span>
              </div>
            </div>
          </div>
        </ValidationModal>
      </div>
    </Layout>
  );
}
