import RolePermission from '../models/rolePermission/rolePermission';
import apiService from './ApiService';

export class RolePermissionService {
  async findAll(id: string): Promise<RolePermission[]> {
    return (
      await apiService.get<RolePermission[]>(
        `/api/role-permissions/view/${id}`,
        {},
      )
    ).data;
  }

  async save(data): Promise<void> {
    await apiService.post('/api/role-permissions', data);
  }
}

const rolePermissionService = new RolePermissionService();
export { rolePermissionService as default };
