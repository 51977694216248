import { ReactNode } from 'react';
import { ChevronRight } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';

interface SidebarItemProps {
  children: ReactNode;
  to: string;
  active?: boolean;
}

export default function SidebarItem({
  children,
  to,
  active = false,
}: SidebarItemProps) {
  const location = useLocation();
  const isActive =
    to === '/'
      ? location.pathname === to
      : location.pathname.startsWith(to) && to !== '/';

  return (
    <Link to={to} className={`navbar__item ${isActive ? 'active' : ''}`}>
      <span className="navbar__item__span">
        {children} {active ? <ChevronRight /> : null}
      </span>
    </Link>
  );
}
