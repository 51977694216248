import SearchIcon from '@mui/icons-material/Search';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import specialRequestService from '../../services/specialRequestService';
import UserService from '../../services/UserService';
import DatePickerModal from '../DatePickerModal';

const columns = [
  { id: 'sr_no', label: 'Sr no.', minWidth: 50, sortable: false },
  { id: 'full_name', label: 'Name', minWidth: 100, sortable: true },
  { id: 'subject', label: 'Subject', minWidth: 100, sortable: true },
  { id: 'date', label: 'Date', minWidth: 100, sortable: true },
  { id: 'status', label: 'Status', minWidth: 100, sortable: true },
  { id: 'approveById', label: 'Approved By', minWidth: 100, sortable: true },
  { id: 'details', label: 'Details', minWidth: 100, sortable: false },
];

export default function SpecialList() {
  const history = useHistory();
  const toggleContainer = useRef<HTMLDivElement | null>(null);

  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSubject, setSearchSubject] = useState('');
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isSubjectFocused, setIsSubjectFocused] = useState(false);
  const [isMyTeam, setIsMyTeam] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = (event) => {
    const { value } = event.target;

    setSearchTerm(value);

    const baseFilter = (data) => {
      const nameMatch =
        value === '' ||
        data['full_name'].toLowerCase().includes(value.toLowerCase());

      const monthMatch =
        !selectedDate ||
        (new Date(data.date) >= new Date(selectionRange.startDate) &&
          new Date(data.date) <= new Date(selectionRange.endDate));

      const statusMatch =
        selectedStatus === '' ||
        selectedStatus.toLowerCase() === 'all' ||
        data['status'].toLowerCase().includes(selectedStatus.toLowerCase());

      return nameMatch && monthMatch && statusMatch;
    };
    const filtered = tableData.filter(baseFilter);
    setFilteredData(filtered);
    // }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const specialRequest = await specialRequestService.findAll(parsedUser.id);
      const filteredData = specialRequest.filter(
        (data) => data['status'] === 'pending',
      );
      setTableData(specialRequest);
      setFilteredData(filteredData);

      const MyTeam = await UserService.findTeam(parsedUser.id);
      const usersArray = MyTeam.user;
      setIsMyTeam(usersArray.length > 1 ? true : false);
    };

    fetchUserData();
  }, []);

  const handleSort = (columnId: string) => {
    if (columnId === 'sr_no' || columnId === 'details') return; // Skip sorting for these columns
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortBy === 'sr_no' || sortBy === 'details') return 0; // Skip sorting for these columns

    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toggleContainer.current &&
      !toggleContainer.current.contains(event.target as Node)
    ) {
      setIsDateModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Apply date range selection
  const handleApply = async () => {
    const startDates = selectionRange.startDate;
    const endDates = selectionRange.endDate;
    const isSameDate =
      startDates.getDate() === endDates.getDate() &&
      startDates.getMonth() === endDates.getMonth() &&
      startDates.getFullYear() === endDates.getFullYear();

    let formattedDate = '';

    if (isSameDate) {
      formattedDate = startDates.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } else {
      const sameMonth = startDates.getMonth() === endDates.getMonth();
      const sameYear = startDates.getFullYear() === endDates.getFullYear();

      if (sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else if (!sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      }
    }

    setSelectedDate(formattedDate);

    const formatToLocalDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    const data = {
      userId: parsedUser.id,
      startDate: formatToLocalDate(selectionRange.startDate),
      endDate: formatToLocalDate(selectionRange.endDate),
    };

    const specialRequest = await specialRequestService.filter(data);
    setTableData(specialRequest);

    const baseFilter = (data) => {
      const statusMatch =
        selectedStatus === 'pending'
          ? data['status'] === 'pending'
          : data['status'] === selectedStatus;

      const termMatch =
        searchTerm === ''
          ? true
          : data['full_name'].toLowerCase().includes(searchTerm.toLowerCase());

      return statusMatch && termMatch;
    };

    // Filter data using the combined filter
    const filtered = specialRequest.filter(baseFilter);
    setFilteredData(filtered);

    closeDateModal();
  };

  const handleSelect = async (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const closeDateModal = () => {
    setIsDateModalOpen(false);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.remove('blurred__sidebar');
    }
  };

  const openDateModal = () => {
    setIsDateModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const setThisYearRange = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const endOfYear = new Date(now.getFullYear(), 11, 31);
    setSelectionRange({
      startDate: startOfYear,
      endDate: endOfYear,
      key: 'selection',
    });

    const formattedDate = `${startOfYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${endOfYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;

    setSelectedDate(formattedDate);
  };

  const setLastYearRange = () => {
    const now = new Date();
    const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(now.getFullYear() - 1, 11, 31);
    setSelectionRange({
      startDate: startOfLastYear,
      endDate: endOfLastYear,
      key: 'selection',
    });

    const formattedDate = `${startOfLastYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })} - ${endOfLastYear.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })}`;

    setSelectedDate(formattedDate);
  };

  const handleNameFocus = () => {
    setIsNameFocused(true);
  };

  const handleNameBlur = () => {
    setIsNameFocused(false);
  };

  const handleSelectedStatus = async (event) => {
    const { value } = event.target;
    setSelectedStatus(value);

    const baseFilter = (data) => {
      const fullNameMatch =
        searchTerm === '' ||
        data['full_name'].toLowerCase().includes(searchTerm.toLowerCase());

      const subjectMatch =
        searchSubject === '' ||
        data['subject'].toLowerCase().includes(searchSubject.toLowerCase());

      // const statusMatch =
      //   value === '' ||
      //   data['status'].toLowerCase().includes(value.toLowerCase());

      const statusMatch =
        value === '' ||
        value.toLowerCase() === 'all' ||
        data['status'].toLowerCase().includes(value.toLowerCase());

      const monthMatch =
        !selectedDate ||
        (new Date(data.date) >= new Date(selectionRange.startDate) &&
          new Date(data.date) <= new Date(selectionRange.endDate));

      return fullNameMatch && subjectMatch && statusMatch && monthMatch;
    };

    const filtered = value === '' ? tableData : tableData.filter(baseFilter);

    setFilteredData(filtered);
  };

  const handleSpecialRequestView = async (id) => {
    history.push(`/special/view/${id}`);
  };

  const addNewRequest = async () => {
    history.push(`/special/add`);
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const beforeTable = document.createElement('div');
      beforeTable.innerHTML = '<p style="text-align: center;"></p>';
      container.appendChild(beforeTable);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      const rows = clonedTable.querySelectorAll('tr');
      rows.forEach((row) => {
        const lastCell = row.lastElementChild;
        lastCell?.remove();
      });

      container.appendChild(clonedTable);

      const afterTable = document.createElement('div');
      afterTable.innerHTML =
        '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      // Set CSS styles to avoid content overlap
      const style = document.createElement('style');
      style.innerHTML = `
            .page-break {
                page-break-before: always;
            }
            .container {
                margin-bottom: 50px; /* Ensure there is enough margin */
            }
            tr {
                page-break-inside: avoid; /* Prevent row break */
            }
        `;
      container.appendChild(style);

      // Add a page break to avoid overlap
      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'special.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            <h4 className="attendance_overview_heading">Special Requests</h4>
          </div>
          <div className="leavesIndex__header_right">
            <button
              onClick={handleDownloadPdf}
              className="downloadPDFButton downloadPDFButton1 bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Download PDF
            </button>
          </div>
        </div>

        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              <div className="filterContainer">
                {isMyTeam && (
                  <div className="remaingLeavesCounterEach inventoryFilterFields">
                    <div
                      className={`searchNameFilterParent searchNameFilterParentSpecific ${
                        isNameFocused ? 'searchNameFilterParentFocused' : ''
                      }`}
                    >
                      <FormControl variant="standard">
                        <OutlinedInput
                          onChange={handleChange}
                          value={searchTerm}
                          onFocus={handleNameFocus}
                          onBlur={handleNameBlur}
                          className="searchNameFilter searchNameFilterSpecial"
                          placeholder="Name"
                          endAdornment={
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                )}
                {/* <div className="remaingLeavesCounterEach inventoryFilterFields">
                  <div
                    className={`searchNameFilterParent searchNameFilterParentSpecific ${
                      isSubjectFocused ? 'searchNameFilterParentFocused' : ''
                    }`}
                  >
                    <FormControl variant="standard">
                      <OutlinedInput
                        onChange={handleChange}
                        name="subject"
                        value={searchSubject}
                        className="searchNameFilter searchNameFilterSpecial"
                        onFocus={handleSubjectFocus}
                        onBlur={handleSubjectBlur}
                        placeholder="Subject"
                        autoComplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </div> */}
                <div className="remaingLeavesCounterEach inventoryFilterFields">
                  <div className="singleInputFieldFilter searchNameSpecial">
                    <FormControl variant="standard">
                      <OutlinedInput
                        className="searchNameFilter"
                        placeholder="Select Date"
                        onClick={openDateModal}
                        value={selectedDate || ''}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="remaingLeavesCounterEach inventoryFilterFields">
                  <div className="singleInputField singleInputFieldFilter">
                    <select
                      name="leave_type"
                      id="leave_type"
                      className="singleInputFilter"
                      onChange={handleSelectedStatus}
                      value={selectedStatus}
                    >
                      <option value="" disabled style={{ display: 'none' }}>
                        Search By Status
                      </option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>
                <div
                  className="remaingLeavesCounterEachInventoryButton"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <button
                    onClick={addNewRequest}
                    className="downloadPDFButton downloadPDFButton2 inventoryFilterButtons"
                  >
                    Add New Request
                  </button>
                  <button
                    onClick={handleDownloadPdf}
                    className="downloadPDFButton downloadPDFButton2"
                  >
                    Download PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* for responsive view */}
          <div className="leavesIndex__header_left leavesIndex__header_left_responsive">
            <div className="remaingLeavesCounter">
              <div className="filterContainer">
                <div className="remaingLeavesCounterEach inventoryFilterFields"></div>

                <div
                  className="remaingLeavesCounterEachInventoryButton remaingLeavesCounterEachInventoryButtonSpecial"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <button
                    onClick={addNewRequest}
                    className="downloadPDFButton downloadPDFButton2 inventoryFilterButtons"
                  >
                    Add New Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              {sortedItems.length > 0 ? (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                    id="your-table-id"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ textAlign: 'center' }}
                          >
                            {column.sortable ? (
                              <TableSortLabel
                                active={sortBy === column.id}
                                direction={
                                  sortBy === column.id ? sortOrder : 'asc'
                                }
                                onClick={() => handleSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedItems.map((row, index) => {
                        const serialNumber = indexOfFirstItem + index + 1;
                        return (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {serialNumber}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['full_name']}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['subject'] === 'multiple_leave'
                                ? 'Special Leave'
                                : row['subject']}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row['date']}
                            </TableCell>

                            {row['status'] === 'approved' && (
                              <TableCell
                                style={{
                                  color: '#45a259',
                                  textAlign: 'center',
                                }}
                              >
                                {row['status']}
                              </TableCell>
                            )}
                            {row['status'] === 'rejected' && (
                              <TableCell
                                style={{
                                  color: '#d7364d',
                                  textAlign: 'center',
                                }}
                              >
                                {row['status']}
                              </TableCell>
                            )}
                            {row['status'] === 'pending' && (
                              <TableCell
                                style={{
                                  color: '#0066ff',
                                  textAlign: 'center',
                                }}
                              >
                                {row['status']}
                              </TableCell>
                            )}

                            {row['approveById'] ? (
                              <TableCell
                                style={{
                                  color: '#0066ff',
                                  textAlign: 'center',
                                }}
                              >
                                {row['approvedByUser']}
                              </TableCell>
                            ) : (
                              <TableCell
                                style={{
                                  color: '#0066ff',
                                  textAlign: 'center',
                                }}
                              >
                                Pending
                              </TableCell>
                            )}

                            <TableCell style={{ textAlign: 'center' }}>
                              <button
                                style={{
                                  border: 'none',
                                  color: '#0066ff',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  handleSpecialRequestView(row['id'])
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                >
                                  <g data-name="Group 295">
                                    <path
                                      data-name="visibility_FILL0_wght300_GRAD0_opsz24 (1)"
                                      d="M13.003 16.95a4.236 4.236 0 0 0 3.115-1.284 4.248 4.248 0 0 0 1.281-3.117 4.236 4.236 0 0 0-1.284-3.115 4.248 4.248 0 0 0-3.117-1.281 4.236 4.236 0 0 0-3.115 1.284 4.248 4.248 0 0 0-1.281 3.117 4.236 4.236 0 0 0 1.284 3.115 4.248 4.248 0 0 0 3.117 1.281zm0-1.485a2.809 2.809 0 0 1-2.063-.85 2.809 2.809 0 0 1-.85-2.063 2.809 2.809 0 0 1 .85-2.063 2.809 2.809 0 0 1 2.063-.85 2.809 2.809 0 0 1 2.063.85 2.809 2.809 0 0 1 .85 2.063 2.809 2.809 0 0 1-.85 2.063 2.809 2.809 0 0 1-2.065.85zm0 4.639a11.281 11.281 0 0 1-6.231-1.817 13.565 13.565 0 0 1-4.509-4.782 1.9 1.9 0 0 1-.2-.468 1.892 1.892 0 0 1-.062-.484 1.884 1.884 0 0 1 .062-.484 1.9 1.9 0 0 1 .2-.468 13.564 13.564 0 0 1 4.509-4.782 11.281 11.281 0 0 1 6.229-1.82 11.281 11.281 0 0 1 6.231 1.818 13.564 13.564 0 0 1 4.514 4.782 1.9 1.9 0 0 1 .2.468 1.893 1.893 0 0 1 .062.484 1.885 1.885 0 0 1-.062.484 1.9 1.9 0 0 1-.2.468 13.565 13.565 0 0 1-4.509 4.782 11.281 11.281 0 0 1-6.236 1.814zm-.002-7.552zm0 5.934a10.3 10.3 0 0 0 5.6-1.6 10.543 10.543 0 0 0 3.9-4.329 10.544 10.544 0 0 0-3.9-4.329 10.3 10.3 0 0 0-5.6-1.6 10.3 10.3 0 0 0-5.6 1.6 10.544 10.544 0 0 0-3.9 4.329 10.543 10.543 0 0 0 3.9 4.329 10.3 10.3 0 0 0 5.6 1.6z"
                                    />
                                    <path
                                      data-name="Rectangle 293"
                                      fill="none"
                                      d="M0 0h26v26H0z"
                                    />
                                  </g>
                                </svg>
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: 'center', padding: '20px' }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {sortedItems.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
        </Card>
        <DatePickerModal isOpen={isDateModalOpen} onClose={closeDateModal}>
          <div className="modal__popup">
            <div className="date-filter-container">
              <div
                className="date-pickers"
                style={{ display: 'flex', gap: '16px' }}
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                />
              </div>
              <div
                className="preset-buttons"
                style={{ margin: '16px 0', display: 'flex', gap: '8px' }}
              >
                <Button variant="outlined" onClick={setThisYearRange}>
                  This Year
                </Button>
                <Button variant="outlined" onClick={setLastYearRange}>
                  Last Year
                </Button>
              </div>
              <div
                className="action-buttons"
                style={{ marginTop: '16px', textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeDateModal}
                  style={{ marginLeft: '8px' }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </DatePickerModal>
      </div>
    </Layout>
  );
}
