import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import InventoryRequestService from '../../services/InventoryRequestService';
import UserService from '../../services/UserService';
import RejectionModal from '../RejectionModal';

export default function ViewInventory() {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const [inventoryDetails, setInventoryDetails] = useState([]);
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async (id) => {
      const inventoryData = await InventoryRequestService.findOne(id);
      setInventoryDetails(inventoryData);

      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const MyTeam = await UserService.findTeam(parsedUser.id);
      const usersArray = MyTeam.user;

      const isPermitted = usersArray.filter((users) => {
        return users['id'] === inventoryData.usersId;
      });

      if (parsedUser.id === inventoryData.usersId) {
        setIsAdmin(false);
        setIsTeamLead(false);
      }

      if (parsedUser.roleId === 2) {
        if (
          inventoryData.inventoryRequestStatus === 'approved' ||
          inventoryData.inventoryRequestStatus === 'delivered' ||
          inventoryData.inventoryRequestStatus === 'out_of_stock'
        ) {
          setIsAdmin(true);
        } else {
          history.push(`/inventory`);
        }
      } else if (isPermitted.length !== 0) {
        if (parsedUser.id !== inventoryData.usersId) {
          setIsTeamLead(true);
        } else {
          setIsTeamLead(false);
        }
      } else {
        history.push(`/inventory`);
      }
    };
    fetchUserData(id);
  }, [id]);

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentStatus('');
  };

  const openModal = async (status) => {
    setIsModalOpen(true);
    setCurrentStatus(status);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const closeRejectionModal = () => {
    setIsRejectionModalOpen(false);
    setCurrentStatus('');
  };

  const openRejectionModal = async (status) => {
    setIsRejectionModalOpen(true);
    setCurrentStatus(status);

    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');

    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const respondToRequest = async () => {
    if (inventoryDetails['inventoryRequestStatus'] !== currentStatus) {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      if (currentStatus === 'approved' || currentStatus === 'rejected') {
        const data = {
          approveById: parsedUser.id,
          status: currentStatus,
        };

        await InventoryRequestService.update(+id, data);
        const inventoryData = await InventoryRequestService.findOne(id);
        setInventoryDetails(inventoryData);
        window.location.reload();
      } else {
        const data = {
          delieverById: parsedUser.id,
          status: currentStatus,
        };

        await InventoryRequestService.update(+id, data);
        const inventoryData = await InventoryRequestService.findOne(id);
        setInventoryDetails(inventoryData);
        window.location.reload();
      }
    }
    setIsModalOpen(false);
    setCurrentStatus('');
  };

  return (
    <Layout>
      <div className="leavesIndex">
        <div
          className="leavesIndex__header leavesIndex__body_view_screen"
          style={{ paddingBottom: 0 }}
        >
          <div>
            <h4 className="attendance_overview_heading">Inventory Request</h4>
          </div>
          {isAdmin ? (
            <div style={{ display: 'flex' }}>
              <div
                className="leavesIndex__header_right"
                style={{ paddingRight: '1vw' }}
              >
                <button
                  onClick={() => openModal('delivered')}
                  className="deliverButton"
                >
                  Deliver
                </button>
                <button
                  onClick={() => openModal('out_of_stock')}
                  className="outOfStockButton"
                >
                  Out Of Stock
                </button>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              {isTeamLead && (
                <div className="leavesIndex__header_right">
                  <button
                    onClick={() => openModal('approved')}
                    className="approveButton"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => openRejectionModal('rejected')}
                    className="rejectButton "
                  >
                    Decline
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="leavesIndex__body leavesIndex__body_view_screen">
          <div className="leavesIndex__body_padding leavesIndex__body_padding_view_screen">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Name
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {inventoryDetails['full_name']}
                    </InputLabel>
                  </div>
                </div>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Status
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {inventoryDetails['inventoryRequestStatus']}
                    </InputLabel>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Request Item
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {inventoryDetails['inventoryItemName']}
                      </InputLabel>
                    </div>
                  </div>
                </div>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Items Required
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2  viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {inventoryDetails['ItemsRequired']}
                    </InputLabel>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Date
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {inventoryDetails['date']}
                    </InputLabel>
                  </div>
                </div>
                <div className="singleInputField">
                  {inventoryDetails['approvedByUser'] !== '' && (
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Approved By
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {inventoryDetails['approvedByUser']}
                      </InputLabel>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  {inventoryDetails['delieverByUser'] !== '' && (
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Deliever By
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2 viewScreenSubHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {inventoryDetails['delieverByUser']}
                      </InputLabel>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div className="singleInputFieldTextArea">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Reason For Leave
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                        value={inventoryDetails['inventoryRequestDescription']}
                        placeholder="Enter text here..."
                        rows={6}
                        cols={50}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '1vw 0',
                  }}
                >
                  {inventoryDetails['inventoryRequestDescription'] ===
                    'rejected' && (
                    <div className="singleInputField singleInputFieldTextArea">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Reason For Rejection
                      </InputLabel>
                      <FormControl variant="standard">
                        <textarea
                          className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                          value={
                            inventoryDetails['inventoryRequestDescription']
                          }
                          placeholder="Enter text here..."
                          rows={6}
                          cols={50}
                          readOnly
                        />
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>

        <RejectionModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="approvalText">
                  Are You sure you want to approve this request !
                </span>

                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submitValidationModal"
                    onClick={respondToRequest}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>
        <RejectionModal
          isOpen={isRejectionModalOpen}
          onClose={closeRejectionModal}
        >
          <div className="modal__popup">
            <div className="Portal-Login-Form">
              <div
                className="ValidationModal"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <h3 className="rejectionHeading">Decline</h3>
                <span className="rejectionText">
                  Would you like to mention any reason for Leave rejection?
                </span>
                <FormControl variant="standard">
                  <textarea
                    className={`leaveDurationTextArea leaveDurationInput`}
                    placeholder="Mention Reason here (optional)"
                    rows={6}
                    cols={50}
                    readOnly
                  />
                </FormControl>
                <div className="CloseRejectionModalButton">
                  <button
                    type="button"
                    className="CloseRejectionModal"
                    onClick={closeRejectionModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submitValidationModal"
                    onClick={respondToRequest}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </RejectionModal>
      </div>
    </Layout>
  );
}
