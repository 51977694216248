import Attendance from '../models/attendance/Attendance';
import apiService from './ApiService';

export class AttendanceService {
  async addMissedAttendance(data) {
    await apiService.put(`/api/attendance/addMissedAttendance`, data);
  }
  async findAll(data) {
    const data2 = await apiService.post('/api/attendance/getAttendance', data);
    return data2;
  }
  async wfmAttendance(data) {
    await apiService.post(`/api/attendance/wfmAttendance`, data);
  }
  async customAttendance(data) {
    const response = await apiService.post('/api/attendance/custom', data);
    const responseData = response.data;
    return responseData;
  }

  async findOne(id: string) {
    return (await apiService.get(`/api/attendance/${id}`)).data;
  }

  async getSpecificAttendance(id: string): Promise<Attendance[]> {
    return (
      await apiService.get<Attendance[]>(
        `/api/attendance/getSpecificAttendance/${id}`,
      )
    ).data;
  }
  async check(id: string, userId: string): Promise<Attendance[]> {
    return (
      await apiService.get<Attendance[]>(
        `/api/attendance/check/${id}/${userId}`,
      )
    ).data;
  }

  async edit(userIdAttendance: string, data) {
    return await apiService.post(
      `/api/attendance/edit/${userIdAttendance}`,
      data,
    );
  }

  async filterFromView(allFilters) {
    try {
      console.log(allFilters);
      const response = await apiService.post(`/api/attendance/filterFromView`, {
        allFilters: allFilters || undefined,
      });

      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async filterFromList(allFilters) {
    try {
      const response = await apiService.post(`/api/attendance/filterFromList`, {
        allFilters: allFilters || undefined,
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  }
}

const attendanceService = new AttendanceService();
export { attendanceService as default };
