import Announcement from '../models/announcement/Announcement';
import UpdateAnnouncementRequest from '../models/announcement/UpdateAnnouncementRequest';
import apiService from './ApiService';

export class AnnouncementService {
  async update(id: number, data): Promise<void> {
    await apiService.put(`/api/announcement/update/${id}`, data);
  }

  async findAll(): Promise<Announcement[]> {
    return (await apiService.get<Announcement[]>('/api/announcement', {})).data;
  }

  async findAllAnnouncement(): Promise<Announcement[]> {
    return (
      await apiService.get<Announcement[]>(
        '/api/announcement/findAllAnnouncement',
        {},
      )
    ).data;
  }

  async filter(data) {
    const data2 = await apiService.post('/api/announcement/filter', data);
    return data2.data;
  }

  async save(data): Promise<void> {
    await apiService.post('/api/announcement', data);
  }

  async findOne(id: string) {
    return (await apiService.get(`/api/announcement/${id}`)).data;
  }
  async delete(id: string): Promise<void> {
    await apiService.put(`/api/announcement/delete/${id}`);
  }
}

const announcementService = new AnnouncementService();
export { announcementService as default };
