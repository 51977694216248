import 'react-toastify/dist/ReactToastify.css';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Layout from '../../components/layout';
import UserService from '../../services/UserService';
export default function ChangePassword() {
  const history = useHistory();
  const { handleSubmit } = useForm();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (record) => {
    try {
      setIsSubmitting(true);
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      const data = {
        password: password,
        cPassword: newPassword,
      };
      const asd = await UserService.matchPassword(parsedUser.id, data);
      if (asd === "old password doesn't match") {
        setIsSubmitting(false);

        alert("old password doesn't match");
        return;
      }
      toast.success('Form submitted successfully!', {
        autoClose: 3000,
        onClose: () => {
          history.push('/');
        },
      });
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'nPassword') {
      setNewPassword(value);
    }
  };

  return (
    <Layout>
      <h1 className="font-semibold text-3xl mb-5 text-custom">New User</h1>
      <hr />
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        noValidate
      >
        <div className="flex flex-grow flex-col lg:flex-row ">
          <div className="card shadow w-full mt-10 mr-3">
            <p className="text-red-500 text-center">
              If you want to change your password then enter your old and new
              password!
            </p>

            <div className="space-y-12">
              <div className=" pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-12">
                    <div className="mt-2 flex items-center">
                      <label
                        htmlFor="passwordInput"
                        className="inline-block w-3/12 text-sm font-medium leading-6 text-custom mr-2 text-right"
                      >
                        Old Password :
                      </label>
                      <input
                        type="password"
                        name="password"
                        className={`block w-8/12 rounded-md border py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 sm:text-sm sm:leading-6`}
                        placeholder="Enter Old Password"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mt-2 flex items-center">
                      <label
                        htmlFor="nPasswordInput"
                        className="inline-block text-sm font-medium leading-6 text-custom mr-2 w-3/12 text-right"
                      >
                        New Password :
                      </label>
                      <input
                        type="password"
                        name="nPassword"
                        className={`block w-8/12 rounded-md border py-1.5 text-gray-900 shadow-sm  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 sm:text-sm sm:leading-6`}
                        placeholder="Enter New Password"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center p-5">
          <button
            className="bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded lg:w-3/12"
            type="submit"
          >
            {isSubmitting ? 'Submitting...' : 'Change'}
          </button>
          <ToastContainer />
        </div>
      </form>
    </Layout>
  );
}
