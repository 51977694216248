import User from '../models/user/User';
import apiService from './ApiService';

export class UserService {
  async findAll(): Promise<User[]> {
    return (await apiService.get<User[]>('/api/users', {})).data;
  }

  async findOne(id: string): Promise<User> {
    return (await apiService.get<User>(`/api/users/${id}`)).data;
  }
  async findTeam(id: string) {
    return (await apiService.get(`/api/users/team/${id}`)).data;
  }

  async getImage(): Promise<void> {
    await apiService.get(`/api/users/filename`);
  }

  async matchPassword(id, data): Promise<string> {
    const response = await apiService.post(
      `/api/users/matchPassword/${id}`,
      data,
    );
    return response.data;
  }
}

const userService = new UserService();
export { userService as default };
