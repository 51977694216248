import Leave from '../models/leave/Leave';
import apiService from './ApiService';

export class LeaveService {
  async update(id: number, data) {
    await apiService.put(`/api/leave/update/${id}`, data);
  }

  async findAll(): Promise<Leave[]> {
    return (await apiService.get<Leave[]>('/api/leave', {})).data;
  }

  async findAllOfMine(id: string): Promise<Leave[]> {
    return (await apiService.get<Leave[]>(`/api/leave/mine/${id}`)).data;
  }

  async numberOfMyLeaves(id: string) {
    return (await apiService.get(`/api/leave/numberOfMyLeaves/${id}`)).data;
  }

  async getPendingLeaves(id: string): Promise<Leave[]> {
    return (await apiService.get<Leave[]>(`/api/leave/getPendingLeaves/${id}`))
      .data;
  }

  async findMonthLeaves(month: any, id: string) {
    return (await apiService.get(`/api/leave/month/${month}/${id}`)).data;
  }

  async save(data): Promise<void> {
    await apiService.post('/api/leave', data);
  }

  async findOne(id: string): Promise<Leave> {
    return (await apiService.get<Leave>(`/api/leave/${id}`)).data;
  }

  async findRemaingLeaves(id: string) {
    const data2 = await apiService.get(`/api/leave/findRemaingLeaves/${id}`);
    return data2.data;
  }

  async filterMyLeavesOfMonth(data) {
    const data2 = await apiService.post(
      '/api/leave/filterMyLeavesOfMonth',
      data,
    );
    return data2.data;
  }

  async leaveOnCurrentDate(data) {
    const data2 = await apiService.post('/api/leave/leaveOnCurrentDate', data);
    return data2.data;
  }

  async delete(id: string): Promise<void> {
    await apiService.put(`/api/leave/delete/${id}`);
  }
}

const leaveService = new LeaveService();
export { leaveService as default };
