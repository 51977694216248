import React from 'react';

const ValidationModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="bootstrap__modal">
      <div className="bootstrap__validation__modal__parent">
        <div className="bootstrap__modal__content">
          <div className="bootstrap__modal__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ValidationModal;
