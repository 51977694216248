import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import html2pdf from 'html2pdf.js';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useHistory, useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import AttendanceService from '../../services/AttendanceService';
import UserService from '../../services/UserService';
import DatePickerModal from '../DatePickerModal';

const columns = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'checkInTime',
    label: 'Check In',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'checkOutTime',
    label: 'Check Out',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'late_minutes',
    label: 'Late Minutes',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'extra_minutes',
    label: 'Extra Minutes',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'total_hours',
    label: 'Total Hours',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'punchById',
    label: 'Marked By',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    sortable: false,
  },
];

export default function ViewAttendance() {
  const toggleContainer = useRef<HTMLDivElement | null>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  const [isTeamLead, setIsTeamLead] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [officeCity, setOfficeCity] = useState('');
  const [userName, setUserName] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.id),
  );

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = React.useState(0);
  const currentPage = 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const range = [];
  const minRange = Math.max(1, currentPage - 2);
  const maxRange = Math.min(totalPages, currentPage + 2);
  for (let i = minRange; i <= maxRange; i++) {
    range.push(i);
  }

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toggleContainer.current &&
      !toggleContainer.current.contains(event.target as Node)
    ) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchAttendance = async () => {
      const staticStartDate = localStorage.getItem('staticStartDateAttendance');
      const startDates = JSON.parse(staticStartDate);
      const startDate = new Date(startDates);

      const staticEndDate = localStorage.getItem('staticEndDateAttendance');
      const endDates = JSON.parse(staticEndDate);
      const endDate = new Date(endDates);

      const selectionRanges = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      };

      setSelectionRange(selectionRanges);

      const isSameDate =
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();

      let formattedDate = '';

      if (isSameDate) {
        formattedDate = startDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else {
        const sameMonth = startDate.getMonth() === endDate.getMonth();
        const sameYear = startDate.getFullYear() === endDate.getFullYear();

        if (sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else if (!sameMonth && sameYear) {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        } else {
          formattedDate = `${startDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
          })} - ${endDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`;
        }
      }

      setSelectedDate(formattedDate);

      const formatToLocalDate = (date) => {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format: "YYYY-MM-DD"
      };

      const allFilters = {
        dateFilter: 'date_range',
        attendanceTime: '',
        loggedInUserId: id,
        startDate: formatToLocalDate(startDates),
        endDate: formatToLocalDate(endDates),
        dateRange: null,
      };

      const resultData = await AttendanceService.filterFromView(allFilters);
      setUserName(resultData[0]?.full_name || '');
      setFilteredData(resultData);
    };
    const check = async (usersId) => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const check = await AttendanceService.check(id, usersId);
      if (parsedUser.roleId === 4 || parsedUser.roleId === 1) {
        fetchAttendance();
      } else {
        if (check) {
          fetchAttendance();
        } else {
          history.push(`/attendance`);
        }
      }
    };
    const teamLead = async (usersId) => {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const MyTeam = await UserService.findTeam(usersId);
      const usersArray = MyTeam.user;

      const isPermitted = usersArray.filter((users) => {
        return users['id'] === usersId;
      });

      if (parsedUser.id === usersId) {
        setIsTeamLead(false);
      }

      if (isPermitted.length !== 0) {
        if (parsedUser.id !== usersId) {
          setIsTeamLead(true);
        } else {
          setIsTeamLead(false);
        }
      } else {
        history.push(`/attendance`);
      }
    };

    const storedUser = localStorage.getItem('user');
    const parsedUser = JSON.parse(storedUser);

    const getUser = async (usersId) => {
      const userData = await UserService.findOne(id);
      setOfficeCity(userData['user'][0].office_city);
    };

    teamLead(parseInt(id));
    getUser(id);
    check(parsedUser.id);
  }, [id]);

  const handleSort = (columnId: string) => {
    if (columnId === 'sr_no' || columnId === 'details') return;
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortBy === 'sr_no' || sortBy === 'details') return 0;

    if (sortBy === 'date') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return sortOrder === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    }
    if (sortBy === 'checkInTime') {
      const dateA = new Date(a.recordTime.check_in);
      const dateB = new Date(b.recordTime.check_in);

      return sortOrder === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    }

    if (sortBy === 'checkOutTime') {
      const dateA = new Date(a.recordTime.check_out);
      const dateB = new Date(b.recordTime.check_out);

      return sortOrder === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    }

    if (sortBy === 'late_minutes') {
      const lateMinutesA = Number(a.recordTime.late_minutes);
      const lateMinutesB = Number(b.recordTime.late_minutes);

      return sortOrder === 'asc'
        ? lateMinutesA - lateMinutesB
        : lateMinutesB - lateMinutesA;
    }

    if (sortBy === 'extra_minutes') {
      const extraMinutesA = Number(a.recordTime.extra_minutes);
      const extraMinutesB = Number(b.recordTime.extra_minutes);

      return sortOrder === 'asc'
        ? extraMinutesA - extraMinutesB
        : extraMinutesB - extraMinutesA;
    }

    if (sortBy === 'total_hours') {
      const totalHourssA = Number(a.recordTime.total_hours);
      const totalHoursB = Number(b.recordTime.total_hours);

      return sortOrder === 'asc'
        ? totalHourssA - totalHoursB
        : totalHoursB - totalHourssA;
    }

    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  const handleApply = async () => {
    const startDates = selectionRange.startDate;
    const endDates = selectionRange.endDate;
    const isSameDate =
      startDates.getDate() === endDates.getDate() &&
      startDates.getMonth() === endDates.getMonth() &&
      startDates.getFullYear() === endDates.getFullYear();

    let formattedDate = '';

    if (isSameDate) {
      formattedDate = startDates.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } else {
      const sameMonth = startDates.getMonth() === endDates.getMonth();
      const sameYear = startDates.getFullYear() === endDates.getFullYear();

      if (sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else if (!sameMonth && sameYear) {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      } else {
        formattedDate = `${startDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
        })} - ${endDates.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}`;
      }
    }

    setSelectedDate(formattedDate);

    localStorage.setItem(
      'staticStartDateAttendance',
      JSON.stringify(startDates),
    );
    localStorage.setItem('staticEndDateAttendance', JSON.stringify(endDates));

    const formatToLocalDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const allFilters = {
      dateFilter: 'date_range',
      attendanceTime: '',
      loggedInUserId: id,
      startDate: formatToLocalDate(selectionRange.startDate),
      endDate: formatToLocalDate(selectionRange.endDate),
      dateRange: null,
    };

    const resultData = await AttendanceService.filterFromView(allFilters);
    if (resultData.length > 0) {
      setUserName(resultData[0]['full_name']);
      setFilteredData(resultData);
    } else {
      setFilteredData([]);
    }
    closeModal();
  };

  const handleSelect = async (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleColumnChange = (event) => {
    setSelectedColumns(event.target.value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.remove('blurred__sidebar');
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const handleAttendanceEdit = async (usersId, checkInId, checkOutId, date) => {
    history.push({
      pathname: `/attendance/edit/${usersId}/${checkInId}/${checkOutId}`,
      state: { date },
    });
  };

  function handleDownloadPdf() {
    const table = document.getElementById('your-table-id');

    if (table) {
      const container = document.createElement('div');

      const header = document.createElement('div');
      header.innerHTML =
        '<img src="/logo.png" style="display: block; margin: 0 auto;"></img>';
      container.appendChild(header);

      const heading = document.createElement('p');
      heading.className = 'attendance_overview_heading';
      heading.textContent = `${userName}'s Attendance`;
      heading.style.textAlign = 'center';
      heading.style.fontSize = '16px';
      heading.style.fontWeight = 'bold';
      heading.style.marginTop = '10px';
      heading.style.marginBottom = '20px';
      container.appendChild(heading);

      const clonedTable = table.cloneNode(true) as HTMLElement;

      function handleActionColumn(table: HTMLElement) {
        const thead = table.querySelector('thead');
        const tbody = table.querySelector('tbody');

        let actionColumnPresent = false;

        if (thead) {
          const headerRows = thead.querySelectorAll('tr');

          headerRows.forEach((headerRow) => {
            const headerCells = Array.from(headerRow.querySelectorAll('th'));

            const actionColumnIndex = headerCells.findIndex(
              (cell) => cell.textContent?.trim() === 'Action',
            );

            if (actionColumnIndex > -1) {
              actionColumnPresent = true;
              headerRow.removeChild(headerRow.children[actionColumnIndex]);
            }
          });
        }

        if (tbody && actionColumnPresent) {
          const bodyRows = tbody.querySelectorAll('tr');

          bodyRows.forEach((row) => {
            const cells = Array.from(row.querySelectorAll('td'));

            const actionColumnIndex = cells.length - 1;

            if (cells[actionColumnIndex]) {
              row.removeChild(cells[actionColumnIndex]);
            }
          });
        }
      }

      handleActionColumn(clonedTable);

      container.appendChild(clonedTable);

      // const afterTable = document.createElement('div');
      // afterTable.innerHTML =
      //   '<div style="margin-top:5%;display:flex;flex-direction:row;justify-content:space-around;border-top:1px solid black"><div style="width:30%"><p style="color:#444444">2nd Floor, 38 Central Commercial Plaza, Bahria Orchard, Lahore</p></div><div><p style="color:#444444">ceo@ainsofttech.com</p></div><div><p style="color:#444444">+92 301 8424 988</p></div></div>';
      // container.appendChild(afterTable);

      const footer = document.createElement('div');
      footer.innerHTML = '<p style="height:20px"><br></p>';
      container.appendChild(footer);

      const style = document.createElement('style');
      style.innerHTML = `
                  .page-break {
                      page-break-before: always;
                  }
                  .container {
                      margin-bottom: 50px; /* Ensure there is enough margin */
                  }
                  tr {
                      page-break-inside: avoid; /* Prevent row break */
                  }
              `;
      container.appendChild(style);

      const pageBreak = document.createElement('div');
      pageBreak.className = 'page-break';
      container.appendChild(pageBreak);

      const htmlString = container.innerHTML;

      html2pdf()
        .from(htmlString)
        .set({
          margin: 10,
          filename: 'attendance.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        })
        .save();
    }
  }

  const staticRanges = [
    {
      label: 'Today',
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        const today = new Date();
        return (
          range.startDate.toDateString() === today.toDateString() &&
          range.endDate.toDateString() === today.toDateString()
        );
      },
      key: 'today',
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      }),
      isSelected: (range) => {
        const yesterday = new Date(
          new Date().setDate(new Date().getDate() - 1),
        );
        return (
          range.startDate.toDateString() === yesterday.toDateString() &&
          range.endDate.toDateString() === yesterday.toDateString()
        );
      },
      key: 'yesterday',
    },
    {
      label: 'This Week',
      range: () => ({
        startDate: new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay()),
        ),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        const startOfWeek = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay()),
        );
        return (
          range.startDate.toDateString() === startOfWeek.toDateString() &&
          range.endDate.toDateString() === new Date().toDateString()
        );
      },
      key: 'thisWeek',
    },
    {
      label: 'Last Week',
      range: () => ({
        startDate: new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 7),
        ),
        endDate: new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 1),
        ),
      }),
      isSelected: (range) => {
        const startOfLastWeek = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() - 7),
        );
        return (
          range.startDate.toDateString() === startOfLastWeek.toDateString() &&
          range.endDate.toDateString() ===
            new Date(
              new Date().setDate(
                new Date().getDate() - new Date().getDay() - 1,
              ),
            ).toDateString()
        );
      },
      key: 'lastWeek',
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      }),
      isSelected: (range) => {
        const startOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1,
        );
        return (
          range.startDate.toDateString() === startOfMonth.toDateString() &&
          range.endDate.toDateString() === new Date().toDateString()
        );
      },
      key: 'thisMonth',
    },
    {
      label: 'Last Month',
      range: () => ({
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1,
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      }),
      isSelected: (range) => {
        const startOfLastMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1,
        );
        return (
          range.startDate.toDateString() === startOfLastMonth.toDateString() &&
          range.endDate.toDateString() ===
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0,
            ).toDateString()
        );
      },
      key: 'lastMonth',
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
      }),
      isSelected: (range) => {
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);
        return (
          range.startDate.toDateString() === startOfYear.toDateString() &&
          range.endDate.toDateString() ===
            new Date(new Date().getFullYear(), 11, 31).toDateString()
        );
      },
      key: 'thisYear',
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: new Date(new Date().getFullYear() - 1, 0, 1),
        endDate: new Date(new Date().getFullYear() - 1, 11, 31),
      }),
      isSelected: (range) => {
        const startOfLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
        return (
          range.startDate.toDateString() === startOfLastYear.toDateString() &&
          range.endDate.toDateString() ===
            new Date(new Date().getFullYear() - 1, 11, 31).toDateString()
        );
      },
      key: 'lastYear',
    },
  ];

  return (
    <Layout>
      <div className="leavesIndex">
        <div className="leavesIndex__header" style={{ paddingBottom: 0 }}>
          <div className="leavesIndex__header_left">
            <h4 className="attendance_overview_heading">
              {userName}'s Attendance
            </h4>
          </div>
        </div>

        <div className="leavesIndex__header">
          <div className="leavesIndex__header_left">
            <div className="remaingLeavesCounter">
              <div className="singleInputFieldSelectedColumns">
                <div className="searchNameFilterParent singleInputFieldFilters">
                  <FormControl variant="outlined">
                    <Select
                      multiple
                      value={selectedColumns}
                      onChange={handleColumnChange}
                      renderValue={() => 'Selected Columns'}
                      input={<OutlinedInput />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {columns
                        .filter((column) => column.id !== 'date')
                        .map((column) => (
                          <MenuItem key={column.id} value={column.id}>
                            <Checkbox
                              checked={selectedColumns.indexOf(column.id) > -1}
                            />
                            <ListItemText primary={column.label} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="singleInputFieldFilters">
                <FormControl variant="standard">
                  <OutlinedInput
                    className="searchNameFilter"
                    placeholder="Select Date"
                    onClick={openModal}
                    value={selectedDate || ''}
                    readOnly
                  />
                </FormControl>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <button
                  onClick={handleDownloadPdf}
                  className="downloadPDFButton downloadPDFButton2 bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card className="myLeaves__card" variant="outlined">
          <div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              {currentItems.length > 0 ? (
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                    id="your-table-id"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => {
                          const shouldDisplay =
                            selectedColumns.includes(column.id) &&
                            (isTeamLead || column.id !== 'Action');

                          return shouldDisplay ? (
                            <TableCell
                              key={column.id}
                              style={{ textAlign: 'center' }}
                            >
                              {column.sortable ? (
                                <TableSortLabel
                                  active={sortBy === column.id}
                                  direction={
                                    sortBy === column.id ? sortOrder : 'asc'
                                  }
                                  onClick={() => handleSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </TableCell>
                          ) : null;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedItems.map((row, index) => (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          {/* DATE */}
                          {!row['office_location'] ||
                          officeCity === row['office_location'] ? (
                            <TableCell
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {row['date']}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                position: 'relative',
                                textAlign: 'center',
                                flexDirection: 'column-reverse',
                              }}
                            >
                              <span
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                {row['date']}
                              </span>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                {row['office_location'] === 'islamabad' && (
                                  <span
                                    style={{
                                      backgroundColor: 'rgb(255, 206, 30)',
                                      fontSize: '10px',
                                      padding: '2px 3px',
                                      borderRadius: '8px',
                                      display: 'inline-block',
                                      textAlign: 'right',
                                    }}
                                  >
                                    ISB
                                  </span>
                                )}
                                {row['office_location'] === 'lahore' && (
                                  <span
                                    style={{
                                      backgroundColor: 'rgb(255, 206, 30)',
                                      fontSize: '10px',
                                      padding: '2px 3px',
                                      borderRadius: '8px',
                                      display: 'inline-block',
                                      textAlign: 'right',
                                    }}
                                  >
                                    LHR
                                  </span>
                                )}
                                {row['office_location'] === 'custom' && (
                                  <span
                                    style={{
                                      backgroundColor: 'rgb(255, 206, 30)',
                                      fontSize: '10px',
                                      padding: '2px 3px',
                                      borderRadius: '8px',
                                      display: 'inline-block',
                                      textAlign: 'right',
                                    }}
                                  >
                                    Custom
                                  </span>
                                )}
                                {row['office_location'] ===
                                  'work_from_home' && (
                                  <span
                                    style={{
                                      backgroundColor: 'rgb(255, 206, 30)',
                                      fontSize: '10px',
                                      padding: '2px 3px',
                                      borderRadius: '8px',
                                      display: 'inline-block',
                                      textAlign: 'right',
                                    }}
                                  >
                                    WFH
                                  </span>
                                )}
                              </div>
                            </TableCell>
                          )}
                          {/* Check IN */}
                          {selectedColumns.includes('checkInTime') &&
                            row.leaveToday === 'first_half' && (
                              <TableCell>
                                <div
                                  style={{
                                    display: 'flex',
                                    position: 'relative',
                                    textAlign: 'center',
                                    flexDirection: 'column-reverse',
                                  }}
                                >
                                  <span>
                                    {row.recordTime.checkInTime ? (
                                      row.recordTime.checkInTime
                                    ) : (
                                      <span style={{ color: 'red' }}>
                                        Pending
                                      </span>
                                    )}
                                  </span>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <a
                                      style={{ cursor: 'pointer' }}
                                      href={`/MyLeaves/view/${row.leaveId}`}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(255, 206, 30)',
                                          fontSize: '10px',
                                          padding: '2px 3px',
                                          borderRadius: '8px',
                                          display: 'inline-block',
                                        }}
                                      >
                                        F.H
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </TableCell>
                            )}
                          {selectedColumns.includes('checkInTime') &&
                            row.leaveToday === 'short_leave' && (
                              <TableCell
                                style={{
                                  display: 'flex',
                                  position: 'relative',
                                  textAlign: 'center',
                                  flexDirection: 'column-reverse',
                                }}
                              >
                                <span>
                                  {row.recordTime.checkInTime ? (
                                    row.recordTime.checkInTime
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      Pending
                                    </span>
                                  )}
                                </span>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    href={`/MyLeaves/view/${row.leaveId}`}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(255, 206, 30)',
                                        fontSize: '10px',
                                        padding: '2px 3px',
                                        borderRadius: '8px',
                                        display: 'inline-block',
                                      }}
                                    >
                                      S.L
                                    </span>
                                  </a>
                                </div>
                              </TableCell>
                            )}
                          {selectedColumns.includes('checkInTime') &&
                            row.leaveToday !== 'first_half' &&
                            row.leaveToday !== 'short_leave' && (
                              <TableCell
                                style={{
                                  textAlign: 'center',
                                  color:
                                    row['CheckToday'] === 'Absent'
                                      ? 'red'
                                      : 'inherit',
                                }}
                              >
                                {row['CheckToday'] ? (
                                  row['CheckToday'] === 'Absent' ? (
                                    '-'
                                  ) : (
                                    '-'
                                  )
                                ) : (
                                  <div
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    {row.recordTime.checkInTime ? (
                                      row.recordTime.checkInTime
                                    ) : (
                                      <span style={{ color: 'red' }}>
                                        Pending
                                      </span>
                                    )}
                                  </div>
                                )}
                              </TableCell>
                            )}
                          {/* Check OUT */}
                          {selectedColumns.includes('checkOutTime') &&
                            row.leaveToday === 'second_half' && (
                              <TableCell
                                style={{
                                  display: 'flex',
                                  position: 'relative',
                                  textAlign: 'center',
                                  flexDirection: 'column-reverse',
                                }}
                              >
                                <span>
                                  {row.recordTime.checkOutTime ? (
                                    row.recordTime.checkOutTime
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      Pending
                                    </span>
                                  )}
                                </span>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    href={`/MyLeaves/view/${row.leaveId}`}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: 'rgb(255, 206, 30)',
                                        fontSize: '10px',
                                        padding: '2px 3px',
                                        borderRadius: '8px',
                                        display: 'inline-block',
                                      }}
                                    >
                                      S.H
                                    </span>
                                  </a>
                                </div>
                              </TableCell>
                            )}
                          {selectedColumns.includes('checkOutTime') &&
                            row.leaveToday !== 'second_half' && (
                              <TableCell
                                style={{
                                  textAlign: 'center',
                                  color:
                                    row['CheckToday'] === 'Absent'
                                      ? 'red'
                                      : 'inherit',
                                }}
                              >
                                {row['CheckToday'] ? (
                                  row['CheckToday'] === 'Absent' ? (
                                    '-'
                                  ) : (
                                    '-'
                                  )
                                ) : (
                                  <div>
                                    {row.recordTime.checkOutTime ? (
                                      row.recordTime.checkOutTime
                                    ) : (
                                      <span style={{ color: 'red' }}>
                                        Pending
                                      </span>
                                    )}
                                  </div>
                                )}
                              </TableCell>
                            )}

                          {selectedColumns.includes('late_minutes') && (
                            <TableCell
                              style={{
                                textAlign: 'center',
                                color:
                                  row['CheckToday'] === 'Absent'
                                    ? 'red'
                                    : 'inherit',
                              }}
                            >
                              {row['CheckToday'] ? (
                                row['CheckToday'] === 'Absent' ? (
                                  '-'
                                ) : (
                                  '-'
                                )
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {row.recordTime.checkInTime ? (
                                    row.recordTime.late_minutes
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      Calculating
                                    </span>
                                  )}
                                </div>
                              )}
                            </TableCell>
                          )}
                          {/* <a
                                    style={{ cursor: 'pointer' }}
                                    href={`/MyLeaves/view/${row.leaveId}`}
                                  ></a> */}
                          {selectedColumns.includes('extra_minutes') && (
                            <TableCell
                              style={{
                                textAlign: 'center',
                                color:
                                  row['CheckToday'] === 'Absent' ||
                                  row['CheckToday'] === 'Rejected Leave'
                                    ? 'red'
                                    : 'inherit',
                              }}
                            >
                              {row['CheckToday'] ? (
                                row['CheckToday'] === 'Absent' ? (
                                  'Absent'
                                ) : row.leaveId ? (
                                  <a
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'none',
                                      // color: '#15267d',
                                      color:
                                        row['CheckToday'] === 'Absent' ||
                                        row['CheckToday'] === 'Rejected Leave'
                                          ? 'red'
                                          : '#15267d',
                                    }}
                                    href={`/MyLeaves/view/${row.leaveId}`}
                                  >
                                    <span>{row['CheckToday']}</span>
                                  </a>
                                ) : (
                                  <span>{row['CheckToday']}</span>
                                )
                              ) : (
                                // row['CheckToday']
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {row.recordTime.checkOutTime ? (
                                    row.recordTime.extra_minutes
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      Calculating
                                    </span>
                                  )}
                                </div>
                              )}
                            </TableCell>
                          )}

                          {selectedColumns.includes('total_hours') && (
                            <TableCell
                              style={{
                                textAlign: 'center',
                                color:
                                  row['CheckToday'] === 'Absent'
                                    ? 'red'
                                    : 'inherit',
                              }}
                            >
                              {row['CheckToday'] ? (
                                row['CheckToday'] === 'Absent' ? (
                                  '-'
                                ) : (
                                  '-'
                                )
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {row.recordTime.checkOutTime ? (
                                    row.recordTime.total_hours
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      Calculating
                                    </span>
                                  )}
                                </div>
                              )}
                            </TableCell>
                          )}

                          {selectedColumns.includes('punchById') && (
                            <TableCell
                              style={{
                                textAlign: 'center',
                                color:
                                  row['CheckToday'] === 'Absent'
                                    ? 'red'
                                    : 'inherit',
                              }}
                            >
                              {row.recordTime.checkOutTime ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {row['usersId'] ===
                                    row.recordTime.checkOutPunchByUserId &&
                                  row['usersId'] ===
                                    row.recordTime.checkinPunchByUserId ? (
                                    userName
                                  ) : row['usersId'] !==
                                    row.recordTime.checkOutPunchByUserId ? (
                                    <span style={{ color: 'red' }}>
                                      {row.recordTime.checkOutPunchByUser}
                                    </span>
                                  ) : (
                                    <span>
                                      {row.recordTime.checkInPunchByUser}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <span style={{ color: 'red' }}>-</span>
                                </div>
                              )}
                            </TableCell>
                          )}
                          {selectedColumns.includes('Action') && isTeamLead && (
                            <TableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                {row['CheckToday'] &&
                                  row['CheckToday'] !== 'Absent' && <div></div>}
                                {!row['CheckToday'] &&
                                  row['CheckToday'] !== 'Absent' && (
                                    <button
                                      style={{
                                        border: 'none',
                                        color: '#45a259',
                                        backgroundColor: 'transparent',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleAttendanceEdit(
                                          row['usersId'],
                                          row.recordTime.checkInId,
                                          row.recordTime.checkOutId,
                                          row['date'],
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                      >
                                        <g data-name="Group 303">
                                          <path d="M4.099 24a2.027 2.027 0 0 1-1.49-.61 2.027 2.027 0 0 1-.61-1.49V6.352a2.027 2.027 0 0 1 .61-1.49 2.027 2.027 0 0 1 1.49-.61h7.96a.785.785 0 0 1 .653.274.939.939 0 0 1 .218.6.917.917 0 0 1-.227.6.811.811 0 0 1-.662.274H4.099a.342.342 0 0 0-.246.112.342.342 0 0 0-.112.246v15.548a.342.342 0 0 0 .112.246.342.342 0 0 0 .246.112h15.548a.342.342 0 0 0 .246-.112.342.342 0 0 0 .112-.246V13.89a.785.785 0 0 1 .274-.653.939.939 0 0 1 .6-.218.939.939 0 0 1 .6.218.785.785 0 0 1 .274.653v8.015a2.027 2.027 0 0 1-.61 1.49 2.027 2.027 0 0 1-1.49.61zm7.775-9.874zm-2.9 1.854v-2.069a2.062 2.062 0 0 1 .163-.808 2.084 2.084 0 0 1 .449-.674l9.912-9.912a1.7 1.7 0 0 1 .594-.394 1.83 1.83 0 0 1 .659-.124 1.778 1.778 0 0 1 .657.124 1.678 1.678 0 0 1 .574.383l1.459 1.454a1.98 1.98 0 0 1 .388.6 1.713 1.713 0 0 1 .135.661 1.881 1.881 0 0 1-.115.65 1.609 1.609 0 0 1-.385.585l-9.946 9.946a2.209 2.209 0 0 1-.674.458 1.97 1.97 0 0 1-.808.172h-2.013a1.017 1.017 0 0 1-.749-.3 1.017 1.017 0 0 1-.304-.752zM22.239 5.23 20.78 3.738zM10.716 15.287h1.448l7.24-7.24-.724-.724-.775-.746-7.189 7.189zm7.964-7.964-.775-.746.775.746.724.724z" />
                                          <path
                                            data-name="Rectangle 294"
                                            fill="none"
                                            d="M0 0h26v26H0z"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                  )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                // If no records found
                <TableContainer>
                  <Table
                    className="MyLeavesTable"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ textAlign: 'center', padding: '20px' }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {currentItems.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
        </Card>
        <DatePickerModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="modal__popup">
            <div className="date-filter-container">
              <div
                className="date-pickers"
                style={{ display: 'flex', gap: '16px' }}
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  staticRanges={staticRanges}
                  onChange={handleSelect}
                />
              </div>
              <div
                className="action-buttons"
                style={{ marginTop: '16px', textAlign: 'right' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeModal}
                  style={{ marginLeft: '8px' }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </DatePickerModal>
      </div>
    </Layout>
  );
}
