import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout';
import SpecialRequestService from '../../services/specialRequestService';
import UserService from '../../services/UserService';

export default function ViewSpecialRequest() {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const [specialRequestDetails, setSpecialRequestDetails] = useState([]);
  const [isTeamLead, setIsTeamLead] = useState(false);

  useEffect(() => {
    const fetchUserData = async (id) => {
      const specialRequestData = await SpecialRequestService.findOne(id);
      setSpecialRequestDetails(specialRequestData[0]);

      const MyTeam = await UserService.findTeam(specialRequestData[0].usersId);
      const usersArray = MyTeam.user;

      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      const isPermitted = usersArray.filter((users) => {
        return users['id'] === specialRequestData[0].usersId;
      });

      if (parsedUser.id === specialRequestData[0].usersId) {
        setIsTeamLead(false);
      }

      if (isPermitted.length !== 0) {
        if (parsedUser.id !== specialRequestData[0].usersId) {
          setIsTeamLead(true);
        } else {
          setIsTeamLead(false);
        }
      } else {
        history.push(`/special`);
      }
    };
    fetchUserData(id);
  }, [id]);

  const respondToRequest = async (status) => {
    if (specialRequestDetails['status'] !== status) {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);
      const data = {
        approveById: parsedUser.id,
        status: status,
      };

      const record = SpecialRequestService.update(+id, data);
      const specialRequestData = await SpecialRequestService.findOne(id);
      setSpecialRequestDetails(specialRequestData[0]);
      window.location.reload();
    }
  };
  return (
    <Layout>
      <div className="leavesIndex">
        <div
          className="leavesIndex__header leavesIndex__body_view_screen"
          style={{ paddingBottom: 0 }}
        >
          <div>
            <h4 className="attendance_overview_heading">Special Request</h4>
          </div>

          <div style={{ display: 'flex' }}>
            {isTeamLead && (
              <div
                className="leavesIndex__header_right"
                style={{ paddingRight: '1vw' }}
              >
                <button
                  onClick={() => respondToRequest('approved')}
                  className="approveButton"
                >
                  Approve
                </button>
                <button
                  onClick={() => respondToRequest('rejected')}
                  className="rejectButton "
                >
                  Decline
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="leavesIndex__body leavesIndex__body_view_screen">
          <div className="leavesIndex__body_padding leavesIndex__body_padding_view_screen">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Name
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {specialRequestDetails['full_name']}
                    </InputLabel>
                  </div>
                </div>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Status
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {specialRequestDetails['status']}
                    </InputLabel>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Subject
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {specialRequestDetails['subject']}
                      </InputLabel>
                    </div>
                  </div>
                </div>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Date
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {specialRequestDetails['date']}
                    </InputLabel>
                  </div>
                </div>
              </div>
              {specialRequestDetails['subject'] === 'multiple_leave' && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Duration
                        </InputLabel>
                        <InputLabel
                          className="formInputLabels formInputLabels2"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          {specialRequestDetails['whichHalf']}
                        </InputLabel>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {specialRequestDetails['subject'] === 'multiple_leave' &&
                specialRequestDetails['reason'] && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="singleInputField">
                      <div className="mt-2 flex items-center">
                        <div className="mt-2 flex items-center">
                          <InputLabel
                            className="formInputLabels"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            Reason
                          </InputLabel>
                          <InputLabel
                            className="formInputLabels formInputLabels2"
                            shrink
                            htmlFor="bootstrap-input"
                          >
                            {specialRequestDetails['reason']}
                          </InputLabel>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div className="singleInputField">
                  {specialRequestDetails['approveById'] && (
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Approved By
                      </InputLabel>
                      <InputLabel
                        className="formInputLabels formInputLabels2"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        {specialRequestDetails['approve_by_full_name']}
                      </InputLabel>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputFieldTextArea singleInputFieldTextAreaView">
                  <InputLabel
                    className="formInputLabels viewScreenHeading"
                    shrink
                    htmlFor="bootstrap-input"
                  >
                    Description
                  </InputLabel>
                  <FormControl variant="standard">
                    <textarea
                      className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                      value={specialRequestDetails['description']}
                      placeholder="Enter text here..."
                      rows={12}
                      cols={50}
                      readOnly
                    />
                  </FormControl>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
}
