import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthService from './services/AuthService';

export default function ForgetPassword() {
  const [selectedEmail, setSelectedEmail] = useState('');

  const [isValidEmail, setValidEmail] = useState(true);
  const [emailError, setEmailError] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);

  const history = useHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onSubmit = async () => {
    if (selectedEmail === '') {
      setEmailError(true);
      setValidEmail(true);

      return;
    } else {
      setEmailError(false);
    }

    setIsSubmitting(true);
    try {
      const data = {
        email: selectedEmail,
      };
      const data2 = await AuthService.findOtp(selectedEmail);
      if (data2.timer) {
        if (isMounted.current) {
          history.push('/otpVerification', { email: selectedEmail });
          setIsSubmitting(false);
          return;
        }
      }
      await AuthService.forget(data);
      if (isMounted.current) {
        history.push('/otpVerification', { email: selectedEmail });
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleEmailExit = () => {
    if (selectedEmail !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(selectedEmail);
      if (isValid) {
        setIsPasswordSubmitting(false);
        setValidEmail(isValid);
      } else {
        setIsPasswordSubmitting(true);
        setValidEmail(isValid);
      }
    }
  };

  const handleEmailChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  return (
    <div className="Portal-Login lg:flex-row flex-col">
      <div className="Portal-Login-Image"></div>
      <div className="Portal-Login-Logo">
        <img alt="logo" src="./logo_3.png"></img>
      </div>
      <div className="Portal-Login-Form">
        <div className="Hello-Welcome-back">
          <span>Forgot Password ? No Worries!</span>
          <p className="forget-paragraph">
            Enter Your e-mail below and we'll send and OTP to your e-mail to
            verify it.
          </p>
        </div>
        <Box
          sx={{
            width: 500,
            maxWidth: '100%',
          }}
        >
          <div className="flex flex-col flex-start">
            <div className="flex flex-col flex-start">
              <FormControl className="login-input-form" fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="Portal-Login-input"
                  label="Enter Email"
                  onBlur={handleEmailExit}
                  onChange={handleEmailChange}
                  value={selectedEmail}
                  error={!isValidEmail}
                  InputLabelProps={{
                    classes: {
                      root:
                        isValidEmail || emailError
                          ? 'Portal-Login-label'
                          : 'Portal-Login-label-error',
                    },
                  }}
                  disabled={isSubmitting}
                />
              </FormControl>
              {!isValidEmail && (
                <p className="error-message-invalid">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                      transform="translate(-80 880)"
                      fill="#f00"
                    />
                  </svg>
                  Invalid Email
                </p>
              )}

              {emailError && (
                <p className="flex justify-center error-message-invalid">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M90-865a.968.968 0 0 0 .713-.287A.968.968 0 0 0 91-866v-4a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-871a.967.967 0 0 0-.713.287A.967.967 0 0 0 89-870v4a.968.968 0 0 0 .287.712A.968.968 0 0 0 90-865zm0-8a.967.967 0 0 0 .713-.288A.968.968 0 0 0 91-874a.967.967 0 0 0-.287-.712A.967.967 0 0 0 90-875a.967.967 0 0 0-.713.288A.967.967 0 0 0 89-874a.968.968 0 0 0 .287.713A.967.967 0 0 0 90-873zm0 13a9.737 9.737 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137 10.1 10.1 0 0 1-2.138-3.175A9.738 9.738 0 0 1 80-870a9.737 9.737 0 0 1 .787-3.9 10.1 10.1 0 0 1 2.138-3.175 10.1 10.1 0 0 1 3.175-2.137A9.737 9.737 0 0 1 90-880a9.737 9.737 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137 10.1 10.1 0 0 1 2.137 3.175A9.737 9.737 0 0 1 100-870a9.737 9.737 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175 10.1 10.1 0 0 1-3.175 2.137A9.737 9.737 0 0 1 90-860zm0-2a7.721 7.721 0 0 0 5.675-2.325A7.721 7.721 0 0 0 98-870a7.721 7.721 0 0 0-2.325-5.675A7.721 7.721 0 0 0 90-878a7.721 7.721 0 0 0-5.675 2.325A7.721 7.721 0 0 0 82-870a7.721 7.721 0 0 0 2.325 5.675A7.721 7.721 0 0 0 90-862zm0-8z"
                      transform="translate(-80 880)"
                      fill="#f00"
                    />
                  </svg>
                  Enter Your Email Address
                </p>
              )}
            </div>

            <div>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Button
                  className="login-button"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isPasswordSubmitting}
                  onClick={onSubmit}
                >
                  Send OTP
                </Button>
              </FormControl>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
