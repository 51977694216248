import '../styles/DatePickerModal.css'; // Include your custom CSS here

import React from 'react';

// Default export
const RejectionModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="bootstrap__modal">
      <div className="bootstrap__rejection__modal__parent">
        <div className="bootstrap__modal__content">
          <div className="bootstrap__modal__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

// Named export
const InternModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="bootstrap__modal">
      <div className="bootstrap__intern__modal__parent">
        <div className="bootstrap__modal__content">
          <div className="bootstrap__modal__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

const DatePickerModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // Return null if the modal is not open

  return (
    <div className="bootstrap__modal" onClick={onClose}>
      {' '}
      {/* Close on outside click */}
      <div
        className="bootstrap__intern__modal__parent"
        onClick={(e) => e.stopPropagation()}
      >
        {' '}
        {/* Prevent click from propagating to the parent */}
        <div className="bootstrap__modal__content">
          <div className="bootstrap__modal__body">
            {children} {/* Render children inside the modal body */}
            <button className="modal__close" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionModal;
export { DatePickerModal, InternModal };
