import { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import { AuthenticationContext } from './context/AuthenticationContext';

export { Route } from 'react-router';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const { authenticatedUser } = useContext(AuthenticationContext);
  if (authenticatedUser) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (authenticatedUser) {
            return <Component {...props} />;
          }
          return <Redirect to="/login" />;
        }}
      />
    );
  } else {
    return <Redirect to="/login" />;
  }
}

export function AuthRoute({ component: Component, ...rest }) {
  const { authenticatedUser } = useContext(AuthenticationContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticatedUser ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}
