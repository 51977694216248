import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useLocation } from 'react-router-dom';

interface SidebarItemWithDropdownRequestsProps {
  title: string;
  icon: React.ReactNode;
  dropdownOpenRequests: boolean;
  toggleDropdownRequests: () => void;
  children: React.ReactNode;
}

const SidebarItemWithDropdownRequests: React.FC<
  SidebarItemWithDropdownRequestsProps
> = ({ title, icon, toggleDropdownRequests, children }) => {
  const location = useLocation();
  const [dropdownOpenRequests, setDropdownOpenRequests] = useState(false);

  useEffect(() => {
    // Check if the current page is '/cash' and set dropdownOpenAccounts accordingly
    if (
      location.pathname.startsWith('/cash') ||
      location.pathname.startsWith('/expence') ||
      location.pathname.startsWith('/inventory')
    ) {
      setDropdownOpenRequests(true);
    }
  }, [location.pathname]);

  return (
    <button
      className="sidebar-item-with-dropdown"
      onClick={() => setDropdownOpenRequests(!dropdownOpenRequests)}
    >
      <div className="dropdown-toggle sidebar-item-with-dropdown_main">
        <span className="navbar__item__span">
          {icon} {title}{' '}
          <ChevronDown style={{ marginLeft: 'auto' }} className="ml-auto" />
        </span>
      </div>
      {dropdownOpenRequests && (
        <ul style={{ padding: 0 }} className="dropdown ml-auto w-full">
          {children}
        </ul>
      )}
    </button>
  );
};

export default SidebarItemWithDropdownRequests;
