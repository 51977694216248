import React, { ReactNode } from 'react';

interface DatePickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

const DatePickerModal = React.forwardRef<HTMLDivElement, DatePickerModalProps>(
  ({ isOpen, onClose, children }, ref) => {
    if (!isOpen) return null;

    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
      // Close modal if clicking on overlay
      if (event.target === event.currentTarget) {
        onClose();
      }
    };

    return (
      <div className="bootstrap__modal" ref={ref} onClick={handleOverlayClick}>
        <div className="DatePickerModal">
          <div className="bootstrap__modal__content">
            <div className="bootstrap__modal__body">{children}</div>
          </div>
        </div>
      </div>
    );
  },
);

export default DatePickerModal;
