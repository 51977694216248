import Inventory from '../models/inventory/Inventory';
import UpdateInventoryRequest from '../models/inventory/UpdateInventoryRequest';
import apiService from './ApiService';

export class InventoryService {
  async update(
    id: number,
    updateInventoryRequest: UpdateInventoryRequest,
  ): Promise<void> {
    await apiService.put(`/api/inventory/update/${id}`, updateInventoryRequest);
  }

  async findAll() {
    return (await apiService.get('/api/inventory', {})).data;
  }
  async save(data): Promise<void> {
    await apiService.post('/api/inventory', data);
  }

  async findOne(id: string) {
    return (await apiService.get(`/api/inventory/${id}`)).data;
  }
  async delete(id: string): Promise<void> {
    await apiService.put(`/api/inventory/delete/${id}`);
  }

  async findItem(record) {
    const data = await apiService.post('/api/inventory/findItem', record);
    return data.data;
  }
}

const inventoryService = new InventoryService();
export { inventoryService as default };
