import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../components/layout';
import AnnouncementService from '../../services/AnnouncementService';

export default function ViewAnnouncement() {
  const { id } = useParams() as { id: string };

  const [selectedTime, setSelectedTime] = useState(null);
  const [title, setTitle] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [location, setLocation] = useState('');
  const [filePath, setFilePath] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [imageLoadError, setImageLoadError] = useState(false);
  const [guests, setGuests] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [islocation, setIsLocation] = useState(false);

  useEffect(() => {
    const fetchUserData = async (id) => {
      const announcement = await AnnouncementService.findOne(id);

      setTitle(announcement.title);
      setDescriptionText(announcement.description);
      const timeRange = formatTimeRange(announcement.time, announcement.toTime);
      setSelectedTime(timeRange);

      setGuests(announcement.guests);
      if (announcement.location) {
        setLocation(announcement.location);
        setIsLocation(true);
      } else {
        setIsLocation(false);
      }

      if (announcement.file) {
        setIsFile(true);
        const fileExtension = announcement.file.split('.').pop();
        const fileNameFromDb = id + '.' + fileExtension;
        setFilePath(fileNameFromDb);
        try {
          const response = await fetch(
            `https://hrportals.ainsofttech.com/api/api/announcement/image/${fileNameFromDb}`,
          );
          if (response.ok) {
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            setImageSrc(imageUrl);
            setImageLoadError(false);
          } else {
            setImageLoadError(true);
          }
        } catch (error) {
          setImageLoadError(true);
        }
      } else {
        setIsFile(false);
      }
    };

    fetchUserData(id);
  }, [id]);

  const formatTimeRange = (time, toTime) => {
    const fromTime = dayjs(time);
    const toDateTime = dayjs(toTime);

    if (fromTime.isSame(toDateTime, 'day')) {
      return `${fromTime.format('DD MMM, YYYY hh:mm A')} - ${toDateTime.format(
        'hh:mm A',
      )}`;
    } else {
      return `${fromTime.format('DD MMM, YYYY hh:mm A')} - ${toDateTime.format(
        'DD MMM, YYYY hh:mm A',
      )}`;
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.remove('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.remove('blurred__sidebar');
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    const sidebar = document.querySelector('.sidebarParent');
    const navbar = document.querySelector('.main__navbar__right');
    if (sidebar) {
      sidebar.classList.add('blurred__sidebar');
    }
    if (navbar) {
      navbar.classList.add('blurred__sidebar');
    }
  };

  const getFileType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return extension;
  };

  const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(getFileType(filePath));

  return (
    <Layout>
      <div className="leavesIndex">
        <div
          className="leavesIndex__header leavesIndex__body_view_screen"
          style={{ paddingBottom: 0 }}
        >
          <div>
            <h4 className="attendance_overview_heading">
              Announcement Details
            </h4>
          </div>
        </div>

        <div className="leavesIndex__body leavesIndex__body_view_screen">
          <div className="leavesIndex__body_padding leavesIndex__body_padding_view_screen">
            <Box
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="mt-2 flex items-center">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Title
                    </InputLabel>
                    <InputLabel
                      className="formInputLabels formInputLabels2 viewScreenSubHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      {title}
                    </InputLabel>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="singleInputField">
                  <div className="singleInputField">
                    <div className="mt-2 flex items-center">
                      <InputLabel
                        className="formInputLabels viewScreenHeading"
                        shrink
                        htmlFor="bootstrap-input"
                      >
                        Time
                      </InputLabel>
                      {selectedTime}
                    </div>
                  </div>
                </div>
              </div>

              {islocation && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField">
                    <div className="singleInputField">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels viewScreenHeading"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Location
                        </InputLabel>
                        {location}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {guests && guests.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField">
                    <div className="singleInputField">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels viewScreenHeading"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Guests
                        </InputLabel>
                        {guests.map((guest, index) => (
                          <div key={index} className="guestItem">
                            {guest}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isFile && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="singleInputField">
                    <div className="singleInputField">
                      <div className="mt-2 flex items-center">
                        <InputLabel
                          className="formInputLabels viewScreenHeading"
                          shrink
                          htmlFor="bootstrap-input"
                        >
                          Image
                        </InputLabel>
                        <IconButton onClick={handleModalOpen}>
                          <VisibilityIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div className="singleInputFieldTextArea singleInputFieldTextAreaView">
                    <InputLabel
                      className="formInputLabels viewScreenHeading"
                      shrink
                      htmlFor="bootstrap-input"
                    >
                      Description
                    </InputLabel>
                    <FormControl variant="standard">
                      <textarea
                        className={`leaveDurationTextArea leaveDurationInput leaveDurationTextAreaViewScreen`}
                        value={descriptionText}
                        placeholder="Enter text here..."
                        rows={12}
                        cols={50}
                        readOnly
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          sx={{ zIndex: 1300 }} // Higher z-index to ensure it's above the sidebar/navbar
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxHeight: '90vh',
              overflow: 'auto',
            }}
          >
            {isImage && !imageLoadError ? (
              <img
                src={imageSrc} // Use the fetched image source
                alt="Uploaded File"
                style={{ width: '100%', height: 'auto', maxHeight: '80vh' }} // Adjust size to fit better
              />
            ) : (
              <iframe
                src={`/path/to/files/${filePath}`} // Update with correct file path
                title="file viewer"
                width="100%"
                height="500px"
              />
            )}
          </Box>
        </Modal>
      </div>
    </Layout>
  );
}
