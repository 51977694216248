import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="bootstrap__modal">
      <div className="bootstrap__modal__parent">
        <div className="bootstrap__modal__content">
          <div className="bootstrap__modal__header">
            <button onClick={onClose}>×</button>
          </div>
          <div className="bootstrap__modal__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
